import {useQuery, UseQueryResult} from 'react-query'
import {transformArrayIdNameForValue} from 'utils/transformObjectFeels'
import {useMemo} from 'react'
import {getInternetResourceTypeList} from "../../api";
import {Id_Name_Code} from "../../types/common";

type onSuccessType = (data: Id_Name_Code[]) => void
export function useGetInternetResourceTypeList(onSuccess?: onSuccessType): UseQueryResult<Id_Name_Code[]> {
    return useQuery({
        queryKey: [getInternetResourceTypeList.key],
        queryFn: () => getInternetResourceTypeList.fetch(),
        onSuccess,
    })
}
export function useGetInternetResourceTypeListOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetInternetResourceTypeList(onSuccess)
    const options = useMemo(() => transformArrayIdNameForValue(data), [data])
    return { data: options, ...rest }
}
