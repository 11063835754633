import React, {useEffect, useState} from 'react';
import './index.less'
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as ContentFormat} from "assets/svg/content-format.svg";
import {selectProject} from "store/projects/selectors";
import {ModalOpenAction} from "store/modals/actions";
import {CalendarOutlined, StarOutlined, UserOutlined, VideoCameraOutlined} from '@ant-design/icons';
import {TDataModalDeadlinesProps} from "components/modals/modal-deadlines/modal-deadlines";
import WhetherTheUser from "components/whetherTheUser";
import TasksContentService from "services/tasksContentService";
import {NotificationOpen} from "store/notification/actions";
import useTasksFields from "hooks/tasksField";
import {TTaskRecordsFilter} from "models/task/task-list";
import {TTasksTypesFields} from "models/task";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {Button} from "antd";
import {ProjectAwards} from 'entities/award/ui';
import {ModalEvent} from 'shared/ui/modal';
import {TaskTypes} from 'entities/task'
import {ProjectTypes} from 'entities/project'
import {ModalTimeLines} from "entities/project/ui";
import {ContentRecords} from "features/content-records";


const ModalsInfo = () => {
    const put = useDispatch();
    const project = useSelector(selectProject);
    const {recordsStatuses} = useTasksFields([TTasksTypesFields.recordsStatuses])
    const {getRecords, records} = useRecords();

    useEffect(() => {
        if (recordsStatuses) {
            getRecords({
                page: 1,
                pageSize: 5,
                filterData: {
                    isMasterFile: true,
                    taskRecordStatusId: recordsStatuses?.find((el) => el.code === 'APPROVED')?.value,
                    projectId: project?.id
                }
            })
        }
            // eslint-disable-next-line
    }, [recordsStatuses, project?.id])

    const handleOpenModal = (type: 'content-format' | 'deadlines' | 'famousPeople' | 'content-records' | 'time-lines') => () => {
        if (type === 'content-format') {
            put(ModalOpenAction(type))
        }
        if (type === 'deadlines') {
            put(ModalOpenAction(type, {
                type: project?.type,
                firstContentCreationDate: project?.firstContentCreationDate,
                firstContentPostingDate: project?.firstContentPostingDate,
                allContentCreationDate: project?.allContentCreationDate,
                allContentPostingDate: project?.allContentPostingDate,
                allContentReachFixDate: project?.allContentReachFixDate,
                licenseDate: project?.licenseDate
            } as TDataModalDeadlinesProps))
        }
        if (type === 'famousPeople') {
            put(ModalOpenAction('famousPeople', project?.famousPeople))
        }
        if (type === 'content-records') {
           ModalEvent.open({
               title: <div style={{fontSize: '20px', color: '#333333'}}>Список единиц контента проекта</div>,
               component: <ContentRecords projectId={project?.id}/>,
               width: 1300,
               destroyOnClose: true,
           })
        }
        if (type === 'time-lines') {
            ModalEvent.open({
                title: (<div style={{fontSize: '14px', color: '#333333'}}>Тайм-коды единиц контента нац. проекта</div>),
                component: <ModalTimeLines contentTimelines={project?.contentTimelines as ProjectTypes.Project['contentTimelines']}/>
            })
        }
    }
    const onOpenAwards = () => {
        ModalEvent.open({
            title: (<div style={{fontSize: '20px', color: '#333333'}}>Награды проекта</div>),
            component: <ProjectAwards projectId={project?.id}/>,
            width: 490,
            destroyOnClose: true
        })
    }
    const isDisableContentRecords = !(!!records?.length)
    const isDisableFamousPeople = !(!!project?.famousPeople.length)
    const isDisableDeadlines = !(
        project?.firstContentCreationDate ||
        project?.firstContentPostingDate ||
        project?.allContentCreationDate ||
        project?.allContentPostingDate ||
        project?.allContentReachFixDate ||
        project?.licenseDate
    )
    return (
        <div className="modalInfo">
            {project?.contentFormats && (project.contentFormats.length > 0) && (
                <div className="modalInfo__item">
                    <span className='title'>Единицы контента</span>
                    <div className="content"
                         onClick={handleOpenModal('content-format')}
                    >
                        <span className="icon">
                           <ContentFormat/>
                        </span>
                        <span className="text">
                            Посмотреть<br/> требования
                        </span>
                    </div>
                </div>
            )}
            <WhetherTheUser role={['ROLE_VIEWER']} isCan={false}>
                <div className="modalInfo__item">
                    <span className='title'>График реализации</span>
                    <div className="content">
                    <Button
                        disabled={isDisableDeadlines}
                        type={"ghost"}
                        className="icon"
                        onClick={handleOpenModal('deadlines')}
                    >
                        <CalendarOutlined/>
                    </Button>
                        <span className="text">
                        Посмотреть сроки создания и размещения контента
                    </span>
                    </div>
                </div>
            </WhetherTheUser>
            <div className="modalInfo__item">
                <span className='title'>Известные люди</span>
                <div className="content">
                    <Button
                        type={"ghost"}
                        disabled={isDisableFamousPeople}
                        className="icon"
                        onClick={handleOpenModal('famousPeople')}
                    >
                        <UserOutlined />
                    </Button>
                    <span className="text">
                        Посмотреть известных людей проекта
                    </span>
                </div>
            </div>
            <div className="modalInfo__item">
                <span className='title'>Награды</span>
                <div className="content">
                    <Button
                      type={"ghost"}
                      disabled={!project?.awards?.length}
                      className="icon"
                      onClick={onOpenAwards}
                    >
                        <StarOutlined />
                    </Button>
                    <span className="text">
                        Посмотреть награды проекта
                    </span>
                </div>
            </div>
            <div className="modalInfo__item">
                <span className='title'>Контент проекта</span>
                <div className="content">
                    <Button
                        type={"ghost"}
                        disabled={isDisableContentRecords}
                        className="icon"
                        onClick={handleOpenModal('content-records')}
                    >
                        <VideoCameraOutlined />
                    </Button>
                    <span className="text">
                        Посмотреть согласованный контент проекта
                    </span>
                </div>
            </div>
            <WhetherTheUser role={['ROLE_VIEWER', 'ROLE_CONTRACTOR']} isCan={false}>
                <>
                    {!!project?.isNationalProject && !!project?.contentTimelines?.length && (
                        <div className="modalInfo__item">
                            <span className='title'>Тайм-коды единиц контента нац. проекта</span>
                            <div className="content">
                                <Button
                                    type={"ghost"}
                                    disabled={isDisableContentRecords}
                                    className="icon"
                                    onClick={handleOpenModal('time-lines')}
                                >
                                    <VideoCameraOutlined/>
                                </Button>
                                <span className="text">
                                    Посмотреть тайм-коды контента проекта
                                </span>
                            </div>
                        </div>
                    )}
                </>
            </WhetherTheUser>
        </div>
    )
}

export default ModalsInfo;

function useRecords() {
    const put = useDispatch();
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState<TaskTypes.ContentRecords[]>([]);

    const filter = {
        page: 1,
        pageSize: 5,
        filterData: {isMasterFile: true}
    }

    const getRecords = async (filterProps?: TTaskRecordsFilter) => {
        setLoading(true)
        try {
            const {data} = await TasksContentService.getTaskRecords(filterProps ? filterProps : filter)
            const {content} = data;
            setRecords(content)
        } catch (error: any) {
            const message = CreateErrorMessage.response(error)
            put(NotificationOpen("error", '', message))
        } finally {
            setLoading(false)
        }
    }

    return {loading, records, getRecords }
}
