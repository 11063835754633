import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getOrganizations} from "../api/organization";
import {OrganizationShort} from "../types/organization";
import {IOptions} from "../../../models/feels";

type onSuccessType = (data: OrganizationShort[]) => void
export function useGetOrganizations(onSuccess?: onSuccessType): UseQueryResult<OrganizationShort[]> {
    return useQuery({
        queryKey: [getOrganizations.key],
        queryFn: () => getOrganizations.fetch(),
        onSuccess,
    });
}
export function useGetOrganizationsOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetOrganizations(onSuccess);
    const options = useMemo(() => data.map((el) => (
        {
            label: `${el.name}  - ${el.isContentCustomer ? "Заказчик контента" : "Поставщик контента"}`,
            value: el.id, code: String(el.isContentCustomer)
        } as IOptions
    )), [data]);
    return { data: options, ...rest };
}
