import {createSelector} from "reselect";
import {TApplicationState} from "store/aplication-state";
import {selectUser} from "store/auth/selectors";

const getTasksState = (state: TApplicationState) => state.tasks;
/**
 * Content
 */
export const selectContentTasksFilter = createSelector(
    getTasksState,
    (state) => state.tasksContent.filter
)
export const selectContentTasks = createSelector(
    getTasksState,
    (state) => state.tasksContent.tasks
)
export const selectContentVotes = createSelector(
    getTasksState,
    (state) => state.tasksContent.votes
)

export const selectContentTasksStatus = createSelector(
    getTasksState,
    (state) => ({
        isLoading: state.tasksContent.isLoading,
        error: state.tasksContent.error,
    })
)

export const selectContentTasksInfo = createSelector(
    getTasksState,
    (state) => ({
        votesStore: state.tasksContent.votes ? state.tasksContent.votes : null,
        recordId: state.tasksContent.recordId
    })
)
export const selectContentBulkAction =  createSelector(
    getTasksState,
    (state) => state.tasksContent.bulkAction
)

export const selectContentRowsIds =  createSelector(
    getTasksState,
    (state) => state.tasksContent.rowsIds
)

export const selectContentTaskWithVotes = createSelector(
    getTasksState,
    selectContentTasks,
    (state,tasks) => {
        if (state.tasksContent.votes && tasks?.length) {
            const votesIds = Object.keys(state.tasksContent.votes)
            // @ts-ignore
            return tasks.filter((it: any)=> votesIds.includes(it.id) && (it.status === "VERIFICATION_REQUIRED" || it.status === "READY_FOR_CUSTOMER_VERIFICATION"))
        }
        return []
    }
)

export const selectContentTasksReview = createSelector(
    selectContentTasks,
    (tasks) => {
        if (tasks?.length) {
            // @ts-ignore
            return tasks.filter((it: any)=> it.status === "READY_FOR_VERIFICATION")
        }
        return []
    }
)

export const selectContentCreateArchive = createSelector(
    selectContentTasks,
    (tasks) => {
        if (tasks?.length) {
            // @ts-ignore
            return tasks.filter((it: any)=> it.file?.id && it.fileUpload && !it.fileUpload?.isUploadInProgress)
        }
        return []
    }
)

export const selectContentTaskWithVotesApproved = createSelector(
    selectUser,
    getTasksState,
    selectContentTasks,
    selectContentBulkAction,
    (user,state,tasks,bulkAction) => {
        if (tasks?.length && bulkAction === 'SET_RECORDS_APPROVED') {
            const votesIds = state.tasksContent.votes ? Object.keys(state.tasksContent.votes) : []
            // @ts-ignore
            const ids = tasks.filter((task:any) => task.votes?.some((vote:any) => vote.user?.id === user?.id && typeof vote.isApproved === 'boolean'))
                .map((task:any) => task.id);
            // @ts-ignore
            return tasks.filter((task:any) => !ids.includes(task.id) && !votesIds.includes(task.id) && ["VERIFICATION_REQUIRED", "READY_FOR_CUSTOMER_VERIFICATION"].includes(task.status));
        }
        return []
    }
)

export const selectContentTaskWithVotesRejected = createSelector(
    selectUser,
    getTasksState,
    selectContentTasks,
    selectContentBulkAction,
    (user, state, tasks, bulkAction) => {
      // * Условия фильтрации:
      //   * 1. Должны быть задачи для фильтрации (tasks?.length).
      //   * 2. Действие (bulkAction) должно быть равно 'SET_RECORDS_REJECTED'.
      //   * 3. Исключаются задачи, которые одобрены текущим пользователем.
      //   * 4. Исключаются задачи, чьи идентификаторы присутствуют в votesIds.
      //   * 5. Исключаются задачи со статусами "VERIFICATION_REQUIRED" или "READY_FOR_CUSTOMER_VERIFICATION".
        if (tasks?.length && bulkAction === 'SET_RECORDS_REJECTED') {
            // votesIds содержит все идентификаторы задач, которые имеют связанные с ними голоса
            const votesIds = state.tasksContent.votes ? Object.keys(state.tasksContent.votes) : []
            //todo const vote.user?.id === user?.id && vote.isApproved))
            //@ts-ignore  ids содержит идентификаторы задач, которые одобрены текущим пользователем
            const ids = tasks.filter((task:any) =>
              task.votes?.some((vote:any) => vote.user?.id === user?.id && typeof vote.isApproved === 'boolean'))
              .map((task:any) => task.id);
            // Возвращаем отфильтрованный массив задач
            // Фильтрация задач:
            //   Исключаются задачи, идентификаторы которых присутствуют в ids или votesIds.
            //   Оставляются задачи, статус которых равен "VERIFICATION_REQUIRED" или "READY_FOR_CUSTOMER_VERIFICATION".
            // @ts-ignore
            return tasks.filter((task:any) =>
              !ids.includes(task.id) &&
              !votesIds.includes(task.id) &&
              ["VERIFICATION_REQUIRED", "READY_FOR_CUSTOMER_VERIFICATION"].includes(task.status)
            );
        }
        return []
    }
)

export const selectContentTasksBulkActions = createSelector(
    selectContentTasks,
    selectContentTaskWithVotes,
    selectContentTasksReview,
    selectContentTaskWithVotesApproved,
    selectContentTaskWithVotesRejected,
    selectContentBulkAction,
    (tasks, tasksVotes, tasksReview, tasksVotesApproved, tasksVotesRejected, bulkActions) => {
      switch (bulkActions) {
            case 'SET_RECORDS_VERIFICATION_REQUIRED':
                return tasksReview
            case 'SEND_APPROVAL_RESULTS':
                return tasksVotes
            case 'SET_RECORDS_APPROVED':
                return tasksVotesApproved
            case 'SET_RECORDS_REJECTED':
                return tasksVotesRejected
            default: return tasks || []
        }
    }
)
