import {axiosRequest} from 'shared/config/axios';
import {downloadXLXS} from "utils/downloadXLXS";
import {Id_Name_Code_Hidden, Id_Name_Code_Hidden_Schema} from "shared/types/common";
import {CategoryTypeRequest, FilterCategoryType} from "../types";

/**
 * @description Получение всех категорий проектов
 */
export const getCategoryTypes = {
    key: '/category-types',
    fetch: async () => {
        const {data} = await axiosRequest<Id_Name_Code_Hidden[]>({
            url: '/category-types',
            method: 'GET',
            showError: true,
            parser: Id_Name_Code_Hidden_Schema.array()
        })
        return data
    },
}

/**
 * Получение списка категорий с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/category-types/search',
    fetch: async (filter: FilterCategoryType): Promise<Id_Name_Code_Hidden> => {
        const newFilter = { ...filter, page: filter.page - 1 }
        const { data } = await axiosRequest<Id_Name_Code_Hidden>({
            method: 'POST',
            url: '/category-types/search',
            data: newFilter,
            parser: { list: true, schema: Id_Name_Code_Hidden_Schema.array() },
        })
        return data
    },
}
/**
 * Создание новой категории
 */
export const create = async (data: CategoryTypeRequest) => {
    await axiosRequest({
        method: 'POST',
        url: '/category-types',
        data,
    })
}

/**
 * Обновление категории
 */
export const update = async ({ id, data }: { id: string; data: CategoryTypeRequest }) => {
    await axiosRequest({
        method: 'PUT',
        url: `/category-types/${id}`,
        data,
    })
}

/**
 * Удаление категории
 */
export const remove = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/category-types/${id}`,
    })
}

/**
 * Массовые действия
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/category-types/bulk-actions/${bulkAction}`,
        data: rowIds,
    })
    return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/category-types/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    })
    downloadXLXS(data, 'категории')
}
/**
 * Получение файла шаблона для выполнения импорта данных о категориях
 */
export const downloadTemplate = async () => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/category-types/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    })
    downloadXLXS(data, 'шаблон_формы_сдачи_контента')
}
/**
 * Выполнение импорта данных о категориях из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
    await axiosRequest({
        method: 'POST',
        url: '/category-types/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    })
}
