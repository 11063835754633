import React, {MouseEvent, ReactNode, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Checkbox, Radio, RadioChangeEvent, Select, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import AppService from "services/appService";
import {ContentService} from "services/contentService";
import {ExtendedSearchModel} from 'features/extended-search';
import { Stores, Fields } from 'features/extended-search/types';
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {useQuery} from "react-query";
import {FilmClusterLib} from 'entities/film-cluster'

const {Option} = Select;
function useGetsData() {
    const regions = useQuery({
        queryKey: [AppService.regionsKey],
        queryFn: async () => {
            const {data} = await AppService.getRegions()
            return transformArrayIdNameForValue(data)
        },
    })
    const regionsAbout = useQuery({
        queryKey: [ContentService.regionsAboutKey],
        queryFn: async () => {
            const {data} = await ContentService.getRegionsAbout()
            return transformArrayIdNameForValue(data)
        },
    })
    const filmCluster = FilmClusterLib.useGetFilmClustersOptions()

    return {
        loading: regions.isLoading || regionsAbout.isLoading || filmCluster.isLoading,
        options: {
            REGION_ABOUT: regions.data || [],
            CONTENT_FILTER_REGION_ABOUT: regionsAbout.data || [],
            CONTRACTOR_ORGANIZATION_REGION: regions.data || [],
            CONTENT_LOCATION_REGION: regions.data || [],
            FILM_CLUSTER: filmCluster.data || [],
        }
    }
}
const optionsFields: Array<{label: string, value: Stores.RegionalsKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'О каком регионе проект', value: Fields.FieldsEnums.REGION_ABOUT},
    {label: 'О каком регионе ед. контента', value: Fields.FieldsContent.CONTENT_FILTER_REGION_ABOUT},
    {label: 'Субъект исполнителя проекта', value: Fields.FieldsEnums.CONTRACTOR_ORGANIZATION_REGION},
    {label: 'Субъект места съемок', value: Fields.FieldsEnums.CONTENT_LOCATION_REGION},
    {label: 'Кинокластер проекта', value: Fields.FieldsEnums.FILM_CLUSTER},
];
export const RegionalsItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const regionals = useSelector(ExtendedSearchModel.selectors.regionals)


    const disabledOptions = useMemo(() => {
        return regionals[typeContent].fields.map((el) => el.field)
    }, [regionals, typeContent]);

    const onGroupChange = (index: number) => (e: RadioChangeEvent) => {
        const arr = [...regionals[typeContent].fields];
        arr[index].isAndOrBesides = e.target.value;
        put(ExtendedSearchModel.actions.setRegionals(typeContent, arr))
    }

    const onSelectChangeType = (index: number) => (value: Stores.RegionalsKeys | 'Параметр неважен') => {
        const newArray = [...regionals[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["values"] = [];
        put(ExtendedSearchModel.actions.setRegionals(
            typeContent,
            newArray
        ))
    }
    const onSelectChangeValue = (index: number) => (value: Fields.FieldFilter["values"]) => {
        const newArray = [...regionals[typeContent].fields];
        newArray[index]["values"] = value;
        put(ExtendedSearchModel.actions.setRegionals(
            typeContent,
            newArray
        ))
    }
    const addGroup = () => {
        put(ExtendedSearchModel.actions.setRegionals(typeContent, [
            ...regionals[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, values: []}
        ]))
    }
    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...regionals[typeContent].fields];
        newArray.splice(index, 1)
        put(ExtendedSearchModel.actions.setRegionals(
            typeContent,
            newArray
        ))
    }
    const onCheckAll = (index: number, field: Stores.RegionalsKeys | 'Параметр неважен') => (event: any) => {
        if (field === 'Параметр неважен') return;
        const checked = event.target.checked;
        const newArray = [...regionals[typeContent].fields];
        if (checked) {
            newArray[index]["values"] = options[field].map((el) => el.value);
        } else {
            newArray[index]["values"] = []
        }
        put(ExtendedSearchModel.actions.setRegionals(
            typeContent,
            newArray
        ))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper" key={typeContent}>
                {regionals[typeContent].fields.map((item, index) => (
                    <div className="item-wrapper__group" key={item.field + index}>
                        <div className="item-wrapper__items">
                            <div className="item">
                                <div className="form-group">
                                    <Select
                                        value={item.field}
                                        onChange={onSelectChangeType(index)}
                                        placeholder={'Параметр неважен'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {optionsFields.map((el) => (
                                            <Option
                                                disabled={disabledOptions.includes(el.value)}
                                                value={el.value}
                                                key={el.value}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="or">
                                <Radio.Group value={item.isAndOrBesides} onChange={onGroupChange(index)}>
                                    <Radio value={true}>И</Radio>
                                    <Radio value={false}>Или</Radio>
                                </Radio.Group>
                            </div>
                            <div className="item item-second">
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        mode={"multiple"}
                                        disabled={item.field === 'Параметр неважен'}
                                        maxTagCount={"responsive"}
                                        value={item.values}
                                        onChange={onSelectChangeValue(index)}
                                        placeholder={'Параметр неважен'}
                                        filterOption={(input, option) => {
                                            if (option === undefined || option === null) return false;
                                            return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        dropdownRender={(originNode: ReactNode) => (
                                            <>
                                                <div className={'item__checkbox'}>
                                                    <Checkbox
                                                        checked={
                                                            item.field !== 'Параметр неважен' &&
                                                            item.values.length === options[item.field].length
                                                        }
                                                        onChange={onCheckAll(index, item.field)}
                                                    >
                                                        Выбрать все
                                                    </Checkbox></div>
                                                {originNode}
                                            </>
                                        )}
                                    >
                                        {item.field !== 'Параметр неважен' && options[item.field].map((el) => (
                                            <Option
                                                value={el.value}
                                                key={el.value}
                                                data-name={el.label}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {regionals[typeContent].fields.length > 1 && (
                            <div className="item__remove" onClick={onRemoveHandler(index)}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ))}
                {regionals[typeContent].fields.length < optionsFields.length - 1 && (
                    <div className="btn">
                        <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                    </div>
                )}
            </div>
        </Spin>
    )
}
