import { FunctionalityInfoType } from '../types'

export const projectTasks: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка задач с поиском, фильтрацией, сортировкой',
		LevelGroupData: [
			{
				name: 'Поиск задач по наименованию, коду и номеру',
				text: 'Для поиска задач по наименованию, коду и номеру:',
				points: ['Нажмите "Поиск задачи"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/project-tasks/search.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация задач',
				text: 'Используйте фильтры для поиска задач:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Закройте окно фильтрации для поиска задач',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров ',
				],
				screenshots: ['/images/screenshots/project-tasks/filter.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сохранение фильтра',
				text: 'Для сохранения фильтра:',
				points: ['Введите наименование фильтра в баннере "Наименование фильтра"', 'Нажмите "Сохранить фильтр"'],
				screenshots: ['/images/screenshots/project-tasks/filter-modal.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сортировка задач',
				text: 'Для сортировки задач:',
				points: ['Нажмите на кнопку', 'Выберите значение для сортировки из выпадающего списка'],
				screenshots: ['/images/screenshots/project-tasks/sorting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Создание задач',
		LevelGroupData: [
			{
				name: 'Создание задачи',
				points: [
					'Нажмите "Создать задачу" для перехода в форму создания задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				screenshots: ['/images/screenshots/create-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Дублирование задач',
				text: 'Для дублирования задачи:',
				points: [
					'Нажмите "Дублировать задачу" для перехода в окно дублирования',
					'Заполните поля формы',
					'Нажмите "Создать"',
				],
				screenshots: ['/images/screenshots/duplicate-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Настройка автосоздания задачи',
				text: 'Для настройки автосоздания задач:',
				points: [
					'Нажмите на кнопку для перехода в окно настройки автосоздания задачи',
					'Нажмите на переключатель "Включена / Выключена" для включения / выключения автосоздания задачи',
					'Вы можете найти нужную задачу по клику на кнопку фильтрации задач по конкурсу, году, сессии, проекту',
					'Заполните обязательные и доступные поля формы',
					'Нажмите "Обновить"',
				],
				screenshots: [
					'/images/screenshots/project-tasks/button.png',
					'/images/screenshots/project-tasks/modal-auto.png',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных задач',
		LevelGroupData: [
			{
				name: 'Редактирование задачи',
				points: [
					'Нажмите для перехода в форму и редактирования данных задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				screenshots: ['/images/screenshots/edit-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Удаление задачи',
				text: 'Нажмите для удаления задачи',
				icons: ['delete-red'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Обновление статуса задачи',
				text: 'Перетяните карточку с задачей в столбец с нужным статусом для обновления статуса задачи либо используйте массовое действие "Присвоить статус"',
				screenshots: ['/images/screenshots/project-tasks/cards.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_CONTRACTOR', 'ROLE_MODERATOR'],
			},
			{
				name: 'Массовые действия',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите задачи',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/project-tasks/bulk.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR'
				],
			},
			{
				name: 'Массовое согласование записей задач',
				points: [
					'Нажмите на кнопку для перехода в окно массового согласования записей задач',
					'Заполните обязательные и доступные поля формы',
					'Нажмите “Применить”',
				],
				screenshots: ['/images/screenshots/project-tasks/mass.png'],
				roles: [
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Комментарии к задаче',
		LevelGroupData: [
			{
				name: 'Просмотр и добавление комментариев к задаче',
				points: [
					'Нажмите "Комментарии" для просмотра комментариев к задаче',
					'Нажмите "Добавить комментарий"',
					'Нажмите “Сохранить”',
					'Нажмите "Изменить" / "Удалить" для редактирования данных',
					'Нажмите "Сначала новые / старые" либо "Все комментарии / Мои комментарии" для сортировки комментариев',
				],
				screenshots: ['/images/screenshots/project-tasks/comment.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных задачи',
		LevelGroupData: [
			{
				name: 'Просмотр дополнительных данных по задаче',
				text: 'Наведите курсор на шестеренку для просмотра данных по задаче',
				icons: ['setting-gray'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор дублирования задач',
				text: 'Наведите курсор на индикатор дублирования задач для просмотра статуса дублирования:',
				points: [
					'Оранжевый - дублирование включено',
					'Серый - дублирование выключено',
				],
				screenshots: ['/images/screenshots/project-tasks/button.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_PRODUCER', 'ROLE_ANALYST', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Индикатор срока выполнения задачи',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Серый - узнайте количество дней до окончания задачи',
						icon: 'light-gray'
					},
					{
						label: 'Красный - срок задачи просрочен',
						icon: 'light-red'
					}
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор даты выполнения задачи',
				text: 'Ознакомьтесь с датой выполнения задачи:',
				points: [
					'Зеленого цвета - срок задачи не просрочен',
					'Желтого цвета - срок задачи истекает сегодня',
					'Красного цвета - срок задачи просрочен',
				],
				screenshots: ['/images/screenshots/indicator-date.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных задачи',
		LevelGroupData: [
			{
				name: 'Массовое обновление списка пользователей задачи',
				text: 'Для массового обновления списка пользователей задач',
				points: [
					'Выберите массовое действие "Обновить список пользователей задач", выберите проекты для обновления, нажмите "Применить"',
					'В модальном окне выберите пользователей из списка к добавлению/удалению из задач',
					'При добавлении пользователя вы можете выбрать одно из значений, кем в задачах является пользователь',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/mass-user-select.png', '/images/screenshots/mass-user-modal.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_COORDINATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_ANALYST',
					'ROLE_LAWYER',
					'ROLE_FINANCIER'
				],
			},
		]
	}
]
