import { FunctionalityInfoType } from '../types'

export const tasksRecords: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка записей задач с поиском, фильтрацией, сортировкой, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск задач',
				text: 'Для поиска задач по наименованию контента / публикаций, описанию и статьям расходов:',
				points: ['Нажмите "Поиск записей задач"', 'Введите текст'],
				screenshots: ['/images/screenshots/tasks-records/search-task-records.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация задач',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Нажмите “Найти” для поиска задач',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров',
				],
				important: ['! Нажмите "Сохранить фильтр" для сохранения фильтра'],
				screenshots: ['/images/screenshots/tasks-records/filters-tasks-records.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сортировка отображения таблицы записей задач',
				text: 'Нажимайте на стрелки для сортировки отображения таблицы записей задач',
				screenshots: ['/images/screenshots//tasks-records/task-sorting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества записей, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице записей задач:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Управление выводом полей',
		LevelGroupData: [
			{
				name: 'Настройка скрытия видимости полей таблицы',
				text: 'Для настройки скрытия видимости полей таблицы:',
				points: ['Нажмите на кнопку', 'Выберите столбцы для скрытия', 'Нажмите "Сохранить"'],
				icons: ['setting-gray'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных записей задач',
		LevelGroupData: [
			{
				name: 'Массовые действия над записями задач',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите задачи',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/tasks-records/bulk-actions.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Массовое обновление связи пользователей с доступным списком единиц контента',
				points: [
					'Нажмите на количество  пользователей с доступом к просмотру данных единиц контента проектов в списках для открытия окна "Массовое обновление связи пользователей с доступным списком единиц контента"',
					'Выберите пользователей из списка',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/tasks-records/mass-update.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN'],
			},
			{
				name: 'Редактирование данных тайм-кодов видео-, аудиоконтента в окне "Посмотреть загруженные файлы записи"',
				text: 'Для редактирования данных тайм-кодов единиц контента в модальном окне "Просмотр загруженных файлов записи"',
				points: [
					'Нажмите на иконку часов  "Редактирование тайм-кодов файлов"',
					'В форме "Информация о тайм-кодах файла" нажмите "Добавить тайм-код"',
					'Укажите хронометраж и  наименование метки хронометража',
					'Нажмите "Сохранить" для сохранения данных тайм-кодов',
					'Вы можете удалить таймкод контента по иконке "Удалить"',
				],
				screenshots: [
					'/images/screenshots/time-codes.png',
				],
				roles: [
					'ROLE_COORDINATOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_PRODUCER',
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_MODERATOR',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных записей задач',
		LevelGroupData: [
			{
				name: 'Просмотр описания контента',
				text: 'Нажмите для просмотра данных в модальном окне "Описание контента"',
				icons: ['message'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Результат проверки',
				text: 'Ознакомьтесь со статусом согласования задач:',
				points: [
					{
						label: 'Данные согласованы',
						icon: 'check-circle'
					},
					{
						label: 'На согласовании',
						icon: 'warning-blue'
					},
					{
						label: 'Редактируется',
						icon: 'minus-circle'
					},
					{
						label: 'Данные отклонены',
						icon: 'close-circle'
					},
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр комментариев по записи задачи',
				text: 'Нажмите для просмотра данных в модальном окне "Комментарии"',
				screenshots: ['/images/screenshots/tasks-records/view-comments.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр связанных файлов записей задач по клику на компонент "Посмотреть связанные файлы"',
				text: 'Нажмите "Посмотреть связанные файлы". В модальном окне просмотра есть возможность:',

				screenshots: ['/images/screenshots/view-files-button/view.png'],
				points: [
					'Просматривать файлы',
					'Просматривать информацию о файлах записи',
					'Скачать файл',
					'Скопировать ссылку на СХД',
					'Посмотреть метаданные файла',
				],
				important: [
					'! Некоторые функции могут быть не доступны при отсутствии прав'
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
