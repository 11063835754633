import {axiosRequest} from 'shared/config/axios'
import {RecordsCount, RecordsCountSchema, UpdateTaskUserList} from '../types'
import {TTaskListFilterData, TTaskListResponse} from "../../../models/task/task-list";
import {TaskTypes} from 'entities/task'

export const getTaskRecordsCount = {
	key: (taskId: string) => `/tasks/${taskId}/records/records-count`,
	fetch: async (taskId: string) => {
		const { data } = await axiosRequest<RecordsCount>({
			url: `/tasks/${taskId}/records/records-count`,
			method: 'GET',
			parser: RecordsCountSchema,
			headers: {
				'Actioncode': 'GET_TASKS_TASK_ID_RECORDS_RECORDS_COUNT'
			}
		})
		return data
	},
}

export const changeStatusTask = async ({ id, statusId }: { id: string, statusId: string}) => {
	await axiosRequest({
		method: 'PUT',
		url: `/tasks/${id}/status`,
		params: { statusId },
	})
}

export const resetStatusRecordContent = async ({ recordId }: { recordId: string }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/tasks/content/records/${recordId}/status-reset`,
	})
}

export const recordsBulkActionsContent = async ({ rowsIds, bulkAction }: { rowsIds: string[], bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/tasks/content/records/bulk-actions/${bulkAction}`,
		data: rowsIds
	})
}
export const recordsBulkActionsReport = async ({ rowsIds, bulkAction, reportType }: { rowsIds: string[], bulkAction: string, reportType: TaskTypes.ReportType }) => {
	let type;
	switch (reportType) {
		case TaskTypes.ReportType.CONTENT_POSTING_REPORT:
			type = 'posting'
			break
		case TaskTypes.ReportType.CONTENT_REACH_REPORT:
			type = 'reach'
			break
		case TaskTypes.ReportType.COPYRIGHT_LIST:
			type = 'copyright'
			break
		case TaskTypes.ReportType.PROJECT_ESTIMATE:
			type = 'estimate'
			break
	}
	await axiosRequest({
		method: 'POST',
		url: `/tasks/${type}/records/bulk-actions/${bulkAction}`,
		data: rowsIds
	})
}


const getActionCode = (typeTask: 'posting' | 'content' | 'estimate' | 'copyright') => {
	let action = 'DELETE_TASKS_CONTENT_TASK_ID_RECORDS_BULK_RECORDS_LIQUIDATION'
	switch (typeTask) {
		case 'posting':
			action = 'DELETE_TASKS_POSTING_TASK_ID_RECORDS_BULK_RECORDS_LIQUIDATION'
			break
		case 'copyright':
			action = 'DELETE_TASKS_COPYRIGHT_TASK_ID_RECORDS_BULK_RECORDS_LIQUIDATION'
			break
		case 'estimate':
			action = 'DELETE_TASKS_ESTIMATE_TASK_ID_RECORDS_BULK_RECORDS_LIQUIDATION'
			break
		case 'content':
			action = 'DELETE_TASKS_CONTENT_TASK_ID_RECORDS_BULK_RECORDS_LIQUIDATION'
			break
	}
	return action
}
export const deleteTaskRecordsBulk = async (taskId: string, typeTask: 'posting' | 'content' | 'estimate' | 'copyright') => {
	await axiosRequest({
		method: "DELETE",
		url: `/tasks/${typeTask}/${taskId}/records/bulk-records-liquidation`,
		headers: {
			'Actioncode': getActionCode(typeTask)
		}
	})
}

export const getAllTasksOptions = async (filterData: TTaskListFilterData) => {
  	const { data } = await axiosRequest<TTaskListResponse>({
		method: 'POST',
		url: `/tasks/search`,
		data: {page: 0, pageSize: 0, filterData}
	})

	return data.content.map(task => {
		const name = task.name.length > 40 ? task.name.substring(0, 37) + '...' : task.name;
		const formattedDeadline = task?.deadline?.split('-')?.reverse()?.join('.');
		return {
			value: task.id,
			label: `${task.code}-${task.number} ${name} (${formattedDeadline})`,
			code: `${task.code}-${task.number} ${task.name} (${formattedDeadline})`,
		};
	});

}
export const updateTaskUserList = async  (data: UpdateTaskUserList) =>  {
	await axiosRequest({
		method: 'PUT',
		url: `/tasks/task-users`,
		data,
	});
}
