import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {ContentFormatTypeTypes} from 'entities/content-format-type'
import {getContentDirections} from "../api/project-content-direction-controller";

type onSuccessType = (data: ContentFormatTypeTypes.SubmissionForm[]) => void
export function useGetProjectContentDirection(onSuccess?: onSuccessType): UseQueryResult<ContentFormatTypeTypes.SubmissionForm[]> {
    return useQuery({
        queryKey: [getContentDirections.key],
        queryFn: () => getContentDirections.fetch(),
        onSuccess,
    });
}
export function useGetProjectContentDirectionOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetProjectContentDirection(onSuccess);
    const options = useMemo(() => data.map((el) => ({
        value: el.id,
        label: el.name,
        code: el.code,
        hidden: el.hidden
    })), [data]);
    return { data: options, ...rest };
}
