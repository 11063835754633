import {z} from 'zod';
import {TasksRecordStatusEnum} from '../record-status';
import {Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema} from 'shared/types/common';
import {FileSchema} from 'shared/types/file';
import {VoteSchema} from '../vote';
import {RoleSchema} from "entities/user/types";

export const ContentRecordsSchema = z.object({
	id: z.string(),
	name: z.string().nullable(),
	description: z.string().nullable(),
	contentFormatType: Id_Name_Code_Schema.nullable(),
	submissionForms: z.array(Id_Name_Code_Schema).nullable(),
	isMasterFile: z.boolean().nullable(),
	votes: z.array(VoteSchema).nullable(),
	linkedFilesCount: z.number(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}).nullable(),
	dateTime: z.string(),
	projectCreateDate: z.string(),
	timeCodes: z.array(
		z.object({ id: z.string().nullable(), timeline: z.string(), name: z.string() })
	),
	status: TasksRecordStatusEnum,
	contentTags: z.array(Id_Name_Code_Hidden_Schema),
	regionForSet: z.array(Id_Name_Code_Hidden_Schema),
	regionAboutSet: z.array(Id_Name_Code_Hidden_Schema),
	isOriginal: z.boolean(),
	isContent: z.boolean(),
	isAccountedContent: z.boolean(),
	isReplaced: z.boolean(),
	task: z.object({
		deadline: z.string(),
		number: z.number(),
		id: z.string(),
		name: z.string(),
		code: z.string(),
	}).optional()
})
export type ContentRecords = z.infer<typeof ContentRecordsSchema>

export const ContentRecordSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	task: z.object({
		id: z.string(),
		status: z.object({ id: z.string(), code: z.string(), name: z.string() }),
		archival: z.boolean(),
		contentTimeCodeRequired: z.boolean()
	}),
	contentFormatType: z.object({
		id: z.string(),
		code: z.string(),
		name: z.string()
	}),
	submissionForms: z.array(z.object({
		id: z.string(),
		code: z.string(),
		name: z.string()
	})),
	isMasterFile: z.boolean().nullable(),
	file: z.object({
		id: z.string(),
		compressedId: z.string(),
		scaledId: z.string(),
		name: z.string(),
		type: z.string(),
		storagePath: z.string(),
		previewFile: z.string(),
		size: z.number()
	}),
	dateTime: z.string(),
	status: z.string(),
	projectCreateDate: z.string(),
	contentTags: z.array(
		z.object({
			id: z.string(),
			code: z.string(),
			name: z.string(),
			hidden: z.boolean()
		})
	),
	regionAboutSet: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			hidden: z.boolean()
		})
	),
	regionForSet: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
			code: z.string(),
			hidden: z.boolean()
		})
	),
	isContent: z.boolean(),
	isAccountedContent: z.boolean(),
	timeCodesFillRequired: z.boolean(),
	files: FileSchema.array().nullable(),
})
export type ContentRecord = z.infer<typeof ContentRecordSchema>

export const MassTasksContentRecordsSchema = z.object({
	contentRecordIds: z.array(z.string()),
	addUserIds: z.array(z.string()),
	deleteUserIds: z.array(z.string()),
})
export type MassTasksContentRecords = z.infer<typeof MassTasksContentRecordsSchema>;

export const RelatedUserSchema = z.object({
	id: z.string(),
	lastName: z.string(),
	firstName: z.string(),
	middleName: z.string().nullable(),
	role: RoleSchema,
	leader: z.boolean(),
	status: z.enum(['ACTIVE', 'BLOCKED', 'ARCHIVE']),
})

export type RelatedUser = z.infer<typeof RelatedUserSchema>;
