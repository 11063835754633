import {useQuery, UseQueryResult} from 'react-query'
import {useMemo} from 'react'
import {getContentProjectTags, getProjectTags} from '../api'
import {IOptions} from 'models/feels'
import {Tag} from '../types'

type onSuccessType = (data: Tag[]) => void
type paramsType = { level?: number | null; isForNationalProject?: boolean, isAssignment?: boolean, isTheme?: boolean, isCollection?: boolean }

export function useGetProjectTags(params?: paramsType, onSuccess?: onSuccessType): UseQueryResult<Tag[]> {
	return useQuery({
		queryKey: [getProjectTags.key, params],
		queryFn: () => getProjectTags.fetch(params),
		onSuccess,
	})
}
export function useGetProjectTagsOptions(params?: paramsType, onSuccess?: onSuccessType) {
	const { data = [], ...rest } = useGetProjectTags(params, onSuccess)
	const options = useMemo(
		() => data.map((el) =>
					({
						value: el.id,
						label: el.levelVisibility ? `${el.name} (${el.level}-уровень)` : el.name,
						hidden: el.hidden,
						code: el.description
					} as IOptions)
			),
		[data]
	)
	return { data: options, ...rest }
}

export function useGetContentProjectTags(params?: Omit<paramsType, 'isForNationalProject' | 'isTheme' | 'isCollection'>, onSuccess?: onSuccessType): UseQueryResult<Tag[]> {
	return useQuery({
		queryKey: [getContentProjectTags.key, params],
		queryFn: () => getContentProjectTags.fetch(params),
		onSuccess,
	})
}
export function useGetContentProjectTagsOptions(params?: Omit<paramsType, 'isForNationalProject' | 'isTheme' | 'isCollection'>, onSuccess?: onSuccessType) {
	const { data = [], ...rest } = useGetContentProjectTags(params, onSuccess)
	const options = useMemo(
		() => data.map((el) =>
			({
				value: el.id,
				label: `${el.name} (${el.level}-уровень)`,
				hidden: el.hidden,
			} as IOptions)
		),
		[data]
	)
	return { data: options, ...rest }
}
