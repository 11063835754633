import {useQuery} from "react-query";
import {ProjectApi, ProjectTypes} from 'entities/project'
import {IOptions} from "models/feels";
import {useMemo} from "react";
import moment from "moment";
import {PROJECT_CREATE_DATE} from "constants/variables";

type onSuccessType = (data: ProjectTypes.ProjectsAllResponse[]) => void
type QueryParams = ProjectTypes.ProjectsAllRequest & { enabled?: boolean }

export function useGetProjectAll(params: QueryParams, onSuccess?: onSuccessType) {
    const {enabled = true, ...rest} = params
    return useQuery({
        queryKey: [ProjectApi.Project.projectsAllKey, rest],
        queryFn: () => ProjectApi.Project.getProjectsAll(rest),
        onSuccess,
        enabled
    });
}
export function useGetProjectAllOptions(params: QueryParams, onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetProjectAll(params, onSuccess);
    const options = useMemo(() => data.map((el) => ({
        value: el.id,
        label: el.name,
        code: el.status.code,
        check: el.isContentTaggingRequired,
        key: moment(el.projectCreateDate).isAfter(moment(PROJECT_CREATE_DATE)) ? 'new' : 'old'// Определяем, новый ли проект
} as IOptions
    )), [data]);
    return { data: options, ...rest };
}

