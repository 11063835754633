import {TTaskForm, TTaskResponse} from 'models/task'
import {IOptions} from 'models/feels'
import {OptionsTasksDto, TDefault} from './task-form.dto'
import {TInfoFormTask} from "../../task-form";
import taskValidateRules from "utils/validation/task-validate-rules";
import {UserTypes} from "entities/user";
import {ProjectTypes} from "entities/project";
import moment from "moment/moment";
import {PROJECT_CREATE_DATE} from "constants/variables";

export class StepFirstDto {
	name: TDefault<string>
	typeProject: TDefault<string> & {isReport?: boolean}
	project: TDefault<IOptions | null>
	deadline: TDefault<string | Date>
	status: TDefault<IOptions | null>
	sprint: TDefault<IOptions | null>
	archival: TDefault<string | null>
	report: TDefault<string | null> & {code?: string}

	constructor(task?: TTaskResponse | null) {
		this.name = task ? { value: task.name } : { value: '' }
		this.typeProject = task ? { value: task.type.id, isReport: task.type.code === 'REPORT' } : { value: '' }
		this.project = task ? { value: new OptionsProjectDto(task.project) } : { value: null }
		this.status = task ? { value: new OptionsTasksDto(task.status) } : { value: null }
		this.deadline = task ? { value: task.deadline } : { value: '' }
		this.sprint = task && task.sprint ? { value: new OptionsTasksDto(task.sprint) } : { value: null }
		this.archival = task && task.archival ? { value: task.archival ? 'true' : 'false' } : { value: 'false' }
		this.report = task && task.reportType ?
			{value: task.reportType.id, code: task.reportType.code } :
			{value:  null}
	}

	validate(info: TInfoFormTask) {
		for (const fieldName in this) {
			// @ts-ignore
			this[fieldName].isDisabled = validate(fieldName, info)
		}
		return this;
	}

	validateTypeProject(type?: string) {
		if (type === 'CONTENT') {
			this.report = {value: null}
		}
		return this;
	}
	validateForModerator(role?: UserTypes.RoleName) {
		if (role && role === 'ROLE_MODERATOR') {
			this.archival = { value: 'true' }
		}
		return this
	}

	setSprint(sprint: IOptions) {
		this.sprint = {value: sprint};
		return this
	}

	setProject(projectId: string) {
		this.project = {value: {value: projectId, label: projectId} as IOptions}
		return this
	}
}

export class OptionsProjectDto implements IOptions {
	value
	label
	key
	check

	constructor(model: ProjectTypes.ProjectsAllResponse) {
		this.value = model.id
		this.label = model.name
		this.key = moment(model.projectCreateDate).isAfter(moment(PROJECT_CREATE_DATE)) ? 'new' : 'old'
		this.check = model.isContentTaggingRequired
	}
}


function validate(fieldName: keyof TTaskForm, info: TInfoFormTask): boolean | undefined {
	if (!info) return undefined
	const { role, projectStatus, status } = info

	if (projectStatus === 'ACTUAL' || projectStatus === 'ARCHIVED') {
		if (role === 'ROLE_ADMIN') return taskValidateRules.roleAdmin(status, fieldName)
		if (role === 'ROLE_ADMIN_IS') return taskValidateRules.roleAdminIs(status, fieldName)
		else if (role === 'ROLE_COORDINATOR') return taskValidateRules.roleCoordinator(status, fieldName)
		else if (role === 'ROLE_CONTRACTOR') return taskValidateRules.roleContractor(status, fieldName)
		else if (role === 'ROLE_PRODUCER') return taskValidateRules.roleProducer(status, fieldName)
		else if (role === 'ROLE_ANALYST') return taskValidateRules.roleAnalyst(status, fieldName)
		else if (role === 'ROLE_FINANCIER') return taskValidateRules.roleFinancier(status, fieldName)
		else if (role === 'ROLE_PROJECT_MANAGER') return taskValidateRules.roleProjectManager(status, fieldName)
		else if (role === 'ROLE_CONTENT_EDITOR') return taskValidateRules.roleContentEditor(status, fieldName)
	} else return true
}
