import React from 'react';
import { FileImageOutlined, FileOutlined, FilePdfOutlined, FileTextOutlined, FileWordOutlined } from '@ant-design/icons';
import { FileType } from 'shared/types/file';

export function getIcon(fileType?: FileType['type'] | string) {
	switch (fileType) {
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return <FileTextOutlined />;
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return <FileWordOutlined />;
		case 'IMAGE':
		case 'image/jpeg':
		case 'image/png':
			return <FileImageOutlined />
		case 'PDF':
		case 'DOCUMENT_ADOBE':
		case 'application/pdf':
			return <FilePdfOutlined />;
		default:
			return <FileOutlined />;
	}
}
