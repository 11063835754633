import React from "react";
import NotificationComponent from "components/notification";
import Modals from "components/modals";
import RootRoutes from "routing";
import {ModalComponent as ModalExtendedSearch} from "features/extended-search";
import {ModalComponent as ModalSearchProject} from "features/search-project-content";
import withProviders from "./providers";
import {StoreActionsComponent} from "processes/store-actions";
import {ModalComponent} from "shared/ui/modal";
import Notification from "processes/notification/ui/notification";
import QueryComponent from 'processes/query/ui';

const App = () => {

    return (
        <>
            <RootRoutes/>
            <NotificationComponent/>

            <Modals/>
            <Notification/>
            <QueryComponent/>
            <ModalComponent/>

            <ModalExtendedSearch/>
            <ModalSearchProject/>

            <StoreActionsComponent/>
        </>
    )
}
export default withProviders(App);