import React, {useEffect} from 'react'
import {SelectComponent} from 'components/formic-control'
import {ProjectLib} from 'entities/project'
import {useSelector} from 'react-redux'
import {selectParams} from 'store/selectors'
import styles from '../styles.module.less'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import DropdownComponent from "./dropdown";

const SubmissionForm = () => {
	const { values, setFieldValue } = useFormikContext<TInitValues>()
	const { id } = useSelector(selectParams)
	useEffect(()=>{
		if (!values.contentFormatType?.value) setFieldValue('submissionForms', undefined)
	},[values.contentFormatType?.value])//eslint-disable-line

	const {data: submissionFormsOptions, isLoading} = ProjectLib.useGetProjectContentSubmissionByProjectOptions({projectId: id, formatTypeId: values.contentFormatType?.value})

	return (
		<div className={styles.item}>
			<span className={styles.label}>
				Формат сдачи контента <sup>*</sup>
			</span>
			<div className={styles.field}>
				<SelectComponent
					maxTagCount={3}
					loading={isLoading}
					multi={true}
					disabled={values.disabled || !values.contentFormatType?.value}
					options={submissionFormsOptions}
					name={'submissionForms'}
					placeholder={'Выберите формат сдачи контента'}
					dropdownRender={<DropdownComponent name={'submissionForms'} options={submissionFormsOptions}/>}
				/>
			</div>
		</div>
	)
}

export default SubmissionForm
