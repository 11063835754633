import {Id_Name_Code} from "../types/common";
import {axiosRequest} from "../config/axios";

/**
 * Получение всех видов площадок (интернет-ресурсов)
 */
export const getInternetResourceTypeList = {
    key: '/internet-resources/types',
    fetch: async (): Promise<Id_Name_Code[]> => {
        const { data } = await axiosRequest<Id_Name_Code[]>({
            method: 'GET',
            url: '/internet-resources/types',
        })
        return data
    },
}
