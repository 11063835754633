import React, { FC } from 'react'
import { z } from 'zod'
import Sprite from './sprite.svg'

export const variantOther = [
	'calendar',
	'home',
	'search-green',
	'search-gray',
	'circle-coin-green',
	'circle-coin-gray',
	'trophy',
	'file-search',
	'file-search-red',
	'file-search-yellow',
	'container',
	'global',
	'usergroup-add',
	'appstore-add',
	'warning-red',
	'warning-gray',
	'warning-blue',
	'delete-red',
	'delete-gray',
	'info-circle',
	'light-gray',
	'light-red',
	'star',
	'tags',
	'setting-gray',
	'setting-blue',
	'setting-red',
	'setting-green',
	'download',
	'file-done',
	'file-image',
	'link',
	'youtube',
	'message',
	'check-circle',
	'minus-circle',
	'close-circle',
	'edit-blue',
	'edit-black',
	'send-yellow',
	'send-blue',
	'retweet',
	'reload',
] as const

const variant = z.enum(variantOther)
type Props = {
	name: z.infer<typeof variant>
	size?: {
		width: number,
		height: number,
	}
}
const Other: FC<Props> = ({ name, ...props }) => {
	const {size = { width: 24, height: 24 }} = props
	return  (
		<svg {...size} {...props}>
			<use xlinkHref={`${Sprite}#${name}`} />
		</svg>
	)
}

export default Other
