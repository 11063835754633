import { FunctionalityInfoType } from '../types'

export const contentInfo: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр единиц контента с поиском, фильтрацией, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск единиц контента проекта',
				text: 'Введите наименование единицы контента в поле поиска для поиска единиц контента',
				screenshots: ['/images/screenshots/content-info/search.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация единиц контента проекта',
				text: 'Выберите значение из выпадающего списка для фильтрации единиц контента',
				screenshots: ['/images/screenshots/content-info/filters.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр единиц контента',
				text: 'Нажмите на карточку единицы контента для просмотра',
				screenshots: ['/images/screenshots/content-info/card.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества единиц контента, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице единиц контента:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр информации о проекте',
		LevelGroupData: [
			{
				name: 'Переход в карточку проекта',
				text: 'Нажмите для перехода в карточку проекта',
				screenshots: ['/images/screenshots/content-info/button-card.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_VIEWER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в задачи проекта',
				text: 'Нажмите для перехода в задачи проекта',
				screenshots: ['/images/screenshots/content-info/button-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в документы проекта',
				text: 'Нажмите для перехода в документы проекта',
				screenshots: ['/images/screenshots/content-info/button-document.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в модальное окно ссылок на публикации контента проекта в сети Интернет',
				text: 'Нажмите для перехода в модальное окно ссылок на публикации контента проекта в сети Интернет',
				screenshots: ['/images/screenshots/content-info/button-modal.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_CONTRACTOR',
					'ROLE_ANALYST',
					'ROLE_CLIENT',
					'ROLE_MODERATOR',
					'ROLE_AGENT',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход в модальное окно списка единиц контента проекта',
				text: 'Нажмите для перехода в модальное окно списка единиц контента проекта',
				screenshots: ['/images/screenshots/content-info/button-modal-content.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_CONTRACTOR'],
			},
		],
	},
	{
		LevelGroupName: 'Работа в модальном окне "Список единиц контента проекта"',
		LevelGroupData: [
			{
				name: 'Переход в модальное окно списка единиц контента проекта',
				text: 'Нажмите для перехода в модальное окно списка единиц контента проекта',
				screenshots: [
					'/images/screenshots/content-info/button-modal-content.png',
					'/images/screenshots/content-info/modal.png',
				],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_CONTRACTOR'],
			},
			{
				name: 'Переход в карточку контента из модального окна просмотра контента проекта',
				text: 'Нажмите "Посмотреть контент" для перехода в карточку контента проекта в окне "Список единиц контента проекта"',
				screenshots: ['/images/screenshots/content-info/view-content.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_CONTRACTOR'],
			},
			{
				name: 'Скачивание единиц контента из модального окна просмотра контента проекта',
				text: 'Для скачивания единиц контента проекта в окне "Список единиц контента проекта":',
				points: [
					'Нажмите чекбокс в баннере "Согласен с положениями Руководства по использованию контента"',
					'Выберите единицу контента из списка и нажмите иконку "Скачать" либо используйте массовое действие "Сформировать архив контента для скачивания"',
				],
				screenshots: ['/images/screenshots/content-info/modal.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_CONTRACTOR'],
			},
			{
				name: 'Массовое скачивание единиц контента из модального окна просмотра контента проекта',
				text: 'Для массового скачивания единиц контента проекта в модальном окне "Список единиц контента проекта":',
				points: [
					'Нажмите "Массовые действия"',
					'Нажмите "Сформировать архив контента для скачивания"',
					'Нажмите чекбоксы (галочки) для выбора единиц контента для скачивания',
					'Нажмите "Применить"',
				],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_CONTRACTOR'],
				screenshots: ['/images/screenshots/bulk-actions.png'],
			},
			{
				name: 'Просмотр связанных файлов контента по клику на компонент "Посмотреть связанные файлы"',
				text: 'Нажмите "Посмотреть связанные файлы". В модальном окне просмотра у вас есть возможность:',

				screenshots: ['/images/screenshots/view-files-button/view.png'],
				points: [
					'Просматривать файлы',
					'Просматривать информацию о файлах записи',
					'Скачать файл',
					'Скопировать ссылку на СХД',
					'Посмотреть метаданные файла',
				],
				important: [
					'! Некоторые функции могут быть не доступны при отсутствии прав'
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование единиц контента проекта',
		LevelGroupData: [
			{
				name: 'Переход в окно редактирования единиц контента проекта',
				text: 'Нажмите "Редактировать" для перехода в окно редактирования единиц контента проекта',
				screenshots: ['/images/screenshots/content-info/card-edit.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_PRODUCER', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Редактирование единиц контента проекта',
				points: ['Заполните обязательные и доступные поля формы ', 'Сохраните форму'],
				screenshots: ['/images/screenshots/content-info/form.png'],
				roles: ['ROLE_ADMIN_IS', 'ROLE_ADMIN', 'ROLE_PRODUCER', 'ROLE_MODERATOR', 'ROLE_PROJECT_MANAGER'],
			},
			{
				name: 'Редактирование данных тайм-кодов видео-, аудиоконтента в окне "Посмотреть загруженные файлы записи"',
				text: 'Для редактирования данных тайм-кодов единиц контента в модальном окне "Просмотр загруженных файлов записи"',
				points: [
					'Нажмите на иконку часов  "Редактирование тайм-кодов файлов"',
					'В форме "Информация о тайм-кодах файла" нажмите "Добавить тайм-код"',
					'Укажите хронометраж и  наименование метки хронометража',
					'Нажмите "Сохранить" для сохранения данных тайм-кодов',
					'Вы можете удалить таймкод контента по иконке "Удалить"',
				],
				screenshots: [
					'/images/screenshots/time-codes.png',
				],
				roles: [
					'ROLE_COORDINATOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_PRODUCER',
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_MODERATOR',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование единиц контента проекта. Работа с загрузкой файлов записей задач по клику на компонент "Изменить связанные файлы"',
		LevelGroupData: [
			{
				name: 'Загрузка файла',
				text: 'В модальном окне редактирования записи задачи нажмите "Изменить связанные файлы" для перехода в окно редактирования файлов',
				text2: 'Для загрузки файла используйте компонент "Перенесите файл в область или выберите"',
				screenshots: ['/images/screenshots/view-files-button/upload.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Вкладка "Активные загрузки"',
				text: 'В модальном окне редактирования записи задачи нажмите "Изменить связанные файлы" для перехода в окно редактирования файлов',
				text2: 'Во вкладке "Активные загрузки" вы имеете возможность:',
				screenshots: ['/images/screenshots/view-files-button/active.png'],
				points: [
					'Приостановить загрузку файла',
					'Восстановить приостановленную загрузку',
					'Прервать загрузку для перевода в "Незавершенные загрузки"',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Вкладка "Незавершенные загрузки"',
				text: 'В модальном окне редактирования записи задачи нажмите "Изменить связанные файлы" для перехода в окно редактирования файлов',
				text2: 'Во вкладке "Незавершенные загрузки" вы имеете возможность:',
				screenshots: ['/images/screenshots/view-files-button/unfinished.png'],
				points: [
					'Возобновить загрузку',
					'Удалить незавершенную загрузку',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Вкладка "Завершенные загрузки"',
				text: 'В модальном окне редактирования записи задачи нажмите "Изменить связанные файлы" для перехода в окно редактирования файлов',
				text2: 'Во вкладке "Завершенные загрузки" вы имеете возможность:',
				screenshots: ['/images/screenshots/view-files-button/finished.png'],
				points: [
					'Возобновить загрузку',
					'Удалить завершенную загрузку',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		]
	}
]
