import React, {useState} from 'react';
import styles from './styles.module.less'
import {Spin} from "antd";
import Checkbox from "antd/es/checkbox";
import {ChangeDownloadRightsStartAction} from "store/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthStatus, selectDownloadRights} from "store/auth/selectors";

const DownloadRights = () => {
    const downloadRights = useSelector(selectDownloadRights)
    const [state, setState] = useState(downloadRights)
    const put = useDispatch();
    const {isLoading} = useSelector(selectAuthStatus)
    const handlerDownloadRights = () => {
        setState(!state)
        put(ChangeDownloadRightsStartAction(!state))
    }

    return (
        <Spin spinning={isLoading}>
            <div className={styles.confirm}>
                <p>Контент для скачивания будет доступен после ознакомления с <a rel="noreferrer" target="_blank" href={window.location.origin + '/docs/Руководство_по_использованию_Контента_и_форма_запроса_ответа_на.pdf'}>Руководством по использованию контента</a> и предоставления согласия с его положениями</p>
                <div className="form-group">
                    <Checkbox checked={state} onChange={handlerDownloadRights}>
                        <span className="label">Согласен с положениями Руководства по использованию контента</span>
                    </Checkbox>
                </div>
            </div>
        </Spin>
    )
};

export default DownloadRights;
