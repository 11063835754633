import { EntityType } from '../types';

export function checkFileLimitByEntityType({entityType, isCounterScreenshot, fileCount}:{ entityType: EntityType
	isCounterScreenshot?: boolean, fileCount: number }) {
	if (entityType === 'POSTING_RECORD') {
		return fileCount >= 5 ? 'Общее количество файлов для поля "Скриншот" не может превышать 5' : undefined;
	}
	if (entityType === 'REACH_RECORD') {
		if (!isCounterScreenshot) {
			return fileCount >= 5 ? 'Общее количество файлов для поля "Скриншот на фиксацию охватов" не может превышать 5' : undefined;
		} else {
			return fileCount >= 12 ? 'Общее количество файлов для поля "Скриншот счетчика" не может превышать 12' : undefined;
		}
	}

}