import {AxiosResponse} from "axios";
import $api from "../http";
import {TTaskRecord} from "../models/task/task-content";
import {TTaskRecordsFilter} from "../models/task/task-list";
import {TContentSettingsFilter} from "../store/content/settings/reducer";
import {TaskTypes} from 'entities/task'

/**
 * @category Service
 * @class TasksContentService
 */
export default class TasksContentService {
    /**
     * @method addTaskRecord
     * @description Добавление записи для задачи контента
     */
    static async addTaskRecord(taskId: string): Promise<AxiosResponse<string>> {
        return $api({
            method: 'POST',
            url: `/tasks/content/${taskId}/records`,
            headers: {
                'Actioncode': 'CREATE_TASKS_CONTENT_TASK_ID_RECORDS'
            }
        })
    }
    /**
     * Получение записи для задачи контента
     */
    static getTaskRecordKey = (recordId: string) => `/tasks/content/records/${recordId}`
    static async getTaskRecord(recordId: string): Promise<AxiosResponse<TaskTypes.ContentRecord>> {
        return $api.get<TaskTypes.ContentRecord>(`/tasks/content/records/${recordId}`)
    }
    /**
     * Обновление записи для задачи контента
     */
    static async updateTaskRecord(recordId: string, data: TTaskRecord): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/content/records/${recordId}`, data)
    }
    /**
     * Получение списка одобренных записей задач на контент текущего пользователя по проекту
     */
    static async getContentTaskRecord(projectId: string): Promise<AxiosResponse<any>> {
        return $api({
            method: 'GET',
            params : {projectId: projectId},
            url: `/tasks/content/records/approved`
        })
    }
    /**
     * Удаление записи задачи контента
     */
    static async deleteTaskRecord(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/content/records/${recordId}`)
    }
    /**
     * Голосование за запись
     */
    static async voteTaskRecord({files, ...rest}: TaskTypes.NewVote): Promise<AxiosResponse<string>> {
        const formData = new FormData();
        formData.append('vote', JSON.stringify(rest.vote));
        files?.forEach((el) => {
            formData.append('files', el as unknown as Blob)
        })
        return $api.post<string>(`/tasks/content/records/vote`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }
    /**
     * Удаление промежуточного голоса текущего пользователя по записи задачи
     */
    static async deleteInterimVoteRecord(recordId: string): Promise<AxiosResponse<any>> {
        return $api.delete<any>(`/tasks/content/records/${recordId}/vote`,{
            headers: {
                'Actioncode': 'DELETE_TASKS_CONTENT_RECORDS_RECORD_ID_VOTE'
            }
        })
    }
    static async ToExaminationTaskReport(recordId: string): Promise<AxiosResponse<string>> {
        return $api.put<string>(`/tasks/content/records/${recordId}/to-examination`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_CONTENT_RECORDS_RECORD_ID_TO_EXAMINATION'
            }
        })
    }
    /**
     * Получение списка записей задач
     */
    static async getTaskRecords(filterProps: TTaskRecordsFilter | TContentSettingsFilter): Promise<AxiosResponse<TaskTypes.TaskRecordsResponse<TaskTypes.ContentRecords[]>>> {
        const filter = {...filterProps, page: filterProps.page - 1};
        return $api.post<TaskTypes.TaskRecordsResponse<TaskTypes.ContentRecords[]>>(`/tasks/content/records/search`, filter)
    }
    /**
     * Получение одной записи задачи
     */
    static getRecordKey = '/tasks/content/records/search/';
    static async getRecord(recordId: string): Promise<AxiosResponse<TaskTypes.ContentRecord>> {
        return $api.get<TaskTypes.ContentRecord>(`/tasks/content/records/search/${recordId}`)
    }
    /**
     * Сброс итогов голосования финального статуса записи
     */
    static async resetTaskRecordsStatus(recordId: string): Promise<AxiosResponse<any>> {
        return $api.put<any>(`/tasks/content/records/${recordId}/status-reset`, undefined, {
            headers: {
                'Actioncode': 'UPDATE_TASKS_CONTENT_RECORDS_RECORD_ID_STATUS_RESET'
            }
        })
    }
    /**
     * Массовое голосование за записи
     */
    static async sendBulkVote(data: TaskTypes.NewVote['vote'][]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/tasks/content/records/bulk-vote', data)
    }
    /**
     * Массовая отправка записей на проверку
     */
    static async sendBulkReview(data: string[]): Promise<AxiosResponse<any>> {
        return $api.put<any>('/tasks/content/records/bulk/to-examination', data)
    }
    /**
     * Создание запроса на получение архива файлов записей задачи контента
     */
    static async createFileArchive(data: string[]): Promise<AxiosResponse<any>> {
        return $api.post<any>('/tasks/content/records/file-archive-request', data)
    }
    /**
     * Получить список идентификаторов пользователей из таблицы связей исключения видимости контента
     * для конкретной записи контента
     */
    static async getExcludedUsers(recordId: string): Promise<AxiosResponse<string[]>> {
        return $api.get(`/tasks/content/records/${recordId}/excluded-users`)
    }
    /**
     * Установить исключение видимости контента для пользователей
     */
    static async sendExcludedUsers(recordId: string, recordsId: string[]): Promise<AxiosResponse<unknown>> {
        return $api.post(`/tasks/content/records/${recordId}/excluded-users`, recordsId)
    }
    static async recordsExport(usersIds: string[]): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: '/tasks/content/records/export',
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: usersIds,
        })
    }
}
