import { AxiosResponse } from 'axios'
import $api from '../http'
import { TProjectDocumentsFilter } from '../models/projects/filter'
import { TDocumentFile, TDocumentsAll, TResponseProjectDocuments } from '../models/projects/project-document'

/**
 * Projects Documents API
 */
export default class ProjectDocumentsService {
	/**
	 * Получение списка документов с фильтрами
	 */
	static async getDocumentsWithFilter(
		data: TProjectDocumentsFilter
	): Promise<AxiosResponse<TResponseProjectDocuments>> {
		const filter = { ...data, page: data.page - 1 }
		return $api.post<TResponseProjectDocuments>('/projects/documents/search', filter)
	}

	/**
	 * Добавить файл
	 */
	static async addFile(projectId: string): Promise<AxiosResponse<string>> {
		return $api({
			method: 'POST',
			url: `/projects/${projectId}/documents`,
			headers: {
				'Actioncode': 'POST_PROJECTS_PROJECTID_DOCUMENTS'
			}
		})
	}

	/**
	 * Получение списка документов всех проектов без пагинации
	 */
	static async getDocuments(): Promise<AxiosResponse<TDocumentsAll[]>> {
		return $api.get<TDocumentsAll[]>('/projects/documents')
	}

	/**
	 * Получение списка документов по ID проекта
	 */
	static async getDocumentsId(projectId: string): Promise<AxiosResponse<TDocumentsAll[]>> {
		return $api.get<TDocumentsAll[]>(`/projects/documents/${projectId}`)
	}

	/**
	 * Обновление документа проекта
	 */
	static async updateDocumentId(documentId: string, data: TDocumentFile): Promise<AxiosResponse> {
		return $api({
			method: 'PUT',
			url: `/projects/documents/${documentId}`,
			data,
		})
	}

	/**
	 * Удаление документа проекта
	 */
	static async deleteDocumentId(id: string): Promise<AxiosResponse> {
		return $api.delete<any>(`/projects/documents/${id}`)
	}
}
