import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
	selectAnalystsOptions,
	selectAppStatus,
	selectCategoriesOptions,
	selectContentEditorsOptions,
	selectContractorsId,
	selectContractorsOptions,
	selectCoordinatorsOptions,
	selectFinanciersOptions,
	selectFormatKpiOptions,
	selectFormatSubmissionByIdOptions,
	selectFormatTypesOptions,
	selectLawyersOptions,
	selectProducersOptions,
	selectProjectManagersOptions,
	selectRankOptions,
	selectReportTypeOptions,
	selectRolesOptions,
	selectStatusesOptions,
	selectThematicsOptions,
} from 'store/app/selectors'
import {TStatusesFeels} from 'models/projects/projectFeels'
import {GetFeelsStartAction} from 'store/app/actions'

const useFeelsApp = (feels?: TStatusesFeels[], id?: string) => {
	const put = useDispatch()
	const data = {
		'formatKpi': useSelector(selectFormatKpiOptions),
		'formatTypes': useSelector(selectFormatTypesOptions),
		'statuses': useSelector(selectStatusesOptions),
		'thematics': useSelector(selectThematicsOptions),
		'reportType': useSelector(selectReportTypeOptions),
		'roles': useSelector(selectRolesOptions),

		'contractors': useSelector(selectContractorsOptions),
		'contractorsId': useSelector(selectContractorsId),
		'coordinators': useSelector(selectCoordinatorsOptions),
		'analysts': useSelector(selectAnalystsOptions),
		'producers': useSelector(selectProducersOptions),
		'financiers': useSelector(selectFinanciersOptions),
		'lawyers': useSelector(selectLawyersOptions),
		'contentEditors': useSelector(selectContentEditorsOptions),
		'projectManagers': useSelector(selectProjectManagersOptions),
		'formatSubmission': useSelector(selectFormatSubmissionByIdOptions),
		'ranks': useSelector(selectRankOptions),
		'categories': useSelector(selectCategoriesOptions),
	}

	const { isLoading } = useSelector(selectAppStatus)

	useEffect(() => {
		if (feels) {
			feels.forEach((el) => {
				if ((el === 'formatSubmission' || el === 'contractorsId' || 'internetResourcesPostingTask')
					&& id && data[el] === null) put(GetFeelsStartAction(el, id))
				else if (el !== 'formatSubmission' && data[el] === null) {
					put(GetFeelsStartAction(el))
				}
			})

		}
	}, [])// eslint-disable-line
	return {isLoading, ...data}
}
export default useFeelsApp
