import React, { FC, useEffect } from 'react';
import styles from './styles.module.less'
import DocViewer from '@cyntler/react-doc-viewer'
import env from 'shared/config/env'
import keycloak from 'Keycloak'
import { Button } from 'antd';
import { Query } from 'processes/query';
import $api from '../../../../http';
import CreateErrorMessage from 'utils/validation/createErrorMessage';
import {Notification} from 'processes/notification'

type Props = {
	extension: string
	fileId?: string
	fileType?: string
	previewFileFileId?: string
	name?: string
	invalidate?: boolean
	closeModal?: () => void
}

const path = `${env.API_ENDPOINT}/files/`
const DocumentViewer: FC<Props> = ({ fileId, previewFileFileId, extension, name, invalidate, closeModal, fileType }) => {

	useEffect(() => {
		if (fileId && !previewFileFileId && fileType !== 'PDF') {

			generatePreview(fileId).then(() => {})
		}
	}, [fileId, previewFileFileId, fileType])

	if (!previewFileFileId && fileType !== 'PDF') {
		return (
			<div className={styles.loader_container}>
				<h3 className={styles.title}>Файл для просмотра формируется, подождите завершения процесса</h3>
				<MyDynamicEllipsis />
				<Button type="primary" onClick={() => {
					if (invalidate) Query.invalidate(['task-files-uploader']);
					closeModal && closeModal();
				}}>
					Обновить
				</Button>
			</div>
		)
	}

	return (
		<DocViewer
			className={styles.doc_viewer}
			documents={[
				{
					uri: `${path}${previewFileFileId || fileId}`,
					fileType: extension,
					fileName: name,
				},
			]}
			requestHeaders={{
				'Authorization': `Bearer ${keycloak.token}`,
			}}
		/>
	)
}

function MyDynamicEllipsis() {
	return (
		<div className={styles.dynamic_ellipsis}>
			<span className={styles.dot1} />
			<span className={styles.dot2} />
			<span className={styles.dot3} />
		</div>
	)
}
async function generatePreview(fileId: string) {
	try {
		await $api({
			method: 'POST',
			url: `/files/generate-preview-files`,
			data: [fileId],
		})
	} catch (error) {
		const message = CreateErrorMessage.response(error)
		Notification.open({ type: 'success', info: {message: message ? message : 'Расписание успешно отключено'}});
	}
}
export default DocumentViewer
