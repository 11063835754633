import {SearchContentProjectModel, SearchContentProjectTypes} from "features/search-project-content";
import {all, put} from "redux-saga/effects";
import {Logger} from "shared/services";
import {Types} from "features/user-filters/types";
import {getTypeContent} from "features/user-filters/lib";

type Props = {
    filterType: Types["code"]
    filterData: string,
    needToMakeRequest: boolean,
    filterId?: string
}

export function* searchWorker({needToMakeRequest, filterType, filterData, filterId}: Props) {
    try {
        const typeContent = getTypeContent(filterType);
        if (filterId) {
            const filter: SearchContentProjectTypes.Stores.TAllFilterValues = JSON.parse(filterData);
            yield all([
                put(SearchContentProjectModel.actions.setCategoriesGenres(
                    typeContent,
                    filter.categoriesGenres,
                    !!filter.categoriesGenres.length
                )),
                put(SearchContentProjectModel.actions.setValuesContent(
                    typeContent,
                    filter.content,
                    [
                        !!filter.content.contentFilterContentSubmissionFormIds,
                        !!filter.content.contentFormatTypeIds
                    ].some(value => value)
                )),
                put(SearchContentProjectModel.actions.setValuesContests(typeContent, {
                    ...(!!filter.contests.contestId && { contestId: filter.contests.contestId}),
                    ...(!!filter.contests.year && { year: `${filter.contests.year}`}),
                    ...(!!filter.contests.sessionId && { sessionId: filter.contests.sessionId}),
                }, [
                    !!filter.contests.year,
                    !!filter.contests.sessionId,
                    !!filter.contests.contestId
                ].some(value => value))),
                put(SearchContentProjectModel.actions.setDates(typeContent, filter.dates, !!filter.dates.length)),
                put(SearchContentProjectModel.actions.setValuesIndicators(typeContent, {
                    isProjectPosted: filter.indicators.isProjectPosted !== undefined ? `${filter.indicators.isProjectPosted}` : 'Все проекты',
                    isFavoriteProject: filter.indicators.isFavoriteProject !== undefined ? `${filter.indicators.isFavoriteProject}` : 'Все проекты',
                }, [
                  filter.indicators.isProjectPosted !== undefined,
                  filter.indicators.isFavoriteProject !== undefined,
                ].some(Boolean))),
                put(SearchContentProjectModel.actions.setValuesIndicatorsContent(typeContent, {
                    isContent: filter.indicatorsContent?.isContent !== undefined ? filter.indicatorsContent?.isContent : 'Все проекты',
                    isAccountedContent: filter.indicatorsContent.isAccountedContent !== undefined ? filter.indicatorsContent.isAccountedContent : 'Все проекты',
                    isMasterContent: filter.indicatorsContent.isMasterContent !== undefined ? `${filter.indicatorsContent.isMasterContent}` : 'Все проекты',
                    contentStatusCodes: filter.indicatorsContent?.contentStatusCodes || []
                }, [
                    filter.indicatorsContent?.isContent !== undefined,
                    filter.indicatorsContent.isAccountedContent !== undefined,
                    filter.indicatorsContent.isMasterContent !== undefined,
                    !!filter.indicatorsContent?.contentStatusCodes?.length,
                ].some(value => value))),
                put(SearchContentProjectModel.actions.setRegionals(typeContent, filter.regionals, !!filter.regionals.length)),
                put(SearchContentProjectModel.actions.setStatus(
                    typeContent,
                    filter.status,
                    [
                        !!filter.status.statusIds.length,
                        !!filter.status.contentDirectionIds.length
                    ].some(value => value)
                )),
                put(SearchContentProjectModel.actions.setThematics(
                    typeContent,
                    filter.thematics,
                    [
                        !!filter.thematics.contentTagIds.length,
                        !!filter.thematics.projectTagIds.length,
                    ].some(value => value)
                )),
            ])
        } else {
            yield all([
                put(SearchContentProjectModel.actions.clearCategoriesGenres(typeContent)),
                put(SearchContentProjectModel.actions.clearContent(typeContent)),
                put(SearchContentProjectModel.actions.clearContests(typeContent)),
                put(SearchContentProjectModel.actions.clearDates(typeContent)),
                put(SearchContentProjectModel.actions.clearIndicators(typeContent)),
                put(SearchContentProjectModel.actions.clearIndicatorsContent(typeContent)),
                put(SearchContentProjectModel.actions.clearRegionals(typeContent)),
                put(SearchContentProjectModel.actions.clearStatus(typeContent)),
                put(SearchContentProjectModel.actions.clearThematics(typeContent)),
            ])
        }
        if (needToMakeRequest) {
            yield put(SearchContentProjectModel.actions.FetchCF(typeContent))
        }
    } catch (error) {
        Logger.error(error)
    }
}
