import React, {FC, useState} from 'react';
import './index.less'
import {Collapse, Empty, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import {ProjectThematics, TCategoriesGenres, TRegions} from "models/projects";
import {CaretDownOutlined, CaretRightOutlined} from "@ant-design/icons";
import WhetherTheUser from "../../whetherTheUser";

const { Panel } = Collapse;

export type ModalProjectInfoProps = {
    type: 'thematics' | 'regions' | 'categoriesGenres',
    thematics?: ProjectThematics,
    regions?: TRegions
    categoriesGenres?: TCategoriesGenres,
    title?: string
    hideField?: {
        regions?: {
            contentLocationRegions?: true
        }
        thematics?: {
            contentThematics?: true
            firstLevelTags?: true
        }
    }
}

type ModalFamousPeopleProps = {
    status: boolean,
    data: ModalProjectInfoProps
}

const ModalProjectInfo: FC<ModalFamousPeopleProps> = ({status, data }) => {
    const {isVisible, closeModal} = useHandlerModal(status);
    const getTitle = () => {
        if (data.title) return data.title;
        else {
            switch (data.type) {
                case "regions":
                    return 'Регионы проекта';
                case "thematics":
                    return 'Тематика проекта'
                case "categoriesGenres":
                    return 'Категории и жанры проекта'
                default:
                    return ''
            }
        }
    }
    return (
        <Modal
            className='modal-projectInfo'
            title={getTitle()}
            visible={isVisible}
            footer={null}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='content'>
                {data.type === 'thematics' && Thematics({thematics: data.thematics, hideFields: data.hideField})}
                {data.type === 'regions' && Regions({regions: data.regions, hideFields: data.hideField})}
                {data.type === 'categoriesGenres' && CategoriesGenres({categoriesGenres: data.categoriesGenres})}
            </div>
        </Modal>
    )
};
function Thematics({thematics, hideFields}: {thematics?: ProjectThematics, hideFields: ModalProjectInfoProps["hideField"]}) {
    return (
        <>
            {!hideFields?.thematics?.contentThematics && (
                <div className="item">
                    <div className="title">Тематическая линия</div>
                    <ul className="list">
                        {thematics?.contentThematics.map((thematics) => (
                            <Item key={thematics.id} name={thematics.name}/>
                        ))}
                    </ul>
                </div>
            )}
            <div className="item">
                <div className="title">Тематики проекта</div>
                <ul className="list">
                    {thematics?.projectThemeTags.length ? thematics?.projectThemeTags.map((tag) => (
                        <Item key={tag.id} name={tag.name}/>
                    )) : 'Теги отсутствуют'}
                </ul>
            </div>
            <div className="item">
                <div className="title">Теги проекта</div>
                <ul className="list">
                    {thematics?.projectTags.length ? thematics?.projectTags.map((tag) => (
                        <Item key={tag.id} name={tag.name}/>
                    )) : 'Теги отсутствуют'}
                </ul>
            </div>
            <WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER', 'ROLE_AGENT', 'ROLE_CLIENT']} isCan={false}>
                <>
                    <div className="item">
                        <div className="title">Тег поручений 1-го уровня</div>
                        <ul className="list">
                            {thematics?.firstLevelAssignmentTags?.length ? thematics?.firstLevelAssignmentTags.map((tag) => (
                                <Item key={tag.id} name={tag.name}/>
                            )) : 'Теги отсутствуют'}
                        </ul>
                    </div>
                    <div className="item">
                        <div className="title">Тег поручений 2-го уровня</div>
                        <ul className="list">
                            {thematics?.secondLevelAssignmentTags?.length ? thematics?.secondLevelAssignmentTags.map((tag) => (
                                <Item key={tag.id} name={tag.name}/>
                            )) : 'Теги отсутствуют'}
                        </ul>
                    </div>
                    <div className="item">
                        <div className="title">Тег подборок 1-го уровня</div>
                        <ul className="list">
                            {thematics?.firstLevelCollectionTags?.length ? thematics?.firstLevelCollectionTags.map((tag) => (
                                <Item key={tag.id} name={tag.name}/>
                            )) : 'Теги отсутствуют'}
                        </ul>
                    </div>
                    <div className="item">
                        <div className="title">Тег подборок 2-го уровня</div>
                        <ul className="list">
                            {thematics?.secondLevelCollectionTags?.length ? thematics?.secondLevelCollectionTags.map((tag) => (
                                <Item key={tag.id} name={tag.name}/>
                            )) : 'Теги отсутствуют'}
                        </ul>
                    </div>
                </>
            </WhetherTheUser>
            <div className="item">
                <div className="title">Тег нац.проекта 1-го уровня</div>
                <ul className="list">
                    {thematics?.firstLevelNationalTags?.length ? thematics?.firstLevelNationalTags.map((tag) => (
                        <Item key={tag.id} name={tag.name}/>
                    )) : 'Теги отсутствуют'}
                </ul>
            </div>
            <div className="item">
                <div className="title">Тег нац.проекта 2-го уровня</div>
                <ul className="list">
                    {thematics?.secondLevelNationalTags?.length ? thematics?.secondLevelNationalTags.map((tag) => (
                        <Item key={tag.id} name={tag.name}/>
                    )) : 'Теги отсутствуют'}
                </ul>
            </div>
        </>
    )
}

function CategoriesGenres({categoriesGenres}: { categoriesGenres?: TCategoriesGenres }) {
    if (categoriesGenres?.genreTypes?.length === 0 && categoriesGenres?.categoryTypes?.length === 0) {
        return <Empty/>
    }
    return (
        <>
            <div className="item">
                <div className="title">Категории</div>
                <ul className="list">
                    {categoriesGenres?.categoryTypes?.length ? categoriesGenres?.categoryTypes?.map((category) => (
                        <Item key={category.id} name={category.name}/>
                    )) : 'Категории отсутствуют'}
                </ul>
            </div>
            <div className="item">
                <div className="title">Жанры</div>
                <ul className="list">
                    {categoriesGenres?.genreTypes?.length ? categoriesGenres?.genreTypes?.map((genre) => (
                        <Item key={genre.id} name={genre.name}/>
                    )) : 'Жанры отсутствуют'}
                </ul>
            </div>
        </>
    )
}


type Items = 'regionAboutSet' | 'regionForSet' | 'contentLocationRegions' | 'filmClusters'

const initState: Items[] = ['regionAboutSet', 'regionForSet', 'contentLocationRegions', 'filmClusters']

function Regions({regions, hideFields}: { regions?: TRegions, hideFields: ModalProjectInfoProps["hideField"] }) {
    const [open, setOpen] = useState<typeof initState>(initState);

    if (regions?.regionForSet?.length === 0 && regions?.regionAboutSet?.length === 0 && regions?.contentLocationRegions?.length === 0) {
        return <Empty/>
    }

    function onCollapseChange(key: string | string[]) {
        if (typeof key === 'string') setOpen([key as Items])
        else setOpen(key as Items[])
    }

    return (
        <>
            <Collapse onChange={onCollapseChange} activeKey={open} expandIcon={({isActive}) => {
                return isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
            }}>
                <Panel
                    className="title"
                    header="О каком регионе проект"
                    key="regionAboutSet"

                >
                    <div className="item">
                        <ul className="list">
                            {regions?.regionAboutSet?.length ? regions?.regionAboutSet?.map((region) => (
                                <Item key={region.id} name={region.name}/>
                            )) : 'Теги отсутствуют'}
                        </ul>

                    </div>
                </Panel>
                <WhetherTheUser role={['ROLE_ADMIN','ROLE_ADMIN_IS']} isCan={true}>
                    <>
                        {!!regions?.regionForSet?.length && (
                            <Panel
                                className="title"
                                header="Для какого региона проект"
                                key="regionForSet"

                            >
                                <div className="item">
                                    <ul className="list">
                                        {
                                            regions?.regionForSet?.map((region) => (
                                                <Item key={region.id} name={region.name}/>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Panel>
                        )}
                    </>
                </WhetherTheUser>
                {!hideFields?.regions?.contentLocationRegions && (
                    <Panel
                        className="title"
                        header="Место съемок проекта"
                        key="contentLocationRegions"
                    >
                        <div className="item">
                            <ul className="list">
                                {regions?.contentLocationRegions?.length ? regions?.contentLocationRegions?.map((region) => (
                                    <Item key={region.id} name={region.name}/>
                                )) : 'Теги отсутствуют'}
                            </ul>
                        </div>
                    </Panel>
                )}
                <Panel
                    className="title"
                    header="Кинокластер проекта"
                    key="filmClusters"

                >
                    <div className="item">
                        <ul className="list">
                            {regions?.filmClusters?.length ? regions?.filmClusters?.map((region) => (
                                <Item key={region.id} name={region.name}/>
                            )) : 'Теги отсутствуют'}
                        </ul>
                    </div>
                </Panel>
            </Collapse>
        </>
    )
}

function Item({name}: {name: string}) {
    return <li>{name}</li>
}
export default ModalProjectInfo;
