import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useFormikContext} from 'formik'
import useTasksFields from 'hooks/tasksField'

import {Col, Row, Select, Spin, Tooltip} from 'antd';
import {InputComponent, RadioButtonsComponent, SelectComponent} from 'components/formic-control'
import DeadlineComponent from "../feels/deadline";

import {selectProjectsStatus} from 'store/projects/selectors'
import {selectRole} from 'store/auth/selectors'
import {selectSprintsListOptions} from 'store/sprints/selectors'
import {GetSprintsStartAction} from 'store/sprints/actions'

import {transformReportType, transformTypeProject} from "../utils/utils";
import {TaskFormDto} from '../domain/dto/task-form.dto'
import {IOptions} from 'models/feels'
import {ReportType, TypesTask} from 'models/task/types'
import {ProjectLib} from 'entities/project'
import {TTasksTypesFields} from 'models/task'
import ReloadList from "../../components/reload-list/reload-list";
import useFeelsApp from "hooks/feelsApp";
import {TStatusesFeels} from "models/projects/projectFeels";
import classNames from "classnames";
import WhetherTheUser from "../../../whetherTheUser";

const { Option } = Select;

export function filingName(key: string): string {
	return `stepFirst.${key}.value`
}

const StepFirst = () => {
	const put = useDispatch()
	const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<TaskFormDto>()
	const { isLoading, types, statuses } = useTasksFields([TTasksTypesFields.types])
	const { reportType, isLoading: isLoadingFeelsApp } = useFeelsApp([TStatusesFeels.reportType])
	const {data: actualProjectsOptions = []} = ProjectLib.useGetProjectAllOptions({statusCodes: ['ACTUAL', 'ARCHIVED']})

	const role = useSelector(selectRole)
	const { isLoading: isLoadingProject } = useSelector(selectProjectsStatus)
	const sprintsOptions = useSelector(selectSprintsListOptions)


	const form = values.stepFirst
	const formErrors = errors.stepFirst



	useEffect(() => {
		if (role === 'ROLE_ANALYST') {
			const typeReport = types?.find(it=> it.code === 'REPORT')
			const report = reportType?.find(it => it.code === ReportType.CONTENT_REACH_REPORT)

			setFieldValue('stepFirst.report',{value: report?.value, code: report?.code})
			if (typeReport?.value) setFieldValue('stepFirst.typeProject',{value: typeReport?.value, isReport: true })
		}

		if (role === 'ROLE_FINANCIER') {
			const typeReport = types?.find(it=> it.code === 'REPORT')
			const report = reportType?.find(it => it.code === ReportType.PROJECT_ESTIMATE)

			setFieldValue('stepFirst.report',{value: report?.value, code: report?.code})
			if (typeReport?.value) setFieldValue('stepFirst.typeProject',{value: typeReport?.value, isReport: true })
		}

		if (role === 'ROLE_CONTENT_EDITOR') {
			const typeContent = types?.find(it=> it.code === 'CONTENT')
			if (typeContent?.value) setFieldValue('stepFirst.typeProject',{value: typeContent?.value, isReport: false })
		}
		// eslint-disable-next-line
	}, [types, role]);

	const handlerSelectProject = (obj: IOptions) => {
		if (obj) put(GetSprintsStartAction(obj.value))
		setFieldValue('stepFirst.sprint.value', null)
		setFieldTouched('stepFirst.sprint.value', false)
		setFieldTouched('stepFirst.sprint.value', false)

		if (values.isEdit && obj.key === 'new') { // если проект новый (это мы определяем в ключе key), тогда поле contentTagsContractorVisible скрыто и записываем в него значение isContentTaggingRequired которое записали в check
			setFieldValue('stepSecond.contentTagsContractorVisible.value', obj.check)
		}
	}

	const handlerSprint = () => {
		setFieldValue('stepFirst.deadline.value', null)
	}

	const handlerTypeProject = (id: string, code: TypesTask) => {
		if (id) {
			values.stepFirst.validateTypeProject(code)
			values.stepSecond.validateTypeProject(code)
			setFieldValue('stepFirst.typeProject',{value: id, isReport: code === 'REPORT' })
		}
	}

	const handlerUpdateSprint = () => {
		if (form.project.value?.value) put(GetSprintsStartAction(form.project.value?.value))
	}

	const handlerTypeReport = (id: string, code: ReportType) => {
		setFieldValue('stepFirst.report',{value: id, code: code })
	}

	return (
		<>
			<Row className="taskForm-form__item" align="middle">
				<Col md={5} xs={24} className="label">
					Название<sup>*</sup>
				</Col>
				<Col md={19} xs={24}>
					<InputComponent
						type="text"
						name={filingName('name')}
						placeholder="Введите название задачи"
						disabled={form.name.isDisabled}
						errorMessage={formErrors?.name?.value as string}
					/>
				</Col>
			</Row>
			<Row className="taskForm-form__item">
				<Col lg={5} md={5} xs={24} className="label">
					Тип задачи<sup>*</sup>
				</Col>
				<Col lg={7} md={7} xs={24}>
					<Spin spinning={isLoading}>
						{types && (
							<RadioButtonsComponent
								name={filingName('typeProject')}
								options={transformTypeProject(role, types as IOptions<TypesTask>[])}
								disabled={form.typeProject.isDisabled}
								notSetField={true}
								handler={handlerTypeProject}
							/>
						)}
					</Spin>
				</Col>
				<Col lg={1} span={0}/>
				<Col lg={2} md={2} xs={24} className="label margin-md-top15 nowrap">
					Проект<sup>*</sup>
				</Col>
				<Col lg={9} md={10} xs={24}>
					<Spin spinning={isLoadingProject}>
						<SelectComponent
							name={filingName('project')}
							placeholder="Выберите"
							options={actualProjectsOptions}
							disabled={form.project.isDisabled}
							handler={handlerSelectProject}
						/>
					</Spin>
				</Col>
			</Row>
			{form.typeProject.isReport && (
				<Row className="taskForm-form__item">
					<Col md={5} xs={24} className={classNames("label", { disabled: !form.typeProject.isReport })}>
						Тип отчета {form.typeProject.isReport && <sup>*</sup>}
					</Col>
					<Col md={19} xs={24} className="taskForm-form__radio">
						<Spin spinning={isLoadingFeelsApp}>
							{reportType && (
								<RadioButtonsComponent
									options={transformReportType(role, reportType as IOptions<ReportType>[])}
									name={filingName("report")}
									disabled={form.report.isDisabled}
									handler={handlerTypeReport}
									notSetField={true}
								/>
							)}
						</Spin>
					</Col>
				</Row>
			)}
			<Row className="taskForm-form__item">
				<Col md={5} xs={24} className="label">
					Название этапа<sup>*</sup>
				</Col>
				<Col md={7} xs={24}>
					{form.project?.value?.value && <ReloadList handlerUpdateList={handlerUpdateSprint}/>}
					<SelectComponent
						name={filingName('sprint')}
						handler={handlerSprint}
						options={sprintsOptions ? sprintsOptions : []}
						placeholder="Выберите"
						disabled={!form.project.value || form.sprint.isDisabled}
						errorMessage={formErrors?.sprint?.value as string}
						renderOptionsFunction={(option) => (
							<Option
								key={option.value}
								value={option.value}
								data-code={option.label}
							>
								<span>
									<Tooltip placement='topLeft' title={`${option.label} - ${option.code}`}>
										{option.label} - <span style={{fontSize: 12}}>{option.code}</span>
									</Tooltip>
								</span>
							</Option>
						)}
					/>
				</Col>
			</Row>
			<Row className="taskForm-form__item">
				<Col md={5} xs={24} className="label">
					Срок исполнения<sup>*</sup>
				</Col>
				<Col md={7} xs={24}>
					<DeadlineComponent values={form} isDisabled={form.deadline.isDisabled} />
				</Col>
				<Col md={1} xs={0} />
				{values.isEdit && (
					<>
						<Col md={2} xs={24} className="label">
							Статус
						</Col>
						<Col md={9} xs={24}>
							<Spin spinning={isLoading}>
								<SelectComponent
									name={filingName('status')}
									options={statuses}
									placeholder="Выберите"
									disabled={form.status.isDisabled}
								/>
							</Spin>
						</Col>
					</>
				)}
			</Row>
			<WhetherTheUser role={['ROLE_ADMIN', 'ROLE_PRODUCER', 'ROLE_PROJECT_MANAGER', 'ROLE_ADMIN_IS']} isCan={true}>
				<Row className="taskForm-form__item">
					<Col md={5} xs={24} className="label">
						Формат задачи<sup>*</sup>
					</Col>
					<Tooltip title={form.archival.isDisabled ? 'При редактировании изменение признака задачи недоступно' : ''}>
						<Col md={7} xs={24}>
							<RadioButtonsComponent
								name={filingName('archival')}
								options={[
									{ label: 'Обычная', value: 'false' },
									{ label: 'Архивная', value: 'true' }
								]}
								disabled={form.archival.isDisabled}
							/>
						</Col>
					</Tooltip>

				</Row>
			</WhetherTheUser>

		</>
	)
}
export default React.memo(StepFirst)
