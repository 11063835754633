import {z} from "zod";
import {FilterRequest, Id_Name_Code_Hidden_Schema, SortSchema} from "shared/types/common";
import {ContestSchema} from "./contest";

export const SessionSchema = z.object({
    id: z.string(),
    sessionNumber: z.number(),
    year: z.number(),
    sessionType: Id_Name_Code_Hidden_Schema,
    contest: ContestSchema,
    code: z.string(),
    endDate: z.string().optional(),
    startDate: z.string().optional()
})

export type Session = z.infer<typeof SessionSchema>;

export const NewSessionSchema = z.object({
    id: z.string(),
    contest: ContestSchema,
    type: z.string()
})

export type NewSession = z.infer<typeof NewSessionSchema>;

export const FilterDataSchema = z.object({
    searchString: z.string().optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
    codeSearchString: z.string().optional(),
    hidden: z.boolean().nullable().optional(),
})

export type FilterData = z.infer<typeof FilterDataSchema>;
export type FilterSession = FilterRequest<FilterData>

export const SessionsResponseSchema = z.object({
    content: z.array(SessionSchema),
    page: z.number(),
    pageSize: z.number(),
    sort: SortSchema.optional(),
    totalCount: z.number(),
})
export type SessionsResponse = z.infer<typeof SessionsResponseSchema>;

export const SessionsRequestSchema = z.object({
    code: z.string().nullable(),
    sessionNumber: z.number().nullable(),
    sessionTypeId: z.string(),
    contestId: z.string(),
    endDate: z.string(),
    startDate: z.string(),
})
export type SessionsRequest = z.infer<typeof SessionsRequestSchema>;


export type SessionTypeRequest = {
    code: string | null
    name: string
    hidden: boolean
}

export type FilterDataSessionType = {
    searchString?: string
    hidden?: boolean
}

export type FilterSessionType = FilterRequest<FilterDataSessionType>
