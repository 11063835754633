import {axiosRequest} from 'shared/config/axios'
import {MassTasksContentRecords, RelatedUser} from '../types'
import {useMutation, useQuery} from 'react-query'
import {getRestPath} from 'shared/lib/get-rest-path'
import {T_ID_NAME} from 'models/common'
import {useMemo} from 'react'
import {transformArrayIdNameForValue} from 'utils/transformObjectFeels'

export const RestKeys = {
	updateTasksContentRecords: '/tasks/content/records/content-users',
	// eslint-disable-next-line
	getUsersContentRecords: '/tasks/content/records/${recordId}/content-users',
	getContentTaskRecordApproved: '/tasks/content/records/approved',
} as const

export function useUpdateRecords(onSuccess?: () => void) {
	return useMutation({
		mutationFn: async (data: MassTasksContentRecords) => {
			await axiosRequest({
				method: 'PUT',
				url: RestKeys.updateTasksContentRecords,
				data,
			})
		},
		onSuccess,
	})
}

export function useGetUsersContentRecords(params: { recordId?: string }) {
	return useQuery({
		queryKey: [RestKeys.getUsersContentRecords],
		queryFn: async () => {
			const { data } = await axiosRequest<RelatedUser[]>({
				method: 'GET',
				url: getRestPath(RestKeys.getUsersContentRecords, params),
				headers: {
					'Actioncode': 'GET_TASKS_CONTENT_RECORDS_RECORD_ID_CONTENT_USERS',
				},
				params,
			})
			return data
		},
		enabled: !!params?.recordId,
	})
}
export function useGetRecordsApproved(params: { projectId?: string }) {
	return useQuery({
		queryKey: [RestKeys.getContentTaskRecordApproved],
		queryFn: async () => {
			const { data } = await axiosRequest<T_ID_NAME[]>({
				method: 'GET',
				url: RestKeys.getContentTaskRecordApproved,
				params,
			})
			return data
		},
		enabled: !!params?.projectId,
	})
}

export function useGetRecordsApprovedOptions(params: { projectId?: string }) {
	const { data = [], ...rest } = useGetRecordsApproved(params)
	const options = useMemo(() => transformArrayIdNameForValue(data), [data])
	return { data: options, ...rest }
}
