import {put} from 'redux-saga/effects'
import {AxiosResponse} from 'axios'
import {LoginErrorAction, LoginStartAction, LoginSuccessAction} from 'store/auth/actions'
import AuthService from 'services/authService'
import CreateErrorMessage from 'utils/validation/createErrorMessage'
import {NotificationOpen} from 'store/notification/actions'
import {GetFileStartAction} from 'store/files/actions'
import {AuthActionTypes} from 'store/auth/action-types'
import ZodParser from 'shared/services/zod-parser';
import { UserTypes } from 'entities/user'

export function* loginWorker({ credentials, type }: ReturnType<typeof LoginStartAction>) {
	try {
		const { data: user }: AxiosResponse<UserTypes.CurrentUser> =
			type === AuthActionTypes.LOGIN_START
				? yield AuthService.login(credentials)
				: yield AuthService.loginByUser(credentials)
		ZodParser.parse(UserTypes.CurrentUserSchema, user, '/login-by-user');
		AuthService.setToStorageUser(user.id)
		AuthService.setToStorageTime()

		yield put(LoginSuccessAction(user))
		if (type !== AuthActionTypes.LOGIN_START)
			yield put(NotificationOpen('success', `Вы успешно авторизировались под пользователем ${credentials.username}`))
		if (user.personalData?.image)
			yield put(GetFileStartAction(user.personalData?.image.id, user.personalData?.image.type))

	} catch (error: any) {
		yield put(LoginErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось авторизоваться'))
	}
}
