
import { useEffect, useState } from 'react'
import env from 'shared/config/env'
import { FileType } from 'shared/types/file'
import { FORMATS_FOR_CONVERSION } from 'utils/varibales'

const pathImage = `${env.API_ENDPOINT}/files/`
function getImage(file?: FileType, image?: FileType) {
	if (!file && !image) return null
	let imageId = null
	if (FORMATS_FOR_CONVERSION.includes(file?.type || '')) return null
	if (file?.compressedId) return file.compressedId
	else if (file?.previewFile?.id) return file.previewFile.id
	else if (file?.type === 'IMAGE') return file.id
	else {
		if (image?.previewFile?.id) return image.previewFile.id
		else if (image?.compressedId) return image.compressedId
	}
	return imageId
}
const useContentImage = (file?: FileType, image?: FileType) => {
	const [loading, setLoading] = useState(true)
	const imageId = getImage(file, image)
	useEffect(() => {
		if (!imageId) setLoading(false)
	}, [imageId])

	return { pathImage, loading, imageId, setLoading }
}
export { useContentImage }
