import {useQuery, UseQueryResult} from "react-query";
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {useMemo} from "react";
import {getSubmissionForms, getSubmissionFormsById} from "../api/project-content-submission-form-controller";
import {ContentFormatTypeTypes} from 'entities/content-format-type'
import {Id_Name_Code} from "shared/types/common";

type onSuccessType = (data: ContentFormatTypeTypes.SubmissionForm[]) => void
export function useGetProjectContentSubmission(onSuccess?: onSuccessType): UseQueryResult<ContentFormatTypeTypes.SubmissionForm[]> {
    return useQuery({
        queryKey: [getSubmissionForms.key],
        queryFn: () => getSubmissionForms.fetch(),
        onSuccess,
    });
}
export function useGetProjectContentSubmissionOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetProjectContentSubmission(onSuccess);
    const options = useMemo(() => transformArrayIdNameForValue(data), [data]);
    return { data: options, ...rest };
}

export function useGetProjectContentSubmissionByProject(data: {projectId: string, formatTypeId?: string}, onSuccess?: onSuccessType): UseQueryResult<Id_Name_Code[]> {
    return useQuery({
        queryKey: [getSubmissionFormsById.key(data.projectId), data?.formatTypeId],
        queryFn: () => getSubmissionFormsById.fetch(data),
        onSuccess,
        enabled: !!data.projectId
    });
}
export function useGetProjectContentSubmissionByProjectOptions(data: {projectId: string, formatTypeId?: string}, onSuccess?: onSuccessType) {
    const { data: submission = [], ...rest } = useGetProjectContentSubmissionByProject(data, onSuccess);
    const options = useMemo(() => submission.map((el) => ({
        value: el.id,
        label: el.name,
        code: el.code,
        hidden: el.hidden
    })), [submission]);
    return { data: options, ...rest };
}
