export function getRestPath(template: string, params?: Record<string, unknown>): string {
	// Если объект параметров не передан, возвращаем исходный шаблон
	if (!params) return template;

	// Заменяем вхождения вида {key} на значение из params
	return template.replace(/{(\w+)}/g, (_, key) => {
		const value = params[key];
		// Если ключа нет в объекте, возвращаем {key} без изменений
		return value !== undefined ? String(value) : `{${key}}`;
	});
}
