import {axiosRequest} from "shared/config/axios";
import {downloadXLXS} from "utils/downloadXLXS";
import {
    UserActionsTypes,
    UserActionsTypesFilter,
    UserActionTypes,
    UserActionTypesRequest,
    UserActionTypesSchema
} from "../types";
import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";

/**
 * Получение всех типов действий пользователей
 */
type onSuccessType = (data: UserActionTypes[]) => void
export function useGetAll(onSuccess?: onSuccessType): UseQueryResult<UserActionTypes[]> {
    return useQuery({
        queryKey: ['/users/actions/types'],
        queryFn: async (): Promise<UserActionTypes[]> => {
            const { data } = await axiosRequest<UserActionTypes[]>({
                method: 'GET',
                url: '/users/actions/types',
                parser: UserActionTypesSchema.array(),
            })
            return data
        },
        onSuccess,
    })
}
export function useGetAllOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetAll(onSuccess)
    const options = useMemo(() => transformArrayIdNameForValue(data), [data])
    return { data: options, ...rest }
}
/**
 * Получение списка типов действий пользователей с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/users/actions/types/search',
    fetch: async (filter: UserActionsTypesFilter): Promise<UserActionsTypes> => {
        const newFilter = { ...filter, page: filter.page - 1 }
        const { data } = await axiosRequest<UserActionsTypes>({
            method: 'POST',
            url: '/users/actions/types/search',
            data: newFilter,
            parser: { list: true, schema: UserActionTypesSchema.array() },
        })
        return data
    },
}
/**
 * Создание типа действия пользователя
 */
export const create = async (data: UserActionTypesRequest) => {
    await axiosRequest({
        method: 'POST',
        url: '/users/actions/types',
        data,
    })
}

/**
 * Обновление типа действия пользователя
 */
export const update = async ({ id, data }: { id: string; data: UserActionTypesRequest }) => {
    await axiosRequest({
        method: 'PUT',
        url: `/users/actions/types/${id}`,
        data,
    })
}

/**
 * Удаление типа действия пользователя
 */
export const remove = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/users/actions/types/${id}`,
    })
}
/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/users/actions/types/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    })
    downloadXLXS(data, 'действия_пользователя')
}

