import {z} from 'zod'
import {FilterRequest, SearchResponse} from 'shared/types/common'
import {LegalFormSchema} from "./legal-form";
import {Nullable} from "models/common";
import {IOptions} from "models/feels";

export const OrganizationShortSchema = z.object({
    id: z.string(),
    name: z.string(),
    inn: z.string(),
    isContentCustomer: z.boolean(),
    region: z.object({
        id: z.string(),
        name: z.string()
    }),
    legalForm: LegalFormSchema
})

export type OrganizationShort = z.infer<typeof OrganizationShortSchema>

export const OrganizationWithContractorsShortSchema = OrganizationShortSchema.extend({
    ogrnOgrnip: z.string()
}).omit({ isContentCustomer: true });

export type OrganizationWithContractorsShort = z.infer<typeof OrganizationWithContractorsShortSchema>;

export const OrganizationSchema = OrganizationShortSchema.extend({
    fullName: z.string(),
    legalAddress: z.string(),
    mailingAddress: z.string(),
    kpp: z.string(),
    bik: z.string(),
    paymentAccount: z.string(),
    correspondentAccount: z.string(),
    phone: z.string(),
    ogrnOgrnip: z.string(),
    site: z.string(),
    okpo: z.string(),
    okato: z.string(),
    okved: z.string(),
    hidden: z.boolean()
})
export type Organization = z.infer<typeof OrganizationSchema>

export type Request = {
    bik: Nullable<string>,
    correspondentAccount: Nullable<string>,
    fullName: string,
    hidden?: boolean,
    inn: string,
    kpp: Nullable<string>,
    legalAddress: string,
    mailingAddress: Nullable<string>,
    name: string,
    ogrnOgrnip: string,
    okato: Nullable<string>,
    okpo: Nullable<string>,
    okved: Nullable<string>,
    paymentAccount: Nullable<string>,
    phone: Nullable<string>,
    site: Nullable<string>,
    isContentCustomer: boolean,
    regionId: string,
    legalFormId: string;

}
export type Form = Omit<Request, 'legalFormId' | 'regionId'> & {
    legalForm?: IOptions
    region?: IOptions,
};
export type FilterData = {
    searchString?: string
    hidden?: boolean
    legalFormId?: string
    regionId?: string
    inn?: string
}

export type Search = SearchResponse<Organization>
export type Filter = FilterRequest<FilterData>
