import {TSort} from "models/common";
import { TypesTask} from "./task-types";
import {TasksRecordStatus} from "./record-status";
import {ReportType} from "./report-type";
import {z} from "zod";
import {PostingRecords} from "./records/posting";
import {EstimateRecords} from "./records/estimate";
import {CopyrightRecords} from "./records/copyright";
import {ReachRecords} from "./records/reach";

export type TStatus = 'disabled' | 'hidden' | 'view';
enum EnumActions {
    edit= 'edit',
    delete= 'delete',
    send= 'send',
    changeStatus= 'changeStatus',
    save= 'save',
    cancel= 'cancel',
}
export type TActions = Record<EnumActions, TStatus>

export type Content = {
    archival: boolean
    typeTask: TypesTask.CONTENT
    recordStatus: TasksRecordStatus
    reportType?: never
    linkedFilesCount?: number
    files?: never
}
export type Report = {
    archival: boolean
    typeTask: TypesTask.REPORT
    recordStatus: TasksRecordStatus
    reportType: ReportType
    linkedFilesCount?: number
    files?: {
        linkedScreenshotsCount: number,
        linkedCounterScreenshotsCount: number
        reachRecordsScreenshotRequired?: boolean,
        reachRecordsCounterScreenshotRequired?: boolean,

    }
}

export const RecordsCountSchema = z.object({
    draftRecordsCount: z.number(),
    readyForVerificationRecordsCount: z.number(),
    verificationRequiredRecordsCount: z.number(),
    rejectedRecordsCount: z.number(),
    correctionRecordsCount: z.number(),
    approvedRecordsCount: z.number(),
    approvedOutdatedRecordsCount: z.number(),
    rejectedAfterVoteResetRecordsCount: z.number(),
})
export type RecordsCount = z.infer<typeof RecordsCountSchema>

export type TaskRecordsResponse<T> = {
    page: number,
    pageSize: number,
    sort?: TSort,
    totalCount?: number,
    taskApprovedReachAmountSum?: number
    content: T
}

export type ReportRecords = PostingRecords[] | EstimateRecords[] | CopyrightRecords[] | ReachRecords[]
export type ReportRecord = PostingRecords | EstimateRecords | CopyrightRecords | ReachRecords

export * from './records/content'
export * from './records/reach'
export * from './records/copyright'
export * from './records/posting'
export * from './records/estimate'

