import {axiosRequest} from 'shared/config/axios'
import {
	Filter,
	InternetResource,
	InternetResourceRequest,
	InternetResources,
	InternetResourceSchema,
	InternetResourceShort,
	InternetResourceShortSchema
} from '../types'
import {downloadXLXS} from "utils/downloadXLXS";

/**
 * Получение всех Интернет-ресурсов
 */
export const getInternetResource = {
	key: '/internet-resources',
	fetch: async () => {
		const { data } = await axiosRequest<InternetResourceShort[]>({
			method: 'GET',
			url: '/internet-resources',
			parser: InternetResourceShortSchema.array(),
		})
		return data
	},
}
/**
 * @static method getInternetResourcesPostingTaskResources()
 * @description Получение всех интернет ресурсов проекта для posting, доступных для текущей роли
 */
export const getInternetResourcesPostingTask = {
	key: `/internet-resources/project-id`,
	fetch: async (projectId?: string) => {
		const { data } = await axiosRequest<InternetResource[]>({
			method: 'GET',
			url: `/internet-resources/project-id/${projectId}`,
			parser: InternetResourceSchema.array(),
		})
		return data
	},
}

/**
 * Получение списка типов Интернет-ресурсов с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/internet-resources/search',
	fetch: async (filter: Filter): Promise<InternetResources> => {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<InternetResources>({
			method: 'POST',
			url: '/internet-resources/search',
			data: newFilter,
			parser: { list: true, schema: InternetResourceSchema.array() },
		})
		return data
	},
}
/**
 * Создание Интернет-ресурсов
 */
export const create = async (data: InternetResourceRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/internet-resources',
		data,
	})
}

/**
 * Обновление Интернет-ресурсов
 */
export const update = async ({ id, data }: { id: string; data: InternetResourceRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/internet-resources/${id}`,
		data,
	})
}

/**
 * Удаление Интернет-ресурсов
 */
export const remove = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/internet-resources/${id}`,
	})
}

/**
 * Массовые действия Интернет-ресурсов
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/internet-resources/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/internet-resources/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
	})
	downloadXLXS(data, 'интернет-ресурсы')
}
/**
 * Получение файла шаблона для выполнения импорта данных Интернет-ресурсов
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/internet-resources/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
	downloadXLXS(data, 'интернет-ресурсы')
}
/**
 * Выполнение импорта данных Интернет-ресурсов из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/internet-resources/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	})
}

