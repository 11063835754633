import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {InternetResource, InternetResourceShort} from "../types";
import {getInternetResource, getInternetResourcesPostingTask} from "../api";
import {IOptions} from "models/feels";

type onSuccessType = (data: InternetResourceShort[]) => void
export function useGetInternetResource(onSuccess?: onSuccessType): UseQueryResult<InternetResourceShort[]> {
    return useQuery({
        queryKey: [getInternetResource.key],
        queryFn: () => getInternetResource.fetch(),
        onSuccess,
    });
}
export function useGetInternetResourceOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetInternetResource(onSuccess);
    const options = useMemo(() => data.map((el) => ({
        label: el.name,
        value: el.id,
        hidden: el.hidden,
        code: el.domainLink
    } as IOptions)), [data]);
    return { data: options, ...rest };
}


export function useGetInternetResourcePostingTask(id?: string, onSuccess?: (data: InternetResource[]) => void): UseQueryResult<InternetResource[]> {
    return useQuery({
        queryKey: [getInternetResourcesPostingTask.key, id],
        queryFn: () => getInternetResourcesPostingTask.fetch(id),
        enabled: !!id,
        onSuccess,
    });
}
export function useGetInternetResourcePostingTaskOptions(id?: string, onSuccess?: (data: InternetResource[]) => void) {
    const { data = [], ...rest } = useGetInternetResourcePostingTask(id, onSuccess);
    const options = useMemo(() => data?.map((el) => ({
        label: el.name,
        value: el.id,
        hidden: el.hidden,
        code: el.domainLink
    } as IOptions)), [data]);

    const optionsHidden = useMemo(() => data
        ?.filter((el) => !el.hidden)
        ?.map((el) => ({
        label: el.name,
        value: el.id,
        hidden: el.hidden,
        code: el.domainLink
    } as IOptions)), [data]);

    return { data: {options, optionsHidden}, ...rest };
}
