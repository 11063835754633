import {axiosRequest} from 'shared/config/axios'
import {downloadXLXS} from 'utils/downloadXLXS'
import {Filter, LegalForm, LegalFormRequest, LegalFormSchema, Search} from "../types/legal-form";

/**
 * Получение всех правовых форм организаций
 */
export const getOrganizationLegalForms = {
    key: '/organization-legal-forms',
    fetch: async (): Promise<LegalForm[]> => {
        const { data } = await axiosRequest<LegalForm[]>({
            method: 'GET',
            url: '/organization-legal-forms',
            parser: LegalFormSchema.array(),
        })
        return data
    },
}
/**
 * Получение списка правовых форм с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/organization-legal-forms/search',
    fetch: async (filter: Filter): Promise<Search> => {
        const newFilter = { ...filter, page: filter.page - 1 }
        const { data } = await axiosRequest<Search>({
            method: 'POST',
            url: '/organization-legal-forms/search',
            data: newFilter,
            parser: { list: true, schema: LegalFormSchema.array() },
        })
        return data
    },
}
/**
 * Создание новой правовой формы организации
 */
export const create = async (data: LegalFormRequest) => {
    await axiosRequest({
        method: 'POST',
        url: '/organization-legal-forms',
        data,
    })
}

/**
 * Обновление правовой формы организации
 */
export const update = async ({ id, data }: { id: string; data: LegalFormRequest }) => {
    await axiosRequest({
        method: 'PUT',
        url: `/organization-legal-forms/${id}`,
        data,
    })
}

/**
 * Удаление правовой формы организации
 */
export const remove = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/organization-legal-forms/${id}`,
    })
}

/**
 * Массовые действия над правовыми формами организаций
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/organization-legal-forms/bulk-actions/${bulkAction}`,
        data: rowIds,
    })
    return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/organization-legal-forms/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    })
    downloadXLXS(data, 'правовые_формы_организаций')
}
/**
 * Получение файла шаблона для выполнения импорта данных о правовых формах организаций
 */
export const downloadTemplate = async () => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/organization-legal-forms/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    })
    downloadXLXS(data, 'шаблон_правовые_формы_организаций')
}
/**
 * Выполнение импорта данных о правовых формах организаций из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
    await axiosRequest({
        method: 'POST',
        url: '/organization-legal-forms/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    })
}
