import {T_ID_NAME} from '../common'
import {TInternetResource} from './internetResource'
import {TPerson} from '../user'

export type TFormatSubmission = {
	id: string
	code: string
	name: string
	hidden: boolean
	requirements: string
}

export enum TStatusesFeels {
	statuses = 'statuses',
	formatTypes = 'formatTypes',
	thematics = 'thematics',
	formatKpi = 'formatKpi',
	reportType = 'reportType',

	contractors = 'contractors',
	contractorsId = 'contractorsId',
	coordinators = 'coordinators',
	analysts = 'analysts',
	financiers = 'financiers',
	producers = 'producers',
	lawyers = 'lawyers',
	contentEditors = 'contentEditors',
	projectManagers = 'projectManagers',

	roles = 'roles',
	formatSubmission = 'formatSubmission',
	ranks = 'ranks',
	categories = 'categories',
}
export type TFeels = {
	statuses?: T_ID_NAME[]
	directions?: T_ID_NAME[]
	formatTypes?: T_ID_NAME[]
	thematics?: T_ID_NAME[]
	formatSubmission?: TFormatSubmission
	formatKpi?: T_ID_NAME[]
	internetResources?: TInternetResource[]
	internetResourcesPostingTask?: TInternetResource[] | null
	contractors?: TPerson[]
	contractorsId?: TPerson[]
	coordinators?: TPerson[]
	analysts?: TPerson[]
	financiers?: TPerson[]
	lawyers?: TPerson[]
	producers?: TPerson[]
	roles?: T_ID_NAME[]
}
