import React, {FC} from 'react'
import styles from './styles.module.less'
import {ProjectTypes} from 'entities/project'

type Props = {
	contentTimelines: ProjectTypes.Project['contentTimelines']
}

export const ModalTimeLines: FC<Props> = ({ contentTimelines }) => {
	return (
		<div className={styles.root}>
			{contentTimelines.map((el) => (
				<div className={styles.item} key={el.contentRecord.id}>
					<h3 className={styles.h3}>{el.contentRecord?.name}</h3>
					{el.timelines?.length
						? el.timelines.map((el, index) => (
							<div className={styles.block} key={index.toString()}>
								<span className={styles.text}>{el.nationalTags.map(it => it.name).join(', ')}</span>
								<span className={styles.time_line}>{el.timeline}</span>
							</div>
						))
						: null}
				</div>
			))}
		</div>
	)
}
