import {axiosRequest} from 'shared/config/axios'
import {downloadXLXS} from 'utils/downloadXLXS'
import {
    Filter,
    Organization,
    OrganizationSchema,
    OrganizationShort,
    OrganizationShortSchema,
    OrganizationWithContractorsShort,
    OrganizationWithContractorsShortSchema,
    Request,
    Search
} from "../types/organization";

/**
 * Получение всех организаций
 */
export const getOrganizations = {
    key: '/organizations',
    fetch: async (): Promise<OrganizationShort[]> => {
        const { data } = await axiosRequest<OrganizationShort[]>({
            method: 'GET',
            url: '/organizations',
            parser: OrganizationShortSchema.array(),
        })
        return data
    },
}
/**
 *
 * Получение списка организаций подрядчиков в кратком формате
 */
export const getOrganizationsWithContractorsShort = {
    key: '/organizations/with-contractors',
    fetch: async (): Promise<OrganizationWithContractorsShort[]> => {
        const { data } = await axiosRequest<OrganizationWithContractorsShort[]>({
            method: 'GET',
            url: '/organizations/with-contractors',
            parser: OrganizationWithContractorsShortSchema.array(),
        })
        return data
    },
}
/**
 * Получение организации по id
 */

export const getOrganizationById = {
    key: '/organizations',
    fetch: async (id: string) => {
        const {data: organization} = await axiosRequest<Organization>({
            url: `/organizations/${id}`,
            method: 'GET',
            showError: true,
            parser: OrganizationSchema.array(),
        })
        return organization
    },
}
/**
 * Получение списка с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/organizations/search',
    fetch: async (filter: Filter): Promise<Search> => {
        const newFilter = { ...filter, page: filter.page - 1 }
        const { data } = await axiosRequest<Search>({
            method: 'POST',
            url: '/organizations/search',
            data: newFilter,
            parser: { list: true, schema: OrganizationSchema.array() },
        })
        return data
    },
}
/**
 * Создание новой организации
 */
export const create = async (data: Request) => {
    await axiosRequest({
        method: 'POST',
        url: '/organizations',
        data,
    })
}

/**
 * Обновление организации
 */
export const update = async ({ id, data }: { id: string; data: Request }) => {
    await axiosRequest({
        method: 'PUT',
        url: `/organizations/${id}`,
        data,
    })
}

/**
 * Удаление организации
 */
export const remove = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/organizations/${id}`,
    })
}

/**
 * Массовые действия над организаций
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/organizations/bulk-actions/${bulkAction}`,
        data: rowIds,
    })
    return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/organizations/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    })
    downloadXLXS(data, 'организации')
}
/**
 * Получение файла шаблона для выполнения импорта данных об организациях
 */
export const downloadTemplate = async () => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/organizations/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    })
    downloadXLXS(data, 'шаблон_организации')
}
/**
 * Выполнение импорта данных об организациях из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
    await axiosRequest({
        method: 'POST',
        url: '/organizations/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    })
}
