import React, { MouseEvent, useRef } from 'react';
import { DefaultUi, Player, usePlayerContext, Video } from '@vime/react';
import styles from './styles.module.less';
import { FileType } from 'shared/types/file';
import env from 'shared/config/env';

type Props = {
	file: FileType;
};
const path = `${env.API_ENDPOINT}/files/`;
const VimePlayer = ({file}: Props) => {
	const player = useRef<HTMLVmPlayerElement>(null);
	const [currentTime, setCurrentTime] = usePlayerContext(player, 'currentTime', 0);

	const onTimeClick = (timeString: string) => (_event: MouseEvent<HTMLSpanElement>) => {
		const time = timeString.split(':')
		const convertTime = +time[2] + +time[1] * 60 + +time[0] * 3600
		console.log('currentTime', currentTime);
		setCurrentTime(convertTime)
	}


	return (
		<>
			<Player
				theme="dark"
				ref={player}
				onVmError={(event) => {
					console.log('VmError', event.detail);
				}}
				style={{width: '100%'}}
				currentPoster={file.previewFile ? path + file.previewFile?.id : undefined}
			>
				<Video crossOrigin="use-credentials" preload="metadata">
					<source data-src={`${path}${file.id}`} />
				</Video>
				<DefaultUi />
			</Player>
			{file.type === 'VIDEO' && file.timeCodes.length > 0 && (
				<ul className={styles.timeCodesList}>
					{file.timeCodes.map((timeCode, index) => {
						return (
							<li className={styles.timeCode} key={index}>
									<span className={styles.time} onClick={onTimeClick(timeCode.timeline)}>
										{timeCode.timeline} - {timeCode.timelineLabel}
									</span>
							</li>
						)
					})}
				</ul>
			)}
		</>
	)
};

export default VimePlayer;