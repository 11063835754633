import React, {FC} from 'react'
import styles from './styles.module.less'
import {Form, Formik, useFormikContext} from 'formik'
import {useQuery} from 'react-query'
//models
import {TaskTypes} from 'entities/task'
import {PopoverRefType} from '../modal-scheduler/tasks'
import {IOptions} from 'models/feels'
//entities
import {ProjectLib} from 'entities/project'
import {SessionApi} from 'entities/session'
import {ContestApi} from 'entities/contest'
//components
import {SelectComponent} from 'components/formic-control'
//others
import {Spin} from 'antd'
import {Query} from 'processes/query'
import TasksService from 'services/tasksService'

type TInitialState = {
	contest: IOptions | null
	year: IOptions | null
	session: IOptions | null
	project: IOptions | null
}

const initialState: TInitialState = {
	contest: null,
	year: null,
	session: null,
	project: null,
}

type Props = {
	handlerChangeRef: (value: PopoverRefType) => void
}
const PopoverContentComponent: FC<Props> = ({ handlerChangeRef }) => {
	const { values, setFieldValue } = useFormikContext<TInitialState>()

	const { data: contests = [], isLoading: isLoadingContest } = useQuery({
		queryKey: [ContestApi.ContestController.getContestWithProjectsOptionsKey],
		queryFn: ContestApi.ContestController.getContestWithProjectsOptions,
	})
	const { data: sessionYears = [], isLoading: isLoadingYears } = useQuery({
		queryKey: [SessionApi.SessionController.getSessionYearsProjectOptionsKey, values.contest?.value],
		queryFn: () => SessionApi.SessionController.getSessionYearsProjectOptions(values.contest?.value || ''),
		enabled: !!values.contest?.value,
	})
	const { data: sessions = [], isLoading: isLoadingSession } = useQuery({
		queryKey: [
			SessionApi.SessionController.getSessionsProjectOptionsKey,
			{ year: values.year?.value, contestId: values.contest?.value },
		],
		queryFn: () =>
			SessionApi.SessionController.getSessionsProjectOptions({
				year: values.year?.value,
				contestId: values.contest?.value,
			}),
		enabled: !!values.year?.value,
	})

	const {data: projects, isLoading: isLoadingProject} = ProjectLib.useGetProjectAllOptions({
		statusCodes: ['ACTUAL'],
		...(values.session?.value && { sessionIds: [values.session?.value] }),
	})

	const reRequestTasks = () => {
		Query.invalidate([
			TasksService.getAllTasksKey,
			TaskTypes.TypesTask.REPORT, TaskTypes.ReportType.CONTENT_REACH_REPORT,
		])
	}

	const handlerContests = (val: IOptions) => {
		if (!val.value || values.contest?.value !== val.value) setFieldValue('year', null)
		if (!val.value || values.contest?.value !== val.value) setFieldValue('session', null)
		if (!val.value || values.contest?.value !== val.value) setFieldValue('project', null)

		handlerChangeRef({ contestIds: val.value ? [val.value] : undefined })
		reRequestTasks()
	}

	const handlerYear = (val: IOptions) => {
		if (!val.value || values.year?.value !== val.value) {
			setFieldValue('session', null)
			setFieldValue('project', null)
			handlerChangeRef({sessionIds: undefined, projectIds: undefined})
			reRequestTasks()
		}
	}

	const handlerSession = (val: IOptions) => {
		if (!val.value || values.session?.value !== val.value) setFieldValue('project', null)

		handlerChangeRef({ sessionIds: val.value ? [val.value] : undefined })
		reRequestTasks()
	}
	const handlerProject = (val: IOptions[]) => {
		const ids = val?.length ? val?.map((it) => it.value) : undefined

		handlerChangeRef({ projectIds: ids })
		reRequestTasks()
	}
	return (
		<>
			<Spin spinning={isLoadingContest || !contests}>
				<div className={styles.popover_item}>
					<span>Конкурс</span>
					<SelectComponent
						options={contests}
						name="contest"
						handler={handlerContests}
						placeholder={'Искать среди всех'}
						showError={false}
					/>
				</div>
			</Spin>
			<Spin spinning={isLoadingYears}>
				<div className={styles.popover_item}>
					<span>Год</span>
					<SelectComponent
						options={values['contest']?.value ? sessionYears : null}
						name="year"
						placeholder={'Искать среди всех'}
						handler={handlerYear}
						disabled={!values['contest']?.value}
						showError={false}
					/>
				</div>
			</Spin>
			<Spin spinning={isLoadingSession}>
				<div className={styles.popover_item}>
					<span>Сессия</span>
					<SelectComponent
						options={values['year']?.value ? sessions : null}
						name="session"
						placeholder={'Искать среди всех'}
						handler={handlerSession}
						disabled={!values['year']?.value}
						showError={false}
					/>
				</div>
			</Spin>
			<Spin spinning={isLoadingProject}>
				<div className={styles.popover_item}>
					<span>Проекты</span>
					<SelectComponent
						options={projects}
						name="project"
						placeholder={'Искать среди всех'}
						showError={false}
						multi={true}
						handler={handlerProject}
						maxTagCount={'responsive'}
					/>
				</div>
			</Spin>
		</>
	)
}

const PopoverContent: FC<Props> = ({ handlerChangeRef }) => {
	return (
		<Formik enableReinitialize initialValues={initialState} onSubmit={() => {}}>
			{(_formik) => {
				return (
					<Form className={styles.popover_content}>
						<PopoverContentComponent handlerChangeRef={handlerChangeRef} />
					</Form>
				)
			}}
		</Formik>
	)
}

export default PopoverContent
