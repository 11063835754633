import { put } from 'redux-saga/effects'
import { NotificationOpen } from '../../store/notification/actions'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {
	AddProjectDocumentsErrorAction,
	AddProjectDocumentsStartAction,
	AddProjectDocumentsSuccessAction, ClearEditingKey,
	GetProjectDocumentsWithFilterStartAction,
} from '../../store/project-documents/actions'
import ProjectDocumentsService from '../../services/projectDocument'

export function* addDocuments({ projectId }: ReturnType<typeof AddProjectDocumentsStartAction>) {
	try {
		yield ProjectDocumentsService.addFile(projectId)
		yield put(AddProjectDocumentsSuccessAction())
		yield put(GetProjectDocumentsWithFilterStartAction())
		yield put(ClearEditingKey())
		yield put(NotificationOpen('success', 'Документ успешно добавлен'))
	} catch (error: any) {
		yield put(AddProjectDocumentsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось добавить'))
	}
}
