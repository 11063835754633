import React, {FC} from 'react';
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import styles from "../styles.module.less";
import Checkbox from "antd/es/checkbox";
import {IOptions} from "models/feels";

type Props = {
    name: string
    options: IOptions[] | string[]
}
const DropdownComponent: FC<Props> = ({name, options}) => {
    const {values, setFieldValue} = useFormikContext<TInitValues>()
    const handlerCheckbox = (e: any) => {
        if (e.target.checked) {
            setFieldValue(name, options)
        } else {
            setFieldValue(name, [])
        }
    }

    if (!options?.length) return null

    return (
        <div className={styles.dropdown}>
            <Checkbox
                onChange={handlerCheckbox}
                checked={(values[name as keyof TInitValues] as Props['options'])?.length === options?.length}
            >
                Выбрать все
            </Checkbox>
            <hr/>
        </div>
    )
};

export default DropdownComponent;
