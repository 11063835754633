import React, {Suspense, useMemo} from 'react';
import { Badge, Button, Skeleton } from 'antd';
import styles from './styles.module.less';
import { Content, Reach, Posting, Estimate, ProjectDocuments } from '../modal-content';
import ErrorBoundary from 'components/error-boundary';
import { EntityType } from '../../types';
import { FileType } from 'shared/types/file';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth/selectors';
import { ModalEvent } from 'shared/ui/modal';

type Props = {
	customButton?: React.ReactNode;
	isEdit?: boolean;
	isCanEditTimeCode?: boolean;
	recordId: string;
	linkedFilesCount: number;
	entityType: EntityType;
	typeScreenshots?: 'screenshots' | 'counterScreenshots'
	files?: FileType[] //only entityType === 'PROJECT_DOCUMENT'
};
const text = 'Просмотреть';
const textEdit = 'Изменить';

const ViewFilesButton = ({
	isEdit = false,
	linkedFilesCount,
	recordId,
	entityType,
	typeScreenshots,
	files,
	customButton,
}: Props) => {
	const user = useSelector(selectUser);

	const styleModal = useMemo(()=>({
		padding: '14px',
		...(!isEdit && {minHeight: '80vh'})
	}),[isEdit])

	const onOpen = (e: any) => {
		e.stopPropagation();

		const modalWidth = isEdit ? 600 : window.innerWidth - 350;

		ModalEvent.open({
			title: 'Просмотр загруженных файлов записи',
			key: `ViewFilesButton-${recordId}-${entityType}-${typeScreenshots}-isEdit:${isEdit}`,
			component: (
				<ErrorBoundary>
					<Suspense fallback={(
						<div style={{display: 'flex'}}>
							{!isEdit && <Skeleton.Image />}
							<Skeleton/>
						</div>
					)}>
						<div style={styleModal}>
							{entityType === 'CONTENT_RECORD' && <Content isEdit={isEdit} recordId={recordId}/>}
							{entityType === 'REACH_RECORD' && <Reach isEdit={isEdit} recordId={recordId} typeScreenshots={typeScreenshots}/>}
							{entityType === 'POSTING_RECORD' && <Posting isEdit={isEdit} recordId={recordId}/>}
							{entityType === 'ESTIMATE_RECORD' && <Estimate isEdit={isEdit} recordId={recordId}/>}
							{entityType === 'PROJECT_DOCUMENT' && <ProjectDocuments isEdit={isEdit} files={files || []} recordId={recordId}/>}
						</div>
					</Suspense>
				</ErrorBoundary>
			),
			destroyOnClose: !isEdit,
			width: isEdit ? 600 : modalWidth,
			closePrevious: false
		})
	}
	const disabled = (() => {
		if (isEdit) return false;
		else if (entityType === 'CONTENT_RECORD' && !user?.contentViewAccess) return true
		return linkedFilesCount === 0
	})();

	return (
		<>
			{customButton && (
				<Button type={'text'} disabled={disabled} onClick={onOpen} className={styles.buttonCustom}>
					{customButton}
				</Button>
			)}
			{!customButton && (
				<Button disabled={disabled} type={'default'} className={styles.button} onClick={onOpen}>
					{isEdit ? textEdit : text} связанные файлы <Badge showZero={true} count={linkedFilesCount} className={styles.count}/>
				</Button>
			)}
		</>
	)
};
export default ViewFilesButton;
