import React, { useRef } from 'react';
import { FileType } from 'shared/types/file';
import { Audio, DefaultUi, Player } from '@vime/react';
import env from 'shared/config/env';

type Props = {
	file: FileType;
};
const path = `${env.API_ENDPOINT}/files/`
const AudioPlayer = ({file}: Props) => {
	const player = useRef<HTMLVmPlayerElement>(null);
	return (
		<Player
			theme="dark"
			ref={player}
			onVmError={(event) => {
				console.log('VmError', event.detail);
			}}
			style={{width: '100%'}}
			currentPoster={file.previewFile ? path + file.previewFile?.id : undefined}
		>
			<Audio crossOrigin="use-credentials">
				<source data-src={`${path}${file.id}`} />
			</Audio>
			<DefaultUi />
		</Player>
	)
};

export default AudioPlayer;