import React, {FC} from 'react';
import description from "assets/svg/description.svg";// eslint-disable-line

//todo (files) добавить информацию о незавершённых загрузках
const InfoMenuUploader: FC<{ type?: 'mobile' }> = ({type}) => {

    return (
        <>
            {/*{(loading || fileIsUpload) && (*/}
            {/*    <Tooltip title={'Загрузка файла'}>*/}
            {/*        <div className={`infoMenu__loadBtn ${type}`} onClick={_e => {*/}
            {/*            put(OpenModalUploadAction())*/}
            {/*        }}>*/}
            {/*            <img src={description} alt="" className='infoMenu__loadBtn-img'/>*/}
            {/*            <div className='infoMenu__loadBtn-progress'>*/}
            {/*                <Progress size="small" status="normal" percent={uploaderState.progress}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Tooltip>*/}
            {/*)}*/}
            {/*{unfinishedFiles && !!unfinishedFiles.length && (*/}
            {/*    <div className={`infoMenu__unfinished ${type}`} onClick={_e => {*/}
            {/*        put(ModalOpenAction('upload'))*/}
            {/*    }}>*/}
            {/*        <Badge count={unfinishedFiles ? unfinishedFiles.length : undefined}>*/}
            {/*            <CloudDownloadOutlined/>*/}
            {/*        </Badge>*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    )
};
export default InfoMenuUploader;
