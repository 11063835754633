import {axiosRequest} from 'shared/config/axios';
import {downloadXLXS} from "utils/downloadXLXS";
import {Id_Name_Code_Hidden, Id_Name_Code_Hidden_Schema} from "shared/types/common";
import {FilterSessionType, SessionTypeRequest} from "../types";

/**
 * @description Получение всех типов сессий
 */
export const getSessionsTypes = {
    key: '/sessions/types',
    fetch: async () => {
        const {data} = await axiosRequest<Id_Name_Code_Hidden[]>({
            url: '/sessions/types',
            method: 'GET',
            showError: true,
            parser: Id_Name_Code_Hidden_Schema.array()
        })
        return data
    },
}

/**
 * Получение списка типа сессии с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
    key: '/sessions/types/search',
    fetch: async (filter: FilterSessionType): Promise<Id_Name_Code_Hidden> => {
        const newFilter = { ...filter, page: filter.page - 1 }
        const { data } = await axiosRequest<Id_Name_Code_Hidden>({
            method: 'POST',
            url: '/sessions/types/search',
            data: newFilter,
            parser: { list: true, schema: Id_Name_Code_Hidden_Schema.array() },
        })
        return data
    },
}
/**
 * Создание нового типа сессии
 */
export const create = async (data: SessionTypeRequest) => {
    await axiosRequest({
        method: 'POST',
        url: '/sessions/types',
        data,
    })
}

/**
 * Обновление типа сессии
 */
export const update = async ({ id, data }: { id: string; data: SessionTypeRequest }) => {
    await axiosRequest({
        method: 'PUT',
        url: `/sessions/types/${id}`,
        data,
    })
}

/**
 * Удаление типа сессии
 */
export const remove = async (id: string) => {
    await axiosRequest({
        method: 'DELETE',
        url: `/sessions/types/${id}`,
    })
}

/**
 * Массовые действия
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
    await axiosRequest({
        method: 'POST',
        url: `/sessions/types/bulk-actions/${bulkAction}`,
        data: rowIds,
    })
    return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
    const { data }: any = await axiosRequest({
        method: 'POST',
        url: `/sessions/types/export`,
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        data: value,
    })
    downloadXLXS(data, 'типы_сессий')
}
/**
 * Получение файла шаблона для выполнения импорта данных о типах сессии
 */
export const downloadTemplate = async () => {
    const { data }: any = await axiosRequest({
        method: 'GET',
        url: '/sessions/types/import/template',
        responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    })
    downloadXLXS(data, 'шаблон_формы_сдачи_контента')
}
/**
 * Выполнение импорта данных о типах сессий из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
    await axiosRequest({
        method: 'POST',
        url: '/sessions/types/import',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data,
    })
}
