import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Select, Spin} from "antd";
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {OrganizationLib} from 'entities/organization'

const { Option} = Select;

function useGetsData() {
    const organizationsWithContractors =  OrganizationLib.useGetOrganizationsWithContractorsOptions()
    const organizationsLegalForm = OrganizationLib.useGetOrganizationLegalFormsOptions()

    return {
        loading: organizationsWithContractors.isLoading || organizationsLegalForm.isLoading,
        options: {
            organizationIds: organizationsWithContractors.data || [],
            legalFormIds: organizationsLegalForm.data || []
        }
    }
}
type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.Organizations, 'isToggled'>
export const OrganizationItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();

    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const organizations = useSelector(ExtendedSearchModel.selectors.organizations)

    const onSelectChange = (field: TKey) => (value: string[]) => {
        put(ExtendedSearchModel.actions.setOrganizations(typeContent, {[field]: value}))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper">
                <div className="item">
                    <div className="label">Наименование</div>
                    <div className="form-group">
                        <Select
                            allowClear={true}
                            showSearch={true}
                            onChange={onSelectChange("organizationIds")}
                            maxTagCount={"responsive"}
                            mode={"multiple"}
                            value={organizations[typeContent]["organizationIds"]}
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder={'Искать среди всех'}
                            filterOption={(input, option) => {
                                if (option === undefined || option === null) return false;
                                const numberInput = +input
                                if (isNaN(numberInput)) {
                                    return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } else return option['data-code'].indexOf(input) >= 0;
                            }}
                        >
                            {options.organizationIds.map((el) => (
                                <Option
                                    key={el.value}
                                    value={el.value}
                                    data-code={el.code}
                                    data-name={el.label}
                                >
                                    {el.label}<br/><small>ИНН - {el.code}</small>
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="item">
                    <div className="label">Форма организации</div>
                    <div className="form-group">
                        <Select
                            allowClear={true}
                            showSearch={true}
                            onChange={onSelectChange("legalFormIds")}
                            maxTagCount={"responsive"}
                            mode={"multiple"}
                            getPopupContainer={trigger => trigger.parentNode}
                            value={organizations[typeContent]["legalFormIds"]}
                            placeholder={'Искать среди всех'}
                            filterOption={(input, option) => {
                                if (option === undefined || option === null) return false;
                                return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                        >
                            {options.legalFormIds.map((el) => (
                                <Option
                                    key={el.value}
                                    value={el.value}
                                    data-name={el.label}
                                >
                                    {el.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        </Spin>

    )
}
