import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getOrganizationLegalForms} from "../api/legal-form-controller";
import {LegalForm} from "../types/legal-form";

type onSuccessType = (data: LegalForm[]) => void
export function useGetOrganizationLegalForms(onSuccess?: onSuccessType): UseQueryResult<LegalForm[]> {
    return useQuery({
        queryKey: [getOrganizationLegalForms.key],
        queryFn: () => getOrganizationLegalForms.fetch(),
        onSuccess,
    });
}
export function useGetOrganizationLegalFormsOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetOrganizationLegalForms(onSuccess);
    const options = useMemo(() => data.map((el) => ({
        value: el.id,
        label: el.fullName,
        code: el.code,
        hidden: el.hidden
    })), [data]);
    return { data: options, ...rest };
}
