import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import './index.less'
//store
import {selectProject, selectProjectsStatus} from "store/projects/selectors";
import {selectTaskNPOptions, selectTaskStatus} from "store/tasks/selectors/selectors-task";
import {TTapeTask} from "store/tasks/reducer/tasks";
import {ClearTasksNPAction, DuplicateTasksStartAction, GetAllTasksNPStartAction} from "store/tasks/actions/tasks";
import {selectRole} from "store/auth/selectors";
import {GetProjectSuccessAction} from "store/projects/actions";
import {GetSprintsStartAction} from "store/sprints/actions";
import {selectSprintsListOptions} from "store/sprints/selectors";
//hooks
import useHandlerModal from "hooks/handlModal";
import useTasksFields from "hooks/tasksField";
//models
import {IOptions} from "models/feels";
import {ReportType, TypesTask} from "models/task/types";
import {TTasksTypesFields} from "models/task";
//ant adn libs
import {PlusCircleOutlined} from "@ant-design/icons";
import {Button, Col, DatePicker, Modal, Row, Select, Spin, Tooltip} from "antd";
import 'moment/locale/ru';
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";
import {ProjectLib} from 'entities/project'

const { Option } = Select;
export type TModalDuplicateData = {
    page?: TTapeTask,
    projectId?: string
    projectCode?: string
    projectStatus?: string
}
type Props = {
    status: boolean
    data?: TModalDuplicateData
}

const ModalDuplicate:FC<Props> = ({status, data}) => {
    const put = useDispatch();
    const location = useLocation()
    const role = useSelector(selectRole)
    const [state, setState] = useState<string[] | null>(null);
    const [filter, setFilter] = useState({stages: [], task: '', project: {id: '', status: ''}});
    const {isLoading} = useTasksFields([TTasksTypesFields.statuses]);
    const {isVisible, closeModal} = useHandlerModal(status);
    const {isLoading: isLoadingProjects} = useSelector(selectProjectsStatus);
    const project = useSelector(selectProject);
    const tasks = useSelector(selectTaskNPOptions);
    const stages = useSelector(selectSprintsListOptions)
    const typeLocation = location.pathname.split('/')[1]

    const {isLoading: isLoadingTask} = useSelector(selectTaskStatus);

    const {data: actualProjects = []} = ProjectLib.useGetProjectAllOptions({statusCodes: ['ACTUAL', 'ARCHIVED']})


    useEffect(() => {
       if (typeLocation === 'tasks') put(GetProjectSuccessAction(null))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (typeLocation === 'project' && data?.projectId) put(GetSprintsStartAction(data.projectId))
        // eslint-disable-next-line
    },[data?.projectId])

    const handlerFilter = (type: 'stages' | 'task' | 'project') => (value: any, option: any) => {
        if (type === 'stages' && value.length) {
            if (data?.projectId) {
                if (data?.projectStatus === 'ARCHIVED') put(GetAllTasksNPStartAction({projectIds: [data.projectId], sprintIds: value}))
                else put(GetAllTasksNPStartAction({projectIds: [data.projectId], sprintIds: value}))

            } else {
                if (filter.project.status && filter.project.status === 'ARCHIVED') put(GetAllTasksNPStartAction({projectIds: [filter.project.id], sprintIds: value}))
                else put(GetAllTasksNPStartAction({projectIds: [filter.project.id], sprintIds: value}))

            }
            setFilter({...filter, stages: value, task: ''})
        }  else if (type === 'project') {
            setFilter({...filter, [type]: {id:value, status: option['data-name']}, stages: [], task: ''})
            put(GetSprintsStartAction(value))
        } else {
            setFilter({...filter,[type]: value})
        }
    }
    const dateChange = (id: number) => (value: any, dateString: string) => {
        setState(prevState => {
            const arr = prevState === null ? [] : [...prevState];
            arr[id] = dateString
            return [...arr]
        })
    }

    useEffect(() => {
        return () => {put(ClearTasksNPAction())}
    }, [put]);

    const validateTask = (tasks: IOptions<ReportType | TypesTask>[]) => {
        let res = tasks;
        if (role === 'ROLE_FINANCIER') {
            res = res.filter((el) => el.code as ReportType === ReportType.PROJECT_ESTIMATE)
        }
        if (role === 'ROLE_MODERATOR') {
            res = res.filter((el) => !!el.check && el.code as TypesTask === TypesTask.CONTENT)
        }
        if (role === 'ROLE_ANALYST') {
            res = res.filter((el) => el.code as ReportType === ReportType.CONTENT_REACH_REPORT)
        }
        if (role === 'ROLE_CONTENT_EDITOR') {
            res = res.filter((el) => el.code as TypesTask === TypesTask.CONTENT)
        }
        if (role && ["ROLE_ANALYST", "ROLE_FINANCIER", "ROLE_CONTENT_EDITOR"].includes(role)) {
            res = res.filter((el) => !el.check)
        }
        return res?.map((el) => (
            <Option key={el.value} value={el.value}>{el.label}</Option>
        ));
    }
    return (
        <Modal
            className='ModalDuplicate'
            title="Дублировать задачу"
            visible={isVisible}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Отменить</Button>
                    <Button type={"primary"} onClick={_event => {
                        if (state && state.length ) {
                            put(DuplicateTasksStartAction(filter.task, state, data?.page ? [data.page] : undefined))
                            closeModal()
                        }
                    }}>Создать</Button>
                </>

            )}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='ModalDuplicate__body'>
                <Row>
                    <Col span={24}>
                        <div className='form-group'>
                            <label htmlFor="project">Проект</label>
                                {data && data.projectId && project ? (
                                    <span className='title'>{project.name}</span>
                                ) : (
                                    <Spin spinning={isLoadingProjects}>
                                        <Select
                                            className={'ModalDuplicate__body__select'}
                                            showSearch
                                            onChange={handlerFilter("project")}
                                            id='project'
                                            placeholder='Выберите проект'
                                            filterOption={(input, option) => {
                                                if (option === undefined || option === null) return false
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}
                                        >
                                            {actualProjects?.map((el) => (
                                                <Option key={el.value} value={el.value} data-name={el.code}>{el.label}</Option>
                                            ))}
                                        </Select>
                                    </Spin>
                                )}
                        </div>
                    </Col>
                </Row>

                <Row justify={"space-between"}>
                    <Col span={24}>
                        <Spin spinning={isLoading}>
                            <div className='form-group'>
                                <label htmlFor="step">Этап</label>
                                    <Select
                                        maxTagCount={5}
                                        mode={"multiple"}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={handlerFilter("stages")}
                                        id='step'
                                        placeholder='Выберите этап / этапы проекта'
                                        disabled={project ? false : !filter.project.id}
                                        value={filter.stages || undefined}
                                    >
                                        {stages?.map((el) => (
                                            <Option key={el.value} value={el.value}>{el.label}</Option>
                                        ))}
                                    </Select>
                            </div>
                        </Spin>
                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <Spin spinning={isLoadingTask}>
                            <div className='form-group'>
                                <label htmlFor="task">Задача</label>
                                <Tooltip title={!filter.stages ? 'Сначала необходимо выбрать этап' : ''}>
                                    <Select
                                        onChange={handlerFilter("task")}
                                        id='task'
                                        placeholder='Выберите задачу'
                                        disabled={project ? !filter.stages.length : (!filter.project.id || !filter.stages.length)}
                                        value={filter.task || undefined}
                                    >
                                        {tasks && validateTask(tasks as IOptions<ReportType>[])}
                                    </Select>
                                </Tooltip>
                            </div>
                        </Spin>
                    </Col>
                </Row>
                <Tooltip title={!filter.stages ? 'Сначала необходимо выбрать этап' : ''}>
                    <div className='ModalDuplicate__body-action' onClick={_event => {
                        if (filter.stages) {
                            setState(prevState => {
                                if (!prevState) return ['']
                                else return [...prevState, '']
                            })
                        }
                    }}>Добавить дубль задачи и срок <PlusCircleOutlined /></div>
                </Tooltip>
                {state && state.map((el, id) => {
                    return (
                        <Row key={`el-${id}`} align={"middle"} className='ModalDuplicate__body-item'>
                            <Col md={12} xs={24}><span>{id + 1}. Срок исполнения</span></Col>
                            <Col md={12} xs={24}>
                                <div className='form-group'>
                                    <DatePicker
                                        size='small'
                                        disabledDate={(date: moment.Moment) => {
                                            return !date.isAfter(new Date());
                                        }}
                                        onChange={dateChange(id)}
                                        value={el ? moment(el) : null}
                                        placeholder={'Введите'}
                                        locale={locale}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )
                })}

            </div>
        </Modal>
    )
};
export default ModalDuplicate;

