import {useMutation, useQuery} from 'react-query';
import {axiosRequest} from 'shared/config/axios';
import {getRestPath} from 'shared/lib/get-rest-path';
import {RestKeys} from './rest-keys';
import {
    UserAction, UserActionDetails, UserActionDetailsSchema,
    UserActionsFilter,
    UserActionsSearch,
    UserActionsStatisticFilter,
    UserActionsStatisticSearch
} from "../../types";

// Логирование действий пользователя
export function useLogUserAction() {
    return useMutation({
        mutationFn: async (actionData: UserAction) => {
            await axiosRequest({
                method: 'POST',
                url: RestKeys.logUserAction,
                data: actionData
            });
            return actionData;
        },
    });
}

// Получение списка действий пользователя
export function useGetUserActionsList(params: UserActionsFilter) {
    return useQuery({
        queryKey: [RestKeys.getUserActionsList, params],
        queryFn: async () => {
            const { data } = await axiosRequest<UserActionsSearch>({
                method: 'POST',
                url: RestKeys.getUserActionsList,
                data: params
            });
            return data;
        },
    });
}

// Получение статистики действий пользователей
export function useGetUserActionsStatistics(params?: UserActionsStatisticFilter) {
    return useQuery({
        queryKey: [RestKeys.getUserActionsStatistics, params],
        queryFn: async () => {
            const { data } = await axiosRequest<UserActionsStatisticSearch>({
                method: 'POST',
                url: RestKeys.getUserActionsStatistics,
                data: params
            });
            return data;
        },
    });
}

// Получение информации о конкретном действии пользователя
export function useGetUserActionDetails(userActionId: string) {
    return useQuery({
        queryKey: [RestKeys.getUserActionDetails, userActionId],
        queryFn: async () => {
            const { data } = await axiosRequest<UserActionDetails>({
                method: 'GET',
                url: getRestPath(RestKeys.getUserActionDetails, { userActionId }),
                parser: UserActionDetailsSchema
            });
            return data;
        },
        enabled: !!userActionId
    });
}
