import {AxiosResponse} from "axios";
import $api from "../../../http";
import {TProjectsFilter, TProjectsFilterExtendedSearch} from "models/projects/filter";
import {ProjectNationalTags, ProjectThematics, ProjectUsers, TCategoriesGenres, TRegions,} from "models/projects";
import {T_ID_NAME_CODE} from "models/common";
import {TContentFormats} from "models/projects/contentFormats";
import {TChannels} from "models/projects/channels";
import {TApprovedContentSubmission} from "models/projects/approved-content-submission";
import {ContentDates, Project, ProjectLabel, ProjectsListResponse,} from '../types';
import {ProjectTypes} from 'entities/project';
import {axiosRequest} from "shared/config/axios";
import {Id_Name_Code_Hidden} from "shared/types/common";

/**
 * @category Service
 * @class ProjectService
 */
export default class ProjectService {
    /**
     * @name getProjectsExtendedSearch
     * @description Получение списка проектов
     */
    static async getProjectsExtendedSearch(data: TProjectsFilterExtendedSearch): Promise<AxiosResponse<ProjectsListResponse>> {
        const filter = { ...data, page: data.page - 1};
        return $api.post('/projects/extended-search', filter)
    }
    /**
     * @name getProjects
     * @description Получение списка проектов
     */
    static async getProjects(data: TProjectsFilter): Promise<AxiosResponse<ProjectsListResponse>> {
        const filter = { ...data, page: data.page - 1};
        return $api.post('/projects/search', filter)
    }
    /**
     * @name createProject
     * @description Создание нового проекта
     */
    static async createProject(data: any): Promise<AxiosResponse<string | number>> {
        return $api({
            method: 'POST',
            url: '/projects',
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: data
        })
    }

    /**
     * @name getProject
     * @description Получение проекта по ID
     */
    static async getProject(projectId: string): Promise<AxiosResponse<Project>> {
        return $api.get(`/projects/${projectId}`)
    }

    static async getProjectsTypes(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get('/projects/types')
    }
    /**
     * @name updateProject
     * @description Обновление проекта
     */
    static async updateProject(projectId: string, data: any): Promise<AxiosResponse<unknown>> {
        return $api({
            method: 'PUT',
            url: `/projects/${projectId}`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: data
        })
    }
    /**
     * @name deleteProject
     * @description Удаление проекта
     */
    static async deleteProject(projectId: string): Promise<AxiosResponse<unknown>> {
        return $api.delete(`/projects/${projectId}`)
    }

    /**
     * @name getProjectsChannels
     * @description Получение наименований каналов проектов
     */
    static projectsChannelsKey = '/projects/channel-names'
    static async getProjectsChannels(): Promise<AxiosResponse<string[]>> {
        return $api.get<string[]>('/projects/channel-names')
    }
    /**
     * @name updateProjectsStatus
     * @description Изменение статуса проекта
     */
    static async updateProjectsStatus(projectId: string, statusId: string): Promise<AxiosResponse<any>> {
        return $api({
            method: 'PUT',
            url: `/projects/${projectId}/status`,
            params: {
                statusId: statusId
            },
            headers: {
                'Actioncode': 'UPDATE_PROJECTS_PROJECT_ID_STATUS'
            }
        })
    }

    /**
     * @name getProjectsWithReachData
     * @description Получение списка проектов содержащих данные по охватам
     */
    static async getProjectsWithReachData (sessionId: any): Promise<AxiosResponse<T_ID_NAME_CODE>> {
        return $api({
            method: 'GET',
            url: '/projects/with-reach-data',
            params: {
                sessionId: sessionId,
            },
        })
    }
    /**
     * @name getProjectsReporting
     * @description Получение списка проектов содержащих данные по охватам
     */
    static async getProjectsReporting(sessionId: any): Promise<AxiosResponse<T_ID_NAME_CODE>> {
        return $api({
            method: 'GET',
            url: '/projects',
            params: {
                sessionId: sessionId,
            },
        })
    }
    /**
     * @name projectsBulkActions
     * @description Отправка массовых действий
     */
    static async projectsBulkActions(projectsId: string[], action: string): Promise<AxiosResponse<unknown>> {
        return $api.post(`/projects/bulk-actions/${action}`, projectsId)
    }
    static async userLinkExist(projectId: string): Promise<AxiosResponse<unknown>> {
        return $api({
            method: 'POST',
            url: `/projects/${projectId}/user-link-exist`,
            headers: {
                'Actioncode': 'POST_PROJECTS_PROJECT_ID_USER_LINK_EXIST'
            }
        })
    }

    /**
     *   Выгрузка шаблона
     */
    static async downloadProjectsTemplate( ): Promise<AxiosResponse<BlobPart>> {
        return  $api({
            method: 'GET',
            url:  '/projects/import/template',
            responseType: 'arraybuffer',
        })
    }

    /**
     * Импорт проектов
     */
    static async uploadProjects (data: any): Promise<AxiosResponse<unknown>> {
        return $api({
            method: 'POST',
            url: `/projects/import`,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data,
        })
    }
    static getRegionsForKey = (projectId: string) => `/projects/${projectId}/regions_for`;
    static async getRegionsFor(projectId: string): Promise<AxiosResponse<Array<Id_Name_Code_Hidden>>> {
        return $api.get(`/projects/${projectId}/regions_for`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_REGIONS_FOR'
            }
        })
    }
    static getRegionsAboutKey = (projectId: string) => `/projects/${projectId}/regions_about`;
    static async getRegionsAbout(projectId: string): Promise<AxiosResponse<Array<Id_Name_Code_Hidden>>> {
        return $api.get(`/projects/${projectId}/regions_about`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_REGIONS_ABOUT'
            }
        })
    }

    static async getContentDates(projectId: string): Promise<AxiosResponse<ContentDates>> {
        return $api.get(`/projects/${projectId}/content-dates`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_CONTENT_DATES'
            }
        })
    }
    static async getContentFormats(projectId: string): Promise<AxiosResponse<TContentFormats[]>> {
        return $api.get(`/projects/${projectId}/content-formats`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_CONTENT_FORMATS'
            }
        })
    }
    static async getApprovedContentSubmission(projectId: string): Promise<AxiosResponse<TApprovedContentSubmission[]>> {
        return $api.get(`/projects/${projectId}/approved-content-submission-forms `)
    }
    static async getThematics(projectId: string): Promise<AxiosResponse<ProjectThematics>> {
        return $api.get(`/projects/${projectId}/thematics`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_THEMATICS'
            }
        })
    }
    static async getRegions(projectId: string): Promise<AxiosResponse<TRegions>> {
        return $api.get(`/projects/${projectId}/regions`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_REGIONS'
            }
        })
    }
    static async getChannels(projectId: string): Promise<AxiosResponse<TChannels[]>> {
        return $api.get(`/projects/${projectId}/channels`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_CHANNELS'
            }
        })
    }
    static async getUsers(projectId: string): Promise<AxiosResponse<ProjectUsers>> {
        return $api.get(`/projects/${projectId}/users`)
    }
    static async getNationalTags(projectId: string): Promise<AxiosResponse<ProjectNationalTags>> {
        return $api.get(`/projects/${projectId}/national-tags`)
    }
    static async getCategoriesGenres(projectId: string): Promise<AxiosResponse<TCategoriesGenres>> {
        return $api.get(`/projects/${projectId}/categories-genres`, {
            headers: {
                'Actioncode': 'GET_PROJECTS_PROJECT_ID_CATEGORIES_GENRES'
            }
        })
    }
    /**
     * Выполнение выгрузки списка в формате Excel
     */
    static async downloadProjects(projectsIds: string[]): Promise<AxiosResponse> {
        return $api({
            method: 'POST',
            url: '/projects/export',
            responseType:'arraybuffer',
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: projectsIds,
        })
    }
    /**
     * @name getProjectsAll
     * @description Получение списка проектов без пагинации
     */
    static projectsAllKey = '/projects/all'
    static async getProjectsAll(params: ProjectTypes.ProjectsAllRequest) {
        const {data} = await axiosRequest<ProjectTypes.ProjectsAllResponse[]>({
            url: '/projects/all',
            method: 'POST',
            parser: ProjectTypes.ProjectsAllResponseSchema.array(),
            data: params
        })
        return data
    }
    /**
     * @name changeProjectLabel
     * @description Изменение метки редактора контента проекта
     */
    static async changeProjectLabel(props: {
        projectId: string, labelIds?: string[], labelTypeCode: ProjectLabel['labelTypeCode']
    }): Promise<AxiosResponse<never>> {
        const {labelIds, projectId, labelTypeCode} = props;
        return $api({
            method: 'put',
            url: `/projects/${projectId}/project-label`,
            params: {labelIds, labelTypeCode},
            paramsSerializer: { indexes: null },
            headers: {
                'Actioncode': 'UPDATE_PROJECTS_PROJECT_ID_PROJECT_LABEL'
            }
        })
    }
    /**
     * @name changeFavoriteProject
     * @description Изменение признака избранности проекта
     */
    static async changeFavoriteProject(projectId: string, isFavorite: boolean): Promise<AxiosResponse<never>> {
        return $api({
            method: 'put',
            url: `/projects/${projectId}/favorite-project`,
            params: {isFavorite},
            headers: {
                'Actioncode': 'UPDATE_PROJECTS_PROJECT_ID_FAVORITE_PROJECT'
            }
        })
    }
}
