import React, { useMemo } from 'react'
import styles from '../styles.module.less'
import { Field, useFormikContext } from 'formik'
import { TInitValues } from '../modal-content-records'
import DropdownComponent from './dropdown'
import { TagLib } from 'entities/tags'
import moment from 'moment'
import { PROJECT_CREATE_DATE } from 'constants/variables'
import { Select, Tooltip } from 'antd'
import { IOptions } from 'models/feels'
import Icon from 'shared/ui/icon'
import { FieldAttributes } from 'formik/dist/Field'

const { Option } = Select

const ContentTags = ({ projectCreateDate }: { projectCreateDate?: string }) => {
	const { setFieldValue, values } = useFormikContext<TInitValues>()
	const name = 'contentTags'
	// Определяем, новый ли проект
	const isNewProject = moment(projectCreateDate).isAfter(moment(PROJECT_CREATE_DATE))
	const isDisabled = values.contentTags.length > 4 && isNewProject

	const queryParams = useMemo(
		() => ({
			isForNationalProject: false,
			isAssignment: false,
			isCollection: false,
			isTheme: false,
			level: isNewProject ? null : undefined, // Для новых проектов level=null, для старых — без фильтра
		}),
		[isNewProject]
	)
	const { data: contentTags } = TagLib.useGetProjectTagsOptions(queryParams)

	const handlerChange = (value: string[]) => {
		setFieldValue(name, value ? value : [])
	}
	return (
		<div className={styles.item}>
			<label htmlFor={name} className={styles.label}>
				Теги единиц контента
			</label>
			<div className="form-group">
				<Field id={name} name={name}>
					{(el: FieldAttributes<any>) => {
						const val = el.field.value
						return (
							<div className={`select-wrapper`}>
								<Select
									getPopupContainer={(trigger) => trigger.parentNode}
									className={styles.select}
									maxTagCount={3}
									allowClear={true}
									mode={'multiple'}
									value={val}
									placeholder={'Выберите тег'}
									onChange={handlerChange}
									optionFilterProp="children"
									filterOption={(input, option) => {
										if (option === undefined || null) return false
										const optionText = option?.['data-code']
										return optionText?.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}}
									dropdownRender={(menu) => (
										<div>
											{!isNewProject && (
												<DropdownComponent name={'contentTags'} options={contentTags.map((el) => el.value)} />
											)}
											{menu}
										</div>
									)}
								>
									{contentTags &&
										contentTags.map((option: IOptions) => (
											<Option
												key={option.value}
												value={option.value}
												data-code={option.label}
												disabled={isDisabled && !values.contentTags?.includes(option.value)}
											>
												<div className={styles.option}>
													<span className={styles.option_label}>{option.label}</span>
													<Tooltip title={option.code || 'нет описания'}>
														<Icon.Other name={'info-circle'} size={{ width: 12, height: 12 }} />
													</Tooltip>
												</div>
											</Option>
										))}
								</Select>
							</div>
						)
					}}
				</Field>
			</div>
		</div>
	)
}

export default ContentTags
