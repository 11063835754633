export function formatFileSize(bytes: number): string {
	// Настраиваем форматирование чисел с учетом локализации
	const formatter = new Intl.NumberFormat('ru-RU', {
		style: 'decimal',
		minimumFractionDigits: 1,
		maximumFractionDigits: 1,
	});

	// Если меньше 1024 байт, возвращаем в байтах
	if (bytes < 1024) {
		return `${bytes} Б`;
	}
	// Если меньше 1 МБ, возвращаем в килобайтах
	else if (bytes < 1024 * 1024) {
		const kilobytes = bytes / 1024;
		return `${formatter.format(kilobytes)} Кб`;
	}
	// Если меньше 1 ГБ, возвращаем в мегабайтах
	else if (bytes < 1024 * 1024 * 1024) {
		const megabytes = bytes / (1024 * 1024);
		return `${formatter.format(megabytes)} Мб`;
	}
	// Если меньше 1 Тб, возвращаем в гигабайтах
	else if (bytes < 1024 * 1024 * 1024 * 1024) {
		const gigabytes = bytes / (1024 * 1024 * 1024);
		return `${formatter.format(gigabytes)} Гб`;
	}
	// Если больше или равно 1 Тб, возвращаем в терабайтах
	else {
		const terabytes = bytes / (1024 * 1024 * 1024 * 1024);
		return `${formatter.format(terabytes)} Тб`;
	}
}
