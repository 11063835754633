import React, {FC, SyntheticEvent, useState} from 'react';
import {TProjectResponse, TypeContent} from 'models/content'
import {Tooltip} from "antd";
import WhetherTheUser from "components/whetherTheUser";

type TTags = {
    content: TypeContent | null
    project: TProjectResponse | null
}

const Tags: FC<TTags> = ({ content, project }) => {
    const contentTags: {tag: Set<string>} = {
        tag: new Set([]),
    }

    content?.contentTags?.forEach((el) => {
        contentTags.tag.add(el.name)
    })

    return (
        <>
            {project && (
                <div className="item tags">
                    <span>Формат проекта: </span>
                    <span className="blue">{project?.categoryTypes?.length ? Array.from(project?.categoryTypes.map(it => it.name)).join(', ') : 'нет данных'}</span>
                    <br/>
                    <span>Жанр проекта: </span>
                    <span className="blue">{project?.genreTypes?.length ? Array.from(project?.genreTypes.map(it => it.name)).join(', ') : 'нет данных'}</span>
                </div>
            )}
            {content && (
            <>
                <span className="title">Регион контента</span>
                <div className="item tags">
                    <div className="tags__content">
                        <TagsRegions arr={content.regionAboutSet} title="О каком регионе контент:" />
                        <WhetherTheUser role={['ROLE_ADMIN', 'ROLE_ADMIN_IS']} isCan={true}>
                            <TagsRegions arr={content.regionForSet} title="Для какого региона контент:" />
                        </WhetherTheUser>
                    </div>
                </div>
            </>
            )}
            {content && (
                <div className="item tags">
                    <span>Теги единиц контента: </span>
                    <span className="blue">{contentTags.tag.size > 0 ? Array.from(contentTags.tag).join(', ') : 'нет данных'}</span>
                </div>
            )}
        </>
    )
};

export default Tags;

function TagsRegions({arr, title}: { arr: any, title: string }) {
    const [visible, setVisible] = useState<boolean>(false)
    const length = visible ? arr.length : 1 // длина либо 1 эдемент либо все элементы при visible - true
    const text = !!arr?.length ?
        arr.slice(0, length)
        .map((el: any) => el.name).join(', ')
        : 'нет данных'

    const handlerClickShow = (e: SyntheticEvent) => {
        e.stopPropagation()
        setVisible(prevState => !prevState)
    }


    return (
        <div className="tags__content-item">
            <strong>{title}</strong>
            <span>{text}</span>
            {arr.length > 1 && (
                <div
                    className="tags__content-amount"
                    onClick={handlerClickShow}
                >
                    <Tooltip placement="top" title={visible ? "Свернуть" : "Посмотреть все"}>
                        {!visible ? `+${arr.length - 1}` : '—'}
                    </Tooltip>
                </div>
            )}
        </div>
    )
}
