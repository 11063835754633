import {put} from "redux-saga/effects";
import {GetFeelsErrorAction, GetFeelsStartAction, GetFeelsSuccessAction} from "store/app/actions";

import AppService from 'services/appService'
import UsersService from 'services/usersService'
import {AxiosResponse} from 'axios'
import {TPerson} from 'models/user'
import {TInternetResource} from 'models/projects/internetResource'
import {T_ID_NAME, TFormatTypes} from 'models/common'

const FeelActions = {
	'statuses': AppService.getStatuses,
	'formatTypes': AppService.getFormatTypes,
	'thematics': AppService.getThematics,
	'formatKpi': AppService.getKpiTypes,
	'roles': AppService.getRoles,
	'reportType': AppService.getReportType,
	'contractors': UsersService.getContractors,

	'contractorsId': UsersService.getContractorsId,
	'coordinators': UsersService.getCoordinators,
	'producers': UsersService.getProducers,
	'analysts': UsersService.getAnalysts,
	'financiers': UsersService.getFinanciers,
	'lawyers': UsersService.getLawyers,
	'contentEditors': UsersService.getContentEditors,
	'projectManagers': UsersService.getProjectManagers,
	'formatSubmission': AppService.getFormatSubmissionById,
	'ranks':  AppService.getRanks,
	'regions':  AppService.getRegions,
	'categories':  AppService.getCategoryProject,
	'genres':  AppService.getGenreProject,
}

export function* getFeels({ feel, id }: ReturnType<typeof GetFeelsStartAction>) {
	try {
		const idIfNeed = id ? id : null;
		const { data }: AxiosResponse<TPerson[] |
			TInternetResource[] |
			T_ID_NAME[] |
			TFormatTypes[]> = yield FeelActions[feel](idIfNeed as string)
		yield put(GetFeelsSuccessAction({ [feel]: data }))
	} catch (error) {
		yield put(GetFeelsErrorAction(error))
	}
}
