import {useQuery, UseQueryResult} from 'react-query'
import {useMemo} from 'react'
import {getSessionsTypes} from "../api/session-type-controller";
import {Id_Name_Code_Hidden} from "shared/types/common";

type onSuccessType = (data: Id_Name_Code_Hidden[]) => void
export function useGetSessionsTypes(onSuccess?: onSuccessType): UseQueryResult<Id_Name_Code_Hidden[]> {
    return useQuery({
        queryKey: [getSessionsTypes.key],
        queryFn: () => getSessionsTypes.fetch(),
        onSuccess,
    })
}
export function useGetSessionsTypesOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetSessionsTypes(onSuccess)
    const options = useMemo(() => data.map((el) => ({
        value: el.id,
        label: el.name,
        code: el.code,
        hidden: el.hidden
    })), [data]);
    return { data: options, ...rest }
}
