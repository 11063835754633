import React, {FC, useState} from 'react';
import './index.less'
import {Button, Empty, Modal} from "antd";
import useHandlerModal from "hooks/handlModal";
import {Nullable} from "models/common";
import InnerHTMLFunction from "utils/innerHTMLFunction";
import ReactQuill from "react-quill";

export type TModalComment= {
    title: Nullable<string>,
    isEdit: boolean,
    comment: string,
    handlerSave: Function
}

type Props = {
    status: boolean
    data: TModalComment
}
const ModalComment: FC<Props> = ({status, data}) => {
    const {isVisible, closeModal} = useHandlerModal(status);
    const [state, setState] = useState({
        comment: data.comment,
        count: data.comment.length
    });

    return (
        <Modal
            className='modal-comment'
            title={(
                <>
                    <span>Описание контента</span> <br/>
                    {data.title && (<span>{`"${data.title}"`}</span>)}
                </>

            )}
            visible={isVisible}
            footer={(
                <>
                    <Button type={"default"} onClick={closeModal}>Закрыть</Button>
                    {data.isEdit && (
                        <Button type={"primary"} disabled={state.count > 1200} onClick={_event => {
                            data.handlerSave(state.comment)
                            closeModal()
                        }}>Сохранить</Button>
                    )}
                </>
            )}
            maskClosable={true}
            onCancel={closeModal}
        >
            <div className='modal-vote__list'>
                <div className="item__content">
                    {data.isEdit ? (
                        <div className="form-group">
                            <ReactQuill
                                placeholder={'Описание контента'}
                                onChange={value => {
                                    setState({comment: value, count: value.length})
                                }}
                                theme="snow"
                                value={state.comment}
                            />
                            <span className='count'>{state.count}/1200</span>
                        </div>
                    ) : (
                        <>
                            {!!state.comment ? (
                                <p dangerouslySetInnerHTML={InnerHTMLFunction(state.comment)}/>
                            ) : (
                                <Empty/>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
};
export default ModalComment;
