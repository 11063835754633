export const TasksContentActionTypes = {
    CLEAR_FILTER: '[TASK_CONTENT] CLEAR_FILTER',

    SET_TASK_FILTER: '[TASK_CONTENT] SET_TASK_FILTER',
    SET_BULK_ACTION: '[TASK_CONTENT] SET_BULK_ACTION',
    SET_ROWS_IDS: '[TASK_CONTENT] SET_ROWS_IDS',

    ADD_TASK_STAT: '[TASK_CONTENT] ADD_TASK_STAT',
    ADD_TASK_SUCCESS: '[TASK_CONTENT] ADD_TASK_SUCCESS',
    ADD_TASK_ERROR: '[TASK_CONTENT] ADD_TASK_ERROR',

    GET_TASKS_STAT: '[TASK_CONTENT] GET_TASKS_STAT',
    GET_TASKS_SUCCESS: '[TASK_CONTENT] GET_TASKS_SUCCESS',
    GET_TASKS_ERROR: '[TASK_CONTENT] GET_TASKS_ERROR',

    GET_RECORD_START: '[TASK_CONTENT] GET_RECORD_START',
    GET_RECORD_SUCCESS: '[TASK_CONTENT] GET_RECORD_SUCCESS',
    GET_RECORD_ERROR: '[TASK_CONTENT] GET_RECORD_ERROR',

    UPDATE_TASK_STAT: '[TASK_CONTENT] UPDATE_TASK_STAT',
    UPDATE_TASK_SUCCESS: '[TASK_CONTENT] UPDATE_TASK_SUCCESS',
    UPDATE_TASK_ERROR: '[TASK_CONTENT] UPDATE_TASK_ERROR',

    DELETE_TASK_STAT: '[TASK_CONTENT] DELETE_TASK_STAT',
    DELETE_TASK_SUCCESS: '[TASK_CONTENT] DELETE_TASK_SUCCESS',
    DELETE_TASK_ERROR: '[TASK_CONTENT] DELETE_TASK_ERROR',

    VOTE_TASK_START: '[TASK_CONTENT] VOTE_TASK_START',
    VOTE_TASK_SUCCESS: '[TASK_CONTENT] VOTE_TASK_SUCCESS',
    VOTE_TASK_ERROR: '[TASK_CONTENT] VOTE_TASK_ERROR',

    INTERIM_VOTE_TASK_START: '[TASK_CONTENT] INTERIM_VOTE_TASK_START',
    INTERIM_VOTE_TASK_SUCCESS: '[TASK_CONTENT] INTERIM_VOTE_TASK_SUCCESS',
    INTERIM_VOTE_TASK_ERROR: '[TASK_CONTENT] INTERIM_VOTE_TASK_ERROR',

    VOTE_TASK_STATUS: '[TASK_CONTENT] VOTE_TASK_STATUS',
    VOTE_TASK_MULTIPLE_STATUSES: '[TASK_CONTENT] VOTE_TASK_MULTIPLE_STATUSES',
    VOTE_TASK_COMMENT: '[TASK_CONTENT] VOTE_TASK_STATUS',
    VOTE_TASK_CHANGE: '[TASK_CONTENT] VOTE_TASK_CHANGE',

    TO_EXAMINATION_TASK_START: '[TASK_CONTENT] TO_EXAMINATION_TASK_START',
    TO_EXAMINATION_TASK_SUCCESS: '[TASK_CONTENT] TO_EXAMINATION_TASK_SUCCESS',
    TO_EXAMINATION_TASK_ERROR: '[TASK_CONTENT] TO_EXAMINATION_TASK_ERROR',

    RESET_STATUS_TASK_START: '[TASK_CONTENT] RESET_STATUS_TASK_START',
    RESET_STATUS_TASK_SUCCESS: '[TASK_CONTENT] RESET_STATUS_TASK_SUCCESS',
    RESET_STATUS_TASK_ERROR: '[TASK_CONTENT] RESET_STATUS_TASK_ERROR',

    SEND_BULK_VOTES_START: '[TASK_CONTENT] SEND_BULK_VOTES_START',
    SEND_BULK_VOTES_SUCCESS: '[TASK_CONTENT] SEND_BULK_VOTES_SUCCESS',
    SEND_BULK_VOTES_ERROR: '[TASK_CONTENT] SEND_BULK_VOTES_ERROR',

    SEND_BULK_REVIEW_START: '[TASK_CONTENT] SEND_BULK_REVIEW_START',
    SEND_BULK_REVIEW_SUCCESS: '[TASK_CONTENT] SEND_BULK_REVIEW_SUCCESS',
    SEND_BULK_REVIEW_ERROR: '[TASK_CONTENT] SEND_BULK_REVIEW_ERROR',

    CREATE_FILE_ARCHIVE_START: '[TASK_CONTENT] CREATE_FILE_ARCHIVE_START',
    CREATE_FILE_ARCHIVE_SUCCESS: '[TASK_CONTENT] CREATE_FILE_ARCHIVE_SUCCESS',
    CREATE_FILE_ARCHIVE_ERROR: '[TASK_CONTENT] CREATE_FILE_ARCHIVE_ERROR',
} as const;
