import {axiosRequest} from 'shared/config/axios';
import {
	ContentSubmissionForm,
	ContentSubmissionFormFilter, ContentSubmissionFormRequest,
	ContentSubmissionForms,
	ContentSubmissionFormSchema
} from '../types';
import {downloadXLXS} from "utils/downloadXLXS";

/**
 * @description Получение всех форм сдачи контента проектов
 */
export const getSubmissionForms = {
	key: '/projects/submission_forms',
	fetch: async () => {
		const {data} = await axiosRequest<ContentSubmissionForm[]>({
			url: '/projects/submission_forms',
			method: 'GET',
			showError: true,
			parser: ContentSubmissionFormSchema.array()
		})
		return data
	},
}
/**
 * Получение всех форм сдачи контента проектов по проекту (с возможностью фильтрации по
 * типу формата контента)
 */

export const getSubmissionFormsById = {
	key: (projectId: string) => `/projects/${projectId}/submission_forms`,
	fetch: async (data: {projectId: string, formatTypeId?: string}) => {
		const {data: contentSubmissionForm} = await axiosRequest<ContentSubmissionForm[]>({
			url: `/projects/${data.projectId}/submission_forms`,
			method: 'GET',
			showError: true,
			...(data?.formatTypeId && {params: {formatTypeId: data?.formatTypeId}}),
			parser: ContentSubmissionFormSchema.array(),
			headers: {
				'Actioncode': 'GET_PROJECTS_PROJECT_ID_SUBMISSION_FORMS'
			}
		})
		return contentSubmissionForm
	},
}

/**
 * Получение списка форм сдачи контента с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/projects/submission_forms/search',
	fetch: async (filter: ContentSubmissionFormFilter): Promise<ContentSubmissionForms> => {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<ContentSubmissionForms>({
			method: 'POST',
			url: '/projects/submission_forms/search',
			data: newFilter,
			parser: { list: true, schema: ContentSubmissionFormSchema.array() },
		})
		return data
	},
}
/**
 * Создание новой формы сдачи контента
 */
export const create = async (data: ContentSubmissionFormRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/projects/submission_forms',
		data,
	})
}

/**
 * Обновление формы сдачи контента
 */
export const update = async ({ id, data }: { id: string; data: ContentSubmissionFormRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/projects/submission_forms/${id}`,
		data,
	})
}

/**
 * Удаление формы сдачи контента
 */
export const remove = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/projects/submission_forms/${id}`,
	})
}

/**
 * Массовые действия
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/projects/submission_forms/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/projects/submission_forms/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
	})
	downloadXLXS(data, 'список_формы_сдачи_контента')
}
/**
 * Получение файла шаблона для выполнения импорта данных о формах сдачи контента
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/projects/submission_forms/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
	downloadXLXS(data, 'шаблон_формы_сдачи_контента')
}
/**
 * Выполнение импорта данных о формах сдачи контента из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/projects/submission_forms/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	})
}
