import {z} from "zod";
import {FilterRequest, Id_Name_Code_Hidden_Schema, Id_Name_Code_Schema, SearchResponse} from "shared/types/common";
import {Nullable} from "models/common";

export const ContentDirectionSchema = Id_Name_Code_Hidden_Schema.extend({
    image: z.string(),
    code: z.enum(['VIDEO_CONTENT', 'BLOG_CONTENT', 'SOFTWARE', 'MULTIFORMAT']).or(z.string()),
    reportTemplates: z.array(Id_Name_Code_Schema.extend({
        fileExtensions: z.array(z.string())
    }))
})
export type ContentDirection = z.infer<typeof ContentDirectionSchema>

export type ContentDirectionRequest = {
    code: string | null
    name: string
    hidden: boolean
    image: Nullable<string>
    reportTemplateIds: string[]
}

export type ContentDirectionFilterData = {
    searchString?: string
    hidden?: boolean
}

export type ContentDirectionFilter = FilterRequest<ContentDirectionFilterData>
export type ContentDirections = SearchResponse<ContentDirection>
