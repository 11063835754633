import { useRef, useState } from "react";
import { FFmpeg   } from "@ffmpeg/ffmpeg";
import { FileType } from 'shared/types/file';
import { toBlobURL } from '@ffmpeg/util';
import $api from '../../../http';
import { Button, Spin } from 'antd';
import {Notification} from 'processes/notification';

const ffmpeg = new FFmpeg();
ffmpeg.on('log', ({ message }) => console.log(message)); // Логирование
type Props = {
	file: FileType;
};


export default function VideoConverter({ file }: Props) {
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const ffmpegRef = useRef(new FFmpeg());
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const messageRef = useRef<HTMLParagraphElement | null>(null);

	const fileUrl = `/files/${file.id}`; // Использую переданный путь

	const load = async () => {
		try {
			setLoading(true);
			const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
			const ffmpeg = ffmpegRef.current;

			ffmpeg.on('log', ({ message }) => {
				if (messageRef.current) {
					messageRef.current.innerHTML = message;
				}
				console.log(message);
			});

			await ffmpeg.load({
				coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
				wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
			});

			setLoaded(true);
		} catch (e) {
			Notification.open({ type: 'error', info: {message: 'Не удалось загрузить wasm'}});
		} finally {
			setLoading(false)
		}
	};

	const transcode = async () => {
		try {
			setLoading(true);
			const ffmpeg = ffmpegRef.current;

			// Загружаем файл с передачей cookies
			const response = await $api.get(fileUrl, {
				responseType: 'arraybuffer', // Получаем файл как ArrayBuffer
			});

			if (response.status !== 200) {
				throw new Error(`Ошибка загрузки: ${response.status}`);
			}

			// Записываем файл в виртуальную ФС ffmpeg
			await ffmpeg.writeFile(file.name, new Uint8Array(response.data));

			// Формируем название выходного файла
			const outputName = file.name.replace(/\.\w+$/, '.mp4');

			// Запускаем конвертацию
			await ffmpeg.exec(['-i', file.name, outputName]);

			// Читаем готовый файл
			const data = await ffmpeg.readFile(outputName);

			if (videoRef.current) {
				// @ts-ignore
				if ('buffer' in data) {
					videoRef.current.src = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
				}
			}
		} catch (e) {
			console.log(e);
			Notification.open({ type: 'error', info: {message: 'Не удалось декодировать данные'}});
		} finally {
			setLoading(false)
		}

	};

	return loaded ? (
		<Spin spinning={loading}>
			<video ref={videoRef} controls width="100%" height="310"></video><br />
			<Button type={'primary'} onClick={transcode}>
				Декодировать файл в MP4
			</Button>
			<br />
			<p>!Для декодирования файл будет загружен (это займёт некоторое время)</p>
			<p ref={messageRef}></p>
		</Spin>
	) : (
		<Button type={'primary'} onClick={load}>Инициализировать декодер</Button>
	);
}
