import { Empty, Image, Spin } from 'antd';
import React, { FC, useState } from 'react';
import styles from './styles.module.less';
import env from '../../config/env';

type Props = {
	compressedId?: string | null;
	imageId?: string | null;
};
const plug = '/images/no-img.png'
const ImageComponent: FC<Props> = ({ imageId, compressedId }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const url = `${env.API_ENDPOINT}/files/`;
	const src = compressedId || imageId ? `${url}/${compressedId || imageId}` : plug;

	const onLoadHandler = () => {
		setLoading(false)
	}
	const onErrorHandler = () => {
		setError(true)
	}

	if (error) {
		return <Empty className={styles.error} description={'Не удалось загрузить изображение'} />
	}
	console.log('here')
	return (
		<Spin spinning={loading}>
			<Image
				className={styles.image}
				src={src}
				alt={`file-${ imageId}`}
				onLoad={onLoadHandler}
				onError={onErrorHandler}
				preview={{
					src: src
				}}
			/>
		</Spin>
	);
};

export default ImageComponent;
