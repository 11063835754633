import {put} from "redux-saga/effects";
import {GetTaskErrorAction, GetTaskStartAction, GetTaskSuccessAction,} from "store/tasks/actions/tasks";
import TasksService from "services/tasksService";
import {TTaskResponse} from "models/task";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import {NotificationOpen} from "store/notification/actions";
import {AxiosResponse} from "axios";
import {GetFileStartAction} from "store/files/actions";


export function* getTask({taskId}: ReturnType<typeof GetTaskStartAction>) {
    try {
        const {data: task}: AxiosResponse<TTaskResponse> = yield TasksService.getTask(taskId);

        yield put(GetTaskSuccessAction(task))

        for (const person of task.coordinators) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.producers) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.analysts) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.financiers) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.contentEditors) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.projectManagers) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
        for (const person of task.contractors) {
            if (person.image?.id) yield put(GetFileStartAction(person.image.id, person.image.type))
        }
    } catch (error: any) {
        yield put(GetTaskErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить задачу'))
    }
}
