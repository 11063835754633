import { FunctionalityInfoType } from '../types'

export const tasksProjects: FunctionalityInfoType = [
	{
		LevelGroupName: 'Просмотр списка задач с поиском, фильтрацией, пагинацией',
		LevelGroupData: [
			{
				name: 'Поиск задач',
				text: 'Для поиска задач по наименованию задачи, коду и номеру:',
				points: ['Нажмите "Название задачи"', 'Введите текст', 'Нажмите на кнопку поиска'],
				screenshots: ['/images/screenshots/search-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Фильтрация задач',
				text: 'Используйте фильтры для поиска:',
				points: [
					'Нажмите для перехода в окно фильтрации',
					'Примените фильтры',
					'Нажмите “Найти” для поиска задач',
					'Нажмите "Сбросить фильтры" для сброса неактуальных фильтров',
				],
				important: ['! Нажмите "Сохранить фильтр" для сохранения фильтра'],
				screenshots: ['/images/screenshots/filters-tasks.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Сохранение фильтра',
				text: 'Для сохранения фильтра:',
				points: [
					'Введите наименование фильтра в баннере "Наименование фильтра"',
					'Нажмите "Сохранить фильтр"',
				],
				screenshots: ['/images/screenshots/tasks-projects/save-filter.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Переход на следующую страницу',
				text: 'Нажмите на номер страницы или стрелочки для перехода на другую страницу',
				screenshots: ['/images/screenshots/pagination.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Настройка количества задач, отображаемых на странице',
				text: 'Для настройки количества отображаемых на странице задач:',
				points: ['Нажмите на кнопку', 'Выберите значение из выпадающего списка'],
				screenshots: ['/images/screenshots/setting-count-page.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Создание, дублирование задач',
		LevelGroupData: [
			{
				name: 'Создание задачи',
				points: [
					'Нажмите "Создать задачу" для перехода в форму создания задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить”',
				],
				screenshots: ['/images/screenshots/create-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Массовое создание задач проектов',
				points: [
					'Нажмите на кнопку для перехода в окно массового создания задач проектов',
					'Заполните обязательные и доступные поля формы',
					'Нажмите “Создать”',
					'Нажмите "Список ошибок" в случае возникновения ошибок',
					'Исправьте перечисленные ошибки на вкладках формы',
					'Сохраните форму повторно',
				],
				screenshots: ['/images/screenshots/mass-create-task-projects.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Дублирование задач',
				text: 'Для дублирования задачи:',
				points: [
					'Нажмите "Дублировать задачу" для перехода в окно дублирования ',
					'Заполните поля формы',
					'Нажмите "Создать"',
				],
				screenshots: ['/images/screenshots/duplicate-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Выгрузка шаблона для создания записи',
				screenshots: ['/images/screenshots/tasks-projects/bulk-template.png'],
				points: [
					'Нажмите "Выберите массовое действие"',
					'Выберите массовое действие из выпадающего списка',
					'Нажмите "Скачать шаблон для импорта"',
					'Заполните шаблон',
					'Для загрузки заполненного шаблона см. пункт "Создание записи через загрузку шаблона с заполненными данными"',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Создание записи через загрузку шаблона с заполненными данными',
				screenshots: ['/images/screenshots/tasks-projects/select-template.png'],
				points: [
					'Выберите тип отчета для загрузки записей из шаблона',
					'Нажмите "Загрузить шаблон"',
					'При возникновении ошибки исправьте шаблон и попробуйте загрузить его снова',
					],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_MODERATOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Редактирование данных задачи',
		LevelGroupData: [
			{
				name: 'Массовое согласование записей задач',
				points: [
					'Нажмите на кнопку для перехода в окно массового согласования записей задач',
					'Заполните обязательные и доступные поля формы',
					'Нажмите “Применить”',
				],
				screenshots: ['/images/screenshots/bulk-coordination-task-records.png'],
				roles: [
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Массовые действия над задачами',
				text: 'Для массового выполнения действий:',
				points: [
					'Нажмите "Массовые действия"',
					'Выберите массовое действие из выпадающего списка',
					'Выберите задачи',
					'Нажмите "Применить"',
				],
				screenshots: [
					'/images/screenshots/tasks-projects/bulk-actions.png',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Редактирование задачи',
				points: [
					'Нажмите для перехода в форму и редактирования данных задачи',
					'Заполните обязательные и доступные поля формы на 1-3 вкладках',
					'Нажмите “Сохранить” ',
				],
				screenshots: ['/images/screenshots/edit-task.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Удаление задачи',
				text: 'Нажмите для удаления задачи',
				icons: ['delete-red'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
					'ROLE_MODERATOR',
				],
			},
			{
				name: 'Массовое обновление списка пользователей задачи',
				text: 'Для массового обновления списка пользователей задач',
				points: [
					'Выберите массовое действие "Обновить список пользователей задач", выберите проекты для обновления, нажмите "Применить"',
					'В модальном окне выберите пользователей из списка к добавлению/удалению из задач',
					'При добавлении пользователя вы можете выбрать одно из значений, кем в задачах является пользователь',
					'Нажмите "Применить"',
				],
				screenshots: ['/images/screenshots/mass-user-select.png', '/images/screenshots/mass-user-modal.png'],
				roles: [
					'ROLE_ADMIN',
					'ROLE_ADMIN_IS',
					'ROLE_COORDINATOR',
					'ROLE_PRODUCER',
					'ROLE_PROJECT_MANAGER',
					'ROLE_CONTENT_EDITOR',
					'ROLE_ANALYST',
					'ROLE_LAWYER',
					'ROLE_FINANCIER'
				],
			},
		],
	},
	{
		LevelGroupName: 'Комментарии к задаче',
		LevelGroupData: [
			{
				name: 'Просмотр и добавление комментариев к задаче',
				screenshots: ['/images/screenshots/comments-task.png'],
				points: [
					'Нажмите "Комментарии" для просмотра комментариев к задаче',
					'Нажмите "Добавить комментарий"',
					'Нажмите “Сохранить”',
					'Нажмите "Изменить" / "Удалить" для редактирования данных',
					'Нажмите "Сначала новые / старые" либо "Все комментарии / Мои комментарии" для сортировки комментариев',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
	{
		LevelGroupName: 'Просмотр данных задачи',
		LevelGroupData: [
			{
				name: 'Индикатор срока выполнения задачи ',
				text: 'Наведите курсор на индикатор для просмотра статуса:',
				points: [
					{
						label: 'Серый - узнайте количество дней до окончания задачи',
						icon: 'light-gray'
					},
					{
						label: 'Красный - срок задачи просрочен',
						icon: 'light-red'
					}
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр дополнительных данных по задаче',
				text: 'Наведите курсор на шестеренку для просмотра данных по задаче',
				screenshots: ['/images/screenshots/setting.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Просмотр данных о количестве записей задач',
				points: ['Наведите курсор на количество записей', 'Ознакомьтесь со статусами записей в появившейся подсказке'],
				screenshots: ['/images/screenshots/count-records.png'],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
			{
				name: 'Индикатор даты выполнения задачи',
				text: 'Ознакомьтесь с датой выполнения задачи:',
				screenshots: ['/images/screenshots/indicator-date.png'],
				points: [
					'Зеленого цвета - срок задачи не просрочен',
					'Желтого цвета - срок задачи истекает сегодня',
					'Красного цвета - срок задачи просрочен',
				],
				roles: [
					'ROLE_ADMIN_IS',
					'ROLE_ADMIN',
					'ROLE_CONTRACTOR',
					'ROLE_PRODUCER',
					'ROLE_COORDINATOR',
					'ROLE_ANALYST',
					'ROLE_FINANCIER',
					'ROLE_LAWYER',
					'ROLE_MODERATOR',
					'ROLE_CONTENT_EDITOR',
					'ROLE_PROJECT_MANAGER',
				],
			},
		],
	},
]
