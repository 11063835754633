import { z } from "zod";
import { VoteSchema } from '../vote';
import { FileSchema } from 'shared/types/file';
import { TasksRecordStatusEnum } from '../record-status';

export const EstimateRecordsSchema = z.object({
	id: z.string(),
	costType: z.object({ id: z.string(), code: z.string(), name: z.string() }).nullable(),
	categoryDescription: z.string().nullable(),
	measurementUnit: z.string().nullable(),
	unitPrice: z.number().nullable(),
	unitsNumber: z.number().nullable(),
	totalWorkCost: z.number().nullable(),
	actualWorkCost: z.number().nullable(),
	costChangeReason: z.string().nullable(),
	contractInfo: z.string().nullable(),
	status: TasksRecordStatusEnum,
	votes: z.array(VoteSchema).nullable(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}).nullable(),
	costValidationFiles: z.array(FileSchema).optional(),
	linkedFilesCount: z.number(),
	isOriginal: z.boolean(),
	isReplaced: z.boolean(),

	task: z.object({
		deadline: z.string(),
		number: z.number(),
		id: z.string(),
		name: z.string(),
		code: z.string(),
	}).optional()
});
export type EstimateRecords = z.infer<typeof EstimateRecordsSchema>