import {axiosRequest} from "shared/config/axios";

export const getApprovedReachAmountSum = {
    key: (taskId: string) => `/tasks/reach/records/${taskId}/approved-reach-amount-sum`,
    fetch: async (taskId: string) => {
        const { data } = await axiosRequest<number>({
            url: `/tasks/reach/records/${taskId}/approved-reach-amount-sum`,
            method: 'GET',
            headers: {
                'Actioncode': 'GET_TASKS_REACH_RECORDS_TASK_ID_APPROVED_REACH_AMOUNT_SUM'
            }
        })
        return data
    },
}