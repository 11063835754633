import React from 'react'
import {SelectComponent} from 'components/formic-control'
import styles from '../styles.module.less'
import {useFormikContext} from "formik";
import {TInitValues} from "../modal-content-records";
import DropdownComponent from "./dropdown";
import {RegionLib} from 'entities/region'
import WhetherTheUser from "../../../components/whetherTheUser";

const Regions = () => {
	const { values } = useFormikContext<TInitValues>()

	const {data: regionsOptions} = RegionLib.useGetRegionsOptions()

	return (
		<>
			<div className={styles.item}>
				<span className={styles.label}>О каком регионе контент</span>
				<div className={styles.field}>
					<SelectComponent
						disabled={values.disabled}
						multi={true}
						options={regionsOptions}
						name={'regionsAbout'}
						placeholder={'Выберите регион'}
						maxTagCount={3}
						dropdownRender={<DropdownComponent name={'regionsAbout'} options={regionsOptions}/>}
					/>
				</div>
			</div>
			<WhetherTheUser role={['ROLE_ADMIN_IS', 'ROLE_ADMIN']} isCan={true}>
				<div className={styles.item}>
					<span className={styles.label}>Для какого региона контент</span>
					<div className={styles.field}>
						<SelectComponent
							disabled={values.disabled}
							multi={true}
							options={regionsOptions}
							name={'regionsFor'}
							placeholder={'Выберите регион'}
							maxTagCount={2}
							dropdownRender={<DropdownComponent name={'regionsFor'} options={regionsOptions}/>}
						/>
					</div>
				</div>
			</WhetherTheUser>

		</>
	)
}

export default Regions
