import React, {FC} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import styles from './styles.module.less'
import {Form, Formik, FormikProps} from 'formik'
import {Button, Spin} from 'antd'
import {ModalEvent} from 'shared/ui/modal'
import {
	ContentFormatType,
	ContentTags,
	DateComponent,
	MaterialType,
	NameContent,
	Regions,
	SubmissionForm,
} from './items'
//models
import {TypeContent} from 'models/content'
import {IOptions} from 'models/feels'
import {TTaskRecord} from 'models/task/task-content'
//shared
import Banner from 'shared/ui/banner'
import {useErrorNotification} from 'shared/lib/error-notification'
import replaceQuotesInString from 'shared/lib/replaceQuotesInString'
import env from "shared/config/env";
//store
import {NotificationOpen} from 'store/notification/actions'
import {GetContentStartAction} from 'store/content/content/actions'
import {GetRecordStartAction} from "store/tasks/actions/content";
//others
import TasksContentService from 'services/tasksContentService'
import {validation} from './validation'
import {transformArrayIdNameForValue} from 'utils/transformObjectFeels'
import {Moment} from 'moment/moment'
import BannerComponent from './components/banner'
import {selectRole, selectUser} from "store/auth/selectors";
import {getMaterialTypeValue} from "./utils";
import {TextAreaQuill} from "components/formic-control";
import ViewFilesButton from 'features/files-uploader/ui/view-files-button';
import SockJsClient from "react-stomp";

export type TInitValues = {
	date: string | Date | Moment
	isMasterFile: boolean
	name: string
	description: string
	submissionForms: IOptions[]
	contentFormatType: IOptions | null
	nameFile: string
	regionsFor: IOptions[]
	regionsAbout: IOptions[]
	contentTags: string[]
	isContent: boolean
	isAccountedContent: boolean
	disabled: boolean
	disabledForRoles: boolean
	materialType?: IOptions
}

type TProps = {
	el: TypeContent
}
const ModalContentRecords: FC<TProps> = ({ el }) => {
	const put = useDispatch()
	const errorFunction = useErrorNotification('Не удалось обновить')
	const user = useSelector(selectUser)
	const role = useSelector(selectRole)
	const { data: record } = useQuery({
		queryKey: [TasksContentService.getTaskRecordKey(el.id)],
		queryFn: async () => {
			const { data } = await TasksContentService.getTaskRecord(el.id)
			return data
		},
	})

	const { mutate: sendVote, isLoading: isLoadingVote } = useMutation({
		mutationFn: () =>
			TasksContentService.voteTaskRecord({
				vote: {
					comment: null,
					isApproved: true,
					recordId: record?.id || '',
					isInterimApproved: null
				},
				files: [],
			}),
		onSuccess: () => {
			put(GetContentStartAction())
			put(NotificationOpen('success', 'Данные обновлены'))
			ModalEvent.close()
		},
		onError: errorFunction,
	})
	const isReady = record?.task?.status?.code === 'READY'
	const isReadyForCustomerArchival = record?.status === 'READY_FOR_CUSTOMER_VERIFICATION' && record?.task?.archival

	const close = () => {
		put(GetContentStartAction())
		ModalEvent.close()
	}
	const { mutate, isLoading } = useMutation({
		mutationFn: (data: TTaskRecord) => TasksContentService.updateTaskRecord(el.id, data),
		onSuccess: () => {
			if (isReadyForCustomerArchival) sendVote()
			close()
			put(NotificationOpen('success', 'Данные обновлены'))
		},
		onError: errorFunction,
	})

	const initValues: TInitValues = {
		date: record?.dateTime ? record?.dateTime : '',
		isMasterFile: !!record?.isMasterFile,
		name: record?.name || '',
		description: record?.description || '',
		contentFormatType: record?.contentFormatType
			? { value: record.contentFormatType.id, label: record.contentFormatType.name }
			: null,
		submissionForms: record?.submissionForms.length ? transformArrayIdNameForValue(record.submissionForms) : [],
		nameFile: record?.file?.name || '',
		regionsFor: record?.regionForSet.length ? transformArrayIdNameForValue(record.regionForSet) : [],
		regionsAbout: record?.regionAboutSet.length ? transformArrayIdNameForValue(record.regionAboutSet) : [],
		contentTags: record?.contentTags.length ?record.contentTags.map(el => el.id) : [],
		isContent: !!record?.isContent ,
		isAccountedContent: !!record?.isAccountedContent,
		materialType: getMaterialTypeValue({isContent: !!record?.isContent, isAccountedContent: !!record?.isAccountedContent}),
		disabled: isReady,
		disabledForRoles: role !== 'ROLE_ADMIN' && role !== 'ROLE_ADMIN_IS' && role !== 'ROLE_MODERATOR'
	}

	const onSubmit = (data: TInitValues) => {
		const values: TTaskRecord = {
			dateTime: data.date ? data.date : '',
			isMasterFile: true,
			name: data.name,
			description: replaceQuotesInString(data.description) || null,
			submissionFormIds: data.submissionForms?.length ? data.submissionForms.map((it) => it.value) : [],
			contentFormatTypeId: data.contentFormatType?.value ? data.contentFormatType?.value : '',
			contentTagIds: data.contentTags.length ? data.contentTags : [],
			regionForIds: data.regionsFor.length ? data.regionsFor.map((it) => it.value) : [],
			regionAboutIds: data.regionsAbout.length ? data.regionsAbout.map((it) => it.value) : [],
			isContent: data?.isContent,
			isAccountedContent: data?.isAccountedContent,
		}
		mutate(values)
	}
	const onMessageReceived = (msg: {id: string,status: string,entityId: string,entityType: string}) => {
		if (msg.entityId && msg?.status === 'CREATED') {
			put(GetRecordStartAction(msg.entityId))
		}
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initValues}
			onSubmit={onSubmit}
			validationSchema={validation}
			validateOnMount={true}
		>
			{(formik: FormikProps<any>) => {
				return (
					<>
						<SockJsClient
							url={`${env.API_ENDPOINT}/secured/ws/connect`}
							topics={[`/user/secured/file-preview/new-status`]}
							headers={{
								'user-name': user?.id,
							}}
							onMessage={onMessageReceived}
							debug={true}
						/>
						<Spin spinning={isLoading || isLoadingVote}>
							<Form className={styles.container}>
								<div className={styles.header}>
									<span className={styles.title}>Редактирование единиц контента проекта</span>
								</div>
								<hr />
								{(isReady) && (
									<Banner>
										<BannerComponent record={record} el={el} />
									</Banner>
								)}
								{record && (
									<ViewFilesButton
										isEdit={true}
										entityType={'CONTENT_RECORD'}
										linkedFilesCount={record?.files?.length ?? 0}
										recordId={record.id}
									/>
								)}

								<DateComponent />
								<MaterialType />
								<NameContent />
								<TextAreaQuill
									label={'Описание единицы контента'}
									name={'description'}
									placeholder="Описание контента"
								/>
								<ContentFormatType />
								<SubmissionForm />
								<ContentTags projectCreateDate={record?.projectCreateDate}/>
								<Regions />

								<div className={styles.buttons}>
									<Button type={'default'} onClick={close} disabled={isReady}>
										Отменить
									</Button>
									<Button
										htmlType={'submit'}
										disabled={!formik.isValid || isReady}
										type={'primary'}
									>
										{isReadyForCustomerArchival ? 'Сохранить и согласовать запись' : 'Сохранить'}
									</Button>
								</div>
							</Form>
						</Spin>
					</>
				)
			}}
		</Formik>
	)
}
export default ModalContentRecords

