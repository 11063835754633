import React from 'react';
import {Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SearchContentProjectModel} from 'features/search-project-content/index';
import {TagLib} from 'entities/tags'

const { Option} = Select;

export const TagsContent = () => {
    const put = useDispatch();
    const {typeContent} = useSelector(SearchContentProjectModel.selectors.main);

    const thematics = useSelector(SearchContentProjectModel.selectors.thematics);
    const contentFilterLvlTagIds = thematics[typeContent].contentTagIds;

    const {data, isLoading} = TagLib.useGetContentProjectTagsOptions()

    function onSelectChange(value: string[]) {
        put(SearchContentProjectModel.actions.setThematics(typeContent, {
            contentTagIds: value
        }))
    }

    return (
        <div className="item">
            <div className="label">Теги единиц контента проекта</div>
            <div className="form-group">
                <Select
                    loading={isLoading}
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    mode={"multiple"}
                    maxTagCount={"responsive"}
                    value={contentFilterLvlTagIds as string[]}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {data?.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
};
