import {Id_Name_Code} from "../types/common";
import {axiosRequest} from "../config/axios";

/**
 * Получение всех разделов модуля контента
 */
export const getContentModuleSection = {
    key: '/content-module-section',
    fetch: async (): Promise<Id_Name_Code[]> => {
        const { data } = await axiosRequest<Id_Name_Code[]>({
            method: 'GET',
            url: '/content-module-section',
        })
        return data
    },
}
