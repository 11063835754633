import React, {useEffect, useMemo} from 'react';
import stylesModal from './styles.module.less'
import {useDispatch, useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {selectProjectsStatus} from "store/projects/selectors";
import {TRole} from "models/user";
import {TInfoProject} from "../form-container";
import {ProjectFormDto, validationSchema} from "../../lib";
import {Button, Modal, Spin, Tooltip} from 'antd';
import {NotificationOpen} from "store/notification/actions";
import {UpdateProjectStartAction} from "store/projects/actions";
import {edit} from "../../lib/edit";
import {Form, Formik, FormikProps} from 'formik';
import styles from "../form.module.less";
import classNames from "classnames";
import {StepFifth} from '../steps';
import {ModalOpenAction} from "store/modals/actions";
import {ModalEvent} from 'shared/ui/modal';


const FormContractor = () => {
    const put = useDispatch()
    const role = useSelector(selectRole);
    const {isLoaded, isLoading, project} = useSelector(selectProjectsStatus)

    useEffect(() => {
        if (isLoaded) {
            ModalEvent.close();
        }
    }, [isLoaded]);


    const info: TInfoProject = useMemo(() => {
        if (!!project) {
            return {
                status: project.status.code,
                role: role as TRole,
                tasksExist: project?.tasksExist
            }
        }
        else return null;
    }, [project, role]);

    const initialValue: ProjectFormDto = useMemo(() => {
        const data = new ProjectFormDto(project);
        if (role) {
            data.stepFirst
              .validate(info)
              .validateStatus(info)
            data.stepSecond
              .validateProjectType(data.stepFirst.projectType.value?.code)
              .validateLevelTags(data.stepFirst.status.value?.code)
              .validate(info)
              .validateContentTaggingRequired(role)
            data.stepThird
              .validate(info)
              .validateProjectType(data.stepFirst.projectType.value?.code)
            data.stepFourth
              .validate(info)
            data.stepFifth
              .validate(info)
              .validateTargetAudienceAgeTo()
              .validateNationalTags()
        } else {
            data.stepFirst.validateStatus(null)
            data.stepSecond.validateContentTaggingRequired(role)
            data.stepFifth.validateTargetAudienceAgeTo()
              .validateNationalTags()
        }
        return data
    }, [info, project, role]);

    function onSubmit(value: ProjectFormDto, formikProps: any) {
        if (formikProps.isValid && project) {
            put(NotificationOpen('info', 'Проект обновляется'))
            put(UpdateProjectStartAction(project.id, edit(value)))
        }
    }
    const handlerClose = () => {
        ModalEvent.close();
    }

    const showError = (error: any) => () => {
        const stringErrors = JSON.stringify(error)
        const arrErrors = stringErrors
          .replace(/([^а-яёА-ЯЁ«»[0-9])/ig, ' ')
          .replace(/  +/g, '; ')
          .split('; ')

        Modal.error({
            title: 'Форма заполнена не валидно',
            maskClosable: true,
            okText: 'Закрыть',
            className: 'modal-duplication-form-error',
            content: (
              <ul>
                  {arrErrors.map((el: string, i) => {
                      if (el.length > 2) {
                          return (
                            <li key={el + i}>{el}</li>
                          )
                      } else return ''
                  })}
              </ul>
            ),
        });
    }

    return (
      <Spin spinning={isLoading}>
          <div className={stylesModal.container}>
              <h2 className={stylesModal.title}>Редактирование проекта</h2>
              <Formik
                enableReinitialize
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount={true}
              >
                  {(formik: FormikProps<ProjectFormDto>) => {
                      const status = formik.values.stepFirst.status.value?.code;
                      return (
                        <Form className={styles.form}>
                            <StepFifth/>
                            <div className={styles.buttons}>
                                <Button
                                  type="default"
                                  onClick={(_e) => {
                                      put(ModalOpenAction('form-no-save',{
                                          reset: formik.handleReset,
                                          handlerClose: handlerClose
                                      }))
                                  }}
                                >
                                    Отменить
                                </Button>
                                {!formik.isValid && (
                                  <Button type={"default"} onClick={showError(formik.errors)}>
                                      Список ошибок
                                  </Button>
                                )}
                                <Tooltip
                                  title={
                                      !formik.isValid ?
                                        `Необходимо корректно заполнить все обязательные поля. Для подробностей нажмите кнопку "Список ошибок"` :
                                        ''
                                  }
                                >
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      className={classNames({
                                          unionSave : formik.isValid &&
                                            status !== 'SUSPENDED' &&
                                            status !== 'COMPLETED'
                                      })}
                                      onClick={() => onSubmit(formik.values, formik)}
                                      disabled={
                                          status
                                            ? (status === 'SUSPENDED' || status === 'COMPLETED' || !formik.isValid)
                                            : !formik.isValid
                                      }
                                    >
                                        Обновить
                                    </Button>
                                </Tooltip>
                            </div>
                        </Form>
                      )
                  }}
              </Formik>
          </div>
      </Spin>
    )
};

export default FormContractor;
