import React from "react";
import {Checkbox, Col, Row, Switch} from "antd";
import {useFormikContext} from "formik";
import {useSelector} from "react-redux";

import {RadioButtonsComponent, TextAreaQuill} from "../../../formic-control";
import TaskFormContentItem from "../feels/task-form-contentItem";
import {selectTasksTypeReportType} from "store/tasks/selectors";
import {TaskFormDto} from "../domain/dto/task-form.dto";
import {IOptions} from "models/feels";
import Banner from "shared/ui/banner";
import {CheckboxChangeEvent} from "antd/es/checkbox";

function filingName(key: string): string {
    return `stepSecond.${key}.value`
}

const defaultMaterialTypeOptions: IOptions[] = [
    {label: 'Учитываемый контент', value: 'accountedContent'},
    {label: 'Неучитываемый контент', value: 'nonAccountedContent'},
    {label: 'Рабочие материалы', value: 'workingMaterial'}
]
const StepSecond = () => {
    const { values, setFieldValue } = useFormikContext<TaskFormDto>()
    const TASK_TYPE_REPORT = useSelector(selectTasksTypeReportType)
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        const value = e.target.checked
        setFieldValue(`stepSecond.materialTypeContent.isDisabled`, !value)
    }
    const onChangeSwitch = (name: string)=> (checked: boolean ) => {
        setFieldValue(name, checked)
    }

    const formStepFirst = values.stepFirst;
    const formStepSecond = values.stepSecond;
    return (
        <>
            <Row className="taskForm-form__item">
                <Col md={4} xs={24} className="label">
                    Описание задачи
                </Col>
                <Col md={20} xs={24}>
                    <TextAreaQuill
                        placeholder="Введите описание задачи"
                        name={filingName('description')}
                        disabled={formStepSecond.description?.isDisabled}
                        limit={5000}
                    />
                </Col>
            </Row>
            {!!(formStepFirst.typeProject.value && !formStepFirst.typeProject.isReport) && (
                <>
                    <Row className="taskForm-form__item">
                        <Col md={4} xs={24} className="label">
                            Учитываемый контент
                        </Col>
                        <Col md={20} xs={24}>
                            <TaskFormContentItem
                                isContent={true}
                                isAccountedContent={true}
                                name={filingName("contents")}
                                shortName={"contents"}
                                project={formStepFirst.project.value}
                                disabled={formStepSecond.contents?.isDisabled}
                            />
                        </Col>
                    </Row>
                    <Row className="taskForm-form__item">
                        <Col md={4} xs={24} className="label">
                            Неучитываемый контент
                        </Col>
                        <Col md={20} xs={24}>
                            <TaskFormContentItem
                                isContent={true}
                                isAccountedContent={false}
                                name={filingName("contents")}
                                shortName={"contents"}
                                project={formStepFirst.project.value}
                                disabled={formStepSecond.contents?.isDisabled}
                            />
                        </Col>
                    </Row>
                    <Row className="taskForm-form__item">
                        <Col md={4} xs={24} className="label">
                            Рабочие материалы
                        </Col>
                        <Col md={20} xs={24}>
                            <TaskFormContentItem
                                labelName='workingMaterial'
                                name={filingName("contents")}
                                shortName={"contents"}
                                project={formStepFirst.project.value}
                                disabled={formStepSecond.contents?.isDisabled}
                            />
                        </Col>
                    </Row>
                    <Row className="taskForm-form__item">
                        <Col md={4} xs={24} className="label">
                            Автозаполнение параметров учета контента
                        </Col>
                        <Col md={1} xs={1}/>
                        <Col md={19} xs={24}>
                            {values.isEdit && (
                                <Row>
                                    <Col md={24} xs={24}>
                                        <Banner>
                                            <span className="taskForm-form__item__banner">
                                                При изменении значений параметров "Установить тип материала" и "Установить тип учета материала"
                                                будет вызвано принудительное изменение значений параметров записей этой задачи.
                                            </span>
                                            <div className="form-group">
                                                <Checkbox
                                                    disabled={!formStepSecond.materialTypeContent?.isDisabled}
                                                    checked={!formStepSecond.materialTypeContent?.isDisabled}
                                                    onChange={onChangeCheckbox}
                                                >
                                                    Согласен с правилами изменения значений.
                                                </Checkbox>
                                            </div>
                                        </Banner>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={15} xs={24} className="taskForm-form__isContent">
                                    <RadioButtonsComponent
                                        disabled={formStepSecond.materialTypeContent?.isDisabled}
                                        options={defaultMaterialTypeOptions}
                                        name={filingName('materialTypeContent')}
                                    />
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </>

            ) }
            {formStepFirst.typeProject.value &&
            formStepFirst.typeProject.isReport &&
                formStepFirst.report?.code === 'CONTENT_POSTING_REPORT' && (
                    <>
                        <Row className="taskForm-form__item">
                            <Col md={4} xs={24} className="label">
                                Наличие скриншотов<sup>*</sup>
                            </Col>
                            <Col md={8} xs={24}>
                                <RadioButtonsComponent
                                    name={filingName('postingRecordsScreenshotRequired')}
                                    options={[
                                        { label: 'Есть', value: 'true' },
                                        { label: 'Нет', value: 'false' },
                                    ]}
                                    disabled={formStepSecond.postingRecordsScreenshotRequired?.isDisabled}
                                />
                            </Col>
                        </Row>
                        <Row className="taskForm-form__item">
                            <Col md={4} xs={24} className="label">
                                Наличие необходимости добавления суммы и даты фиксации реакций для записей задач на фиксацию охватов
                            </Col>
                            <Col md={20} xs={24} className="descriptionContent">
                                <Switch
                                    onChange={onChangeSwitch(filingName('reachRecordsReactionAmountRequired'))}
                                    disabled={formStepSecond.reachRecordsReactionAmountRequired?.isDisabled}
                                    checked={formStepSecond.reachRecordsReactionAmountRequired.value}
                                />
                                <div className="descriptionContent__wrapper">
                                    <div className="descriptionContent__text">
                                        В записях задачи {!formStepSecond.reachRecordsReactionAmountRequired.value && 'не '} требуется по умолчанию запрашивать необходимость указывать размещениям сумму реакций и дату фиксации реакций при будущем сборе охватов
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}

            {formStepFirst.typeProject.value &&
                formStepFirst.typeProject.isReport &&
                formStepFirst.report?.code === 'CONTENT_REACH_REPORT' && (
                <>
                    <Row className="taskForm-form__item">
                        <Col md={4} xs={24} className="label">
                            Наличие скриншотов на фиксацию охватов<sup>*</sup>
                        </Col>
                        <Col md={20} xs={24} className="descriptionContent">
                            <Switch
                                onChange={onChangeSwitch(filingName('reachRecordsScreenshotRequired'))}
                                disabled={formStepSecond.reachRecordsScreenshotRequired?.isDisabled}
                                checked={formStepSecond.reachRecordsScreenshotRequired.value}/>
                            <div className="descriptionContent__wrapper">
                                <div className="descriptionContent__text">
                                    В записях задачи {!formStepSecond.reachRecordsScreenshotRequired.value && 'не '} требуется указывать скриншот на фиксацию охватов
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="taskForm-form__item">
                        <Col md={4} xs={24} className="label">
                            Наличие скриншотов <br/> cчётчика на фиксацию охватов<sup>*</sup>
                        </Col>
                        <Col md={20} xs={24} className="descriptionContent">
                            <Switch
                                onChange={onChangeSwitch(filingName('reachRecordsCounterScreenshotRequired'))}
                                disabled={formStepSecond.reachRecordsCounterScreenshotRequired?.isDisabled}
                                checked={formStepSecond.reachRecordsCounterScreenshotRequired.value}/>
                            <div className="descriptionContent__wrapper">
                                <div className="descriptionContent__text">
                                    В записях задачи {!formStepSecond.reachRecordsCounterScreenshotRequired.value && 'не '} требуется указывать скриншот cчётчика
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            {formStepFirst.typeProject.value && formStepFirst.typeProject.value !== TASK_TYPE_REPORT?.id && (
                <Row className="taskForm-form__item">
                    <Col md={4} xs={24} className="label">
                        Видимость описания ед.контента<br/> для исполнителя
                    </Col>
                    <Col md={20} xs={24} className="descriptionContent">
                        <Switch
                            onChange={onChangeSwitch(filingName('contentRecordDescriptionContractorVisible'))}
                            disabled={formStepSecond.contentRecordDescriptionContractorVisible?.isDisabled}
                            checked={formStepSecond.contentRecordDescriptionContractorVisible.value}/>
                        <div className="descriptionContent__wrapper">
                            <div className="descriptionContent__text">
                                В записях задачи исполнитель {!formStepSecond.contentRecordDescriptionContractorVisible.value && 'не '} будет видеть поле с описанием единиц контента
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {!formStepFirst.typeProject.isReport && values.isEdit && values.stepFirst.project.value?.key === 'old' &&  ( //в key записываем новый или старый проект
                <Row className="taskForm-form__item">
                    <Col md={4} xs={24} className="label">
                        Видимость тематического тегирования <br/> ед.контента для исполнителя
                    </Col>
                    <Col md={20} xs={24} className="descriptionContent">
                        <Switch
                            onChange={onChangeSwitch(filingName('contentTagsContractorVisible'))}
                            disabled={formStepSecond.contentTagsContractorVisible?.isDisabled}
                            checked={formStepSecond.contentTagsContractorVisible.value}/>
                        <div className="descriptionContent__wrapper">
                            <div className="descriptionContent__text">
                                В записях задачи исполнитель {!formStepSecond.contentTagsContractorVisible.value && 'не '} будет видeть поля с тематическими тегами единиц контента
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {!formStepFirst.typeProject.isReport && (
                <Row className="taskForm-form__item">
                    <Col md={4} xs={24} className="label">
                        Видимость регионального тегирования <br/> ед.контента для исполнителя
                    </Col>
                    <Col md={20} xs={24} className="descriptionContent">
                        <Switch
                            onChange={onChangeSwitch(filingName('regionTagsContractorVisible'))}
                            disabled={formStepSecond.regionTagsContractorVisible?.isDisabled}
                            checked={formStepSecond.regionTagsContractorVisible.value}/>
                        <div className="descriptionContent__wrapper">
                            <div className="descriptionContent__text">
                                В записях задачи исполнитель {!formStepSecond.regionTagsContractorVisible.value && 'не '} будет видeть поля с региональными тегами единиц контента
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {!formStepFirst.typeProject.isReport && (
                <Row className="taskForm-form__item">
                    <Col md={4} xs={24} className="label">
                        Наличие тайм-кодов <br/> ед. видеоконтента
                    </Col>
                    <Col md={20} xs={24} className="descriptionContent">
                        <Switch
                            onChange={onChangeSwitch(filingName('contentTimeCodeRequired'))}
                            disabled={formStepSecond.contentTimeCodeRequired?.isDisabled}
                            checked={formStepSecond.contentTimeCodeRequired.value}/>
                        <div className="descriptionContent__wrapper">
                            <div className="descriptionContent__text">
                                В записях задачи {!formStepSecond.contentTimeCodeRequired.value && 'не '}
                                требуется указывать <br/> тайм-коды видеоконтента
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default React.memo(StepSecond)
