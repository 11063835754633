import {useQuery, UseQueryResult} from "react-query";
import {useMemo} from "react";
import {getOrganizationsWithContractorsShort} from "../api/organization";
import {OrganizationWithContractorsShort} from "../types/organization";
import {IOptions} from "models/feels";

type onSuccessType = (data: OrganizationWithContractorsShort[]) => void
export function useGetOrganizationsWithContractors(onSuccess?: onSuccessType): UseQueryResult<OrganizationWithContractorsShort[]> {
    return useQuery({
        queryKey: [getOrganizationsWithContractorsShort.key],
        queryFn: () => getOrganizationsWithContractorsShort.fetch(),
        onSuccess,
    });
}
export function useGetOrganizationsWithContractorsOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetOrganizationsWithContractors(onSuccess);
    const options = useMemo(() => data.map((el) => {
        return {label: el.name, value: el.id, code: `${el.name} ${el.inn} ${el.ogrnOgrnip}`} as IOptions
    }), [data]);
    return { data: options, ...rest };
}
