import React, {FC, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import styles from './styles.module.less'
//antd
import {Button, Col, Row, Select, Table, Tooltip} from 'antd'
import {ColumnsType} from 'antd/es/table'
//hooks
import {useBulkActions, useRecords} from '../lib'
import useTasksFields from 'hooks/tasksField'
//components
import NameCenterCell from 'components/table-cells/name-center-cell'
import WhetherTheUser from 'components/whetherTheUser'
import NameCellWithCount from 'components/table-cells/name-cell-with-count/name-cell-with-count'
//store
import {CreateFileArchiveStartAction} from 'store/tasks/actions/content'
import {selectDownloadRights, selectUser} from 'store/auth/selectors'
//models
import {TTaskRecordsFilterData} from 'models/task/task-list'
import {TTasksTypesFields} from 'models/task'
import {TaskTypes} from 'entities/task'
//other
import DownloadRights from './download-rights'
import {CONTENT} from 'routing/names'
import {UserTypes} from 'entities/user'
import {useGetBulkActions} from "shared/lib/hooks/use-get-bulk-actions";
import ViewFilesButton from 'features/files-uploader/ui/view-files-button';
import {ModalEvent} from 'shared/ui/modal';
import getColumnsWidth from "../../../shared/lib/table/get-columns-width";
import classNames from "classnames";

type ModalPostingContentProps = {
	projectId?: string
}
export const ContentRecords: FC<ModalPostingContentProps> = ({ projectId }) => {
	const put = useDispatch()
	const user = useSelector(selectUser)

	const downloadRights = useSelector(selectDownloadRights)
	const { recordsStatuses } = useTasksFields([TTasksTypesFields.recordsStatuses])
	const { getRecords, filter, loading, records, handleTable } = useRecords()
	const {bulkActionsOptions, isLoading} = useGetBulkActions('MODAL_CONTENT_RECORDS')


	const isClientAgentViewer = user?.role === 'ROLE_AGENT' || user?.role === 'ROLE_CLIENT' || user?.role === 'ROLE_VIEWER'

	const { rowSelection, bulkAction, handlerBulkAction, handlerRowIds, rowsIds } = useBulkActions()
	const [valueSelect, setValueSelect] = useState()

	useEffect(() => {
		if (recordsStatuses) {
			let filterData: TTaskRecordsFilterData = {
				isMasterFile: true,
				taskRecordStatusId: recordsStatuses?.find((el) => el.code === 'APPROVED')?.value,
				projectIds: projectId ? [projectId] : []
			}
			if (isClientAgentViewer) {
				filterData = { ...filterData, isContent: true, isAccountedContent: true }
			}
			getRecords({
				page: 1,
				pageSize: bulkAction ? 3000 : 5,
				filterData,
			})
		}
	}, [recordsStatuses, projectId, bulkAction, isClientAgentViewer]) //eslint-disable-line


	const handlerBulkActions = (value: any) => {
		setValueSelect(value)
		handlerBulkAction(value)
		handlerRowIds([])
	}

	const handlerApply = () => {
		handlerBulkActions(null)
		put(CreateFileArchiveStartAction(rowsIds))
	}
	const columns: ColumnsType<TaskTypes.ContentRecords> = useMemo(() => {
		const arr: ColumnsType<TaskTypes.ContentRecords> = [
            {
                title: 'Единица контента',
                dataIndex: ['contentFormatType', 'name'],
                key: 'contentFormatType',
                width: 150,
                render: (name: string) => {
                    return NameCenterCell(name)
                },
            },
			{
				title: 'Название единицы контента',
				dataIndex: 'name',
				key: 'name',
				width: 150,
				render: (name: string) => {
					return NameCenterCell(name)
				},
			},
			{
				title: 'О каком регионе контент',
				dataIndex: 'regionAboutSet',
				key: 'regionAboutSet',
				width: 140,
				render: NameCellWithCount,
			}
		]
		if (user?.role === 'ROLE_ADMIN' || user?.role === 'ROLE_ADMIN_IS') {
			arr.push({
				title: 'Для какого региона контент',
				dataIndex: 'regionForSet',
				key: 'regionForSet',
				width: 140,
				render: NameCellWithCount,
			})
		}
		arr.push({
			title: 'Формат сдачи контента',
			dataIndex: 'submissionForms',
			key: 'submissionForms',
			width: 120,
			render: NameCellWithCount
		})
		if (user?.contentDownloadAccess) {
			arr.push({
				title: 'Скачать контент',
				dataIndex: 'file',
				key: 'file',
				width: 260,
				render: (_file: any, record) => {
					return (
						<Tooltip title={downloadRights ? 'Скачать' : 'Для скачивания подтвердите согласие'}>
							<ViewFilesButton
								entityType={'CONTENT_RECORD'}
								linkedFilesCount={downloadRights ? 1 : 0}
								recordId={record.id}
							/>
						</Tooltip>
					)
				},
			})
		}
		const roleHiddenColumns: ColumnsType<TaskTypes.ContentRecords> = [
			{
				title: 'Тип учёта',
				dataIndex: 'isAccountedContent',
				key: 'isAccountedContent',
				width: 150,
				render: (isAccountedContent: boolean | null) => {
					return NameCenterCell(isAccountedContent ? 'Учитываемый' : 'Неучитываемый')
				},
			},
			{
				title: 'Тип материала',
				dataIndex: 'isContent',
				key: 'isContent',
				width: 150,
				render: (isContent: boolean | null) => {
					return NameCenterCell(isContent === null ? '__' : isContent ? 'Контент' : ' Рабочий материал')
				},
			},
			{
				title: 'Тип файла',
				dataIndex: 'isMasterFile',
				key: 'isMasterFile',
				width: 150,
				render: (isMasterFile: boolean | null) => {
					return NameCenterCell(isMasterFile === null ? '__' : isMasterFile ? 'Мастер' : 'Предмастер')
				},
			},
		]

		return isClientAgentViewer ? arr : [...roleHiddenColumns, ...arr]
		// eslint-disable-next-line
	}, [downloadRights, user, isClientAgentViewer])

	return (
		<div className={styles.root}>
			{user?.contentDownloadAccess ? <DownloadRights /> : null}
			<Row>
				<Col md={8} sm={24} xs={24}>
					{!!bulkActionsOptions?.length && (
						<div className={classNames('form-group',styles.bulk)}>
							<Select
								loading={isLoading}
								allowClear={true}
								options={bulkActionsOptions}
								className={styles.select}
								placeholder={'Массовые действия'}
								onChange={handlerBulkActions}
								value={valueSelect}
							/>
						</div>
					)}
				</Col>
				<Col md={4} sm={24} xs={24}>
					{downloadRights && rowsIds.length > 0 ? (
						<div className="form-group">
							<Button type={'primary'} onClick={handlerApply} disabled={false}>
								Применить
							</Button>
						</div>
					) : null}
				</Col>
				<WhetherTheUser role={['ROLE_CONTRACTOR', 'ROLE_VIEWER']} isCan={false}>
					<>
						<Col md={7}/>
						{user?.contentViewAccess ? (
							<Col md={5} sm={24} xs={24}>
								<div className="form-group">
									<Button type="primary" ghost onClick={() => ModalEvent.close()}>
										<Link to={`${CONTENT.CONTENT_INFO}?id=${projectId}`}>Посмотреть контент</Link>
									</Button>
								</div>
							</Col>
						) : null}
					</>
				</WhetherTheUser>
			</Row>

			<Table
				rowSelection={bulkAction ? { type: 'checkbox', ...rowSelection } : undefined}
				loading={loading}
				className={classNames('table', styles.table)}
				sticky
				scroll={{x: getColumnsWidth(columns)}}
				columns={columns}
				dataSource={records ? (bulkAction ? availableRecords(records, user?.role) : records) : []}
				pagination={{
					current: filter ? filter.page : 1,
					pageSize: filter ? filter.pageSize : 5,
					total: filter ? filter.totalCount : 1,
					position: ['bottomCenter'],
					showSizeChanger: true,
					pageSizeOptions: ['5', '10', '20', '50', '100'],
				}}
				rowKey="id"
				onChange={handleTable}
			/>
		</div>
	)
}

function availableRecords(records: TaskTypes.ContentRecords[], role?: UserTypes.RoleName) {
	if (!role) return []
	const isCheck = ['ROLE_CLIENT', 'ROLE_AGENT', 'ROLE_VIEWER'].includes(role)
	return records.filter((it: any) => it.file?.id && (isCheck ? it.status === 'APPROVED' : true))
}
