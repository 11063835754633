import React, {useMemo} from 'react';
import styles from './styles.module.less'

import {useSelector} from "react-redux";
//antd
import {Spin} from "antd";
import Checkbox from "antd/es/checkbox";
//models
import {TRole} from "models/user";
//components
import ReloadList from "components/forms/components/reload-list/reload-list";
import {CheckboxComponent, SelectComponent} from 'components/formic-control';
//others
import {useFormikContext} from "formik";
import {selectRole} from "store/auth/selectors";
import {ProjectFormDto} from "../../../lib";
import classNames from "classnames";
import {TagApi, TagLib} from "entities/tags";
import {Query} from 'processes/query'

function filingName(key: string): string {
    return `stepSecond.${key}.value`
}

function useGetsData(values: ProjectFormDto, role: TRole | undefined) {
    const form = values.stepSecond;
    const status = values.stepFirst.status.value;
    role && role !== 'ROLE_CONTENT_EDITOR' && form.validateLevelTags(status?.code);

    const projectTags = TagLib.useGetProjectTagsOptions({isCollection: false, isTheme: false, isAssignment: false, isForNationalProject: false})
    const projectThemeTags = TagLib.useGetProjectTagsOptions({isTheme: true})


    const tagsAssignmentFirstLevel = TagLib.useGetProjectTagsOptions({level: 1, isAssignment: true})
    const tagsAssignmentSecondLevel = TagLib.useGetProjectTagsOptions({level: 2, isAssignment: true})

    const tagsCollectionFirstLevel = TagLib.useGetProjectTagsOptions({level: 1, isCollection: true})
    const tagsCollectionSecondLevel = TagLib.useGetProjectTagsOptions({level: 2, isCollection: true})

    return {
        projectTagLoading: projectTags.isLoading,
        projectThemeTagLoading: projectThemeTags.isLoading,
        firstAssignmentLoading: tagsAssignmentFirstLevel.isLoading,
        secondAssignmentLoading: tagsAssignmentSecondLevel.isLoading,
        firstCollectionLoading: tagsCollectionFirstLevel.isLoading,
        secondCollectionLoading: tagsCollectionSecondLevel.isLoading,
        options: {
            PROJECT_TAG: projectTags.data || [],
            PROJECT_THEME_TAG: projectThemeTags.data || [],
            FIRST_LVL_ASSIGNMENT_TAG: tagsAssignmentFirstLevel.data || [],
            SECOND_LVL_ASSIGNMENT_TAG: tagsAssignmentSecondLevel.data || [],
            FIRST_LVL_COLLECTION_TAG: tagsCollectionFirstLevel.data || [],
            SECOND_LVL_COLLECTION_TAG: tagsCollectionSecondLevel.data || [],
        }
    }
}

const TagsField = () => {
    const role = useSelector(selectRole)
    const {values,setFieldValue} = useFormikContext<ProjectFormDto>();
    const form = values.stepSecond;
    const status = values.stepFirst.status.value;
    const {
        projectTagLoading,
        projectThemeTagLoading,
        firstAssignmentLoading,
        secondAssignmentLoading,
        firstCollectionLoading,
        secondCollectionLoading,
        options
    } = useGetsData(values, role)

    const projectOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.PROJECT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const projectThemeOptionsDisabled = useMemo(() => {
        const selected = values.stepSecond.projectThemeTags?.value ?? []
        const isDraftOrActual = !status || status?.code === 'DRAFT' || status?.code === 'ACTUAL'
        const isLimitReached = selected.length >= 3

        if (!isDraftOrActual) return []

        const filtered = options?.PROJECT_THEME_TAG?.filter(option => {
            const isSelected = selected.some(tag => tag.value === option.value)
            if (isLimitReached) return !isSelected || option.hidden
            return option.hidden
        })

        return filtered?.map(opt => opt.value) ?? []
    }, [status, options, values.stepSecond.projectThemeTags])

    const firstLevelAssignmentOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.FIRST_LVL_ASSIGNMENT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);
    const secondLevelAssignmentOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.SECOND_LVL_ASSIGNMENT_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    const firstLevelCollectionOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.FIRST_LVL_COLLECTION_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);
    const secondLevelCollectionOptionsDisabled = useMemo(() => {
        if (!status || status?.code === 'DRAFT' || status?.code === 'ACTUAL') {
            return options?.SECOND_LVL_COLLECTION_TAG?.filter((el) => el.hidden).map((el) => el.value);
        }
        return [];
    }, [status, options]);

    //Options для checkbox чтобы при нажатии на "Выбрать все" не выбирались заблокированные
    const projectFilteredOptions = options?.PROJECT_TAG.filter(option => {
        return !projectOptionsDisabled.some(value => value === option.value);
    })
    const firstAssignmentFilteredOptions = options?.FIRST_LVL_ASSIGNMENT_TAG.filter(option => {
        return !firstLevelAssignmentOptionsDisabled.some(value => value === option.value);
    })
    const secondAssignmentFilteredOptions = options?.SECOND_LVL_ASSIGNMENT_TAG.filter(option => {
        return !secondLevelAssignmentOptionsDisabled.some(value => value === option.value);
    })

    const firstCollectionFilteredOptions = options?.FIRST_LVL_COLLECTION_TAG.filter(option => {
        return !firstLevelCollectionOptionsDisabled.some(value => value === option.value);
    })
    const secondCollectionFilteredOptions = options?.SECOND_LVL_COLLECTION_TAG.filter(option => {
        return !secondLevelCollectionOptionsDisabled.some(value => value === option.value);
    })


    const handlerUpdateProjectTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {isCollection: false, isTheme: false, isAssignment: false, isForNationalProject: false}]);
    }

    const handlerUpdateProjectThemeTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {isTheme: true}]);
    }

    const handlerUpdateFirstAssignmentTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 1, isAssignment: true}]);
    }
    const handlerUpdateSecondAssignmentTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 2, isAssignment: true}]);
    }

    const handlerUpdateFirstCollectionTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 1, isCollection: true}]);
    }
    const handlerUpdateSecondCollectionTag = () => {
        Query.invalidate([TagApi.getProjectTags.key, {level: 2, isCollection: true}]);
    }


    const selectCheckboxProjectTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('projectTags'),projectFilteredOptions)
        else setFieldValue(filingName('projectTags'),[])
    }
    const selectCheckboxFirstAssignmentTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('firstLevelAssignmentTags'),firstAssignmentFilteredOptions)
        else setFieldValue(filingName('firstLevelAssignmentTags'),[])
    }
    const selectCheckboxSecondAssignmentTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('secondLevelAssignmentTags'),secondAssignmentFilteredOptions)
        else setFieldValue(filingName('secondLevelAssignmentTags'),[])
    }
    const selectCheckboxFirstCollectionTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('firstLevelCollectionTags'),firstCollectionFilteredOptions)
        else setFieldValue(filingName('firstLevelCollectionTags'),[])
    }
    const selectCheckboxSecondCollectionTag = (e: any)=> {
        const checked: boolean = e.target.checked;
        if(checked) setFieldValue(filingName('secondLevelCollectionTags'),secondCollectionFilteredOptions)
        else setFieldValue(filingName('secondLevelCollectionTags'),[])
    }


    return (
        <>
            <div className={classNames(styles.tagsProjectTheme_label, 'label')}>
                Тематика проекта
            </div>
            <div className={styles.tagsProjectTheme}>
                <Spin spinning={projectThemeTagLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateProjectThemeTag}/>
                    <SelectComponent
                        name={filingName('projectThemeTags')}
                        placeholder={'Выберите одну или несколько тематик проекта'}
                        options={options?.PROJECT_THEME_TAG}
                        multi={true}
                        disabled={form.projectThemeTags.isDisabled}
                        disabledOptionsIds={projectThemeOptionsDisabled}
                        maxTagCount={'responsive'}
                    />
                </Spin>
            </div>
            {values.projectId && form.visibleProjectTags && (
                <>
                    <div className={classNames(styles.tagsProject_label, 'label')}>
                        Свод тематических тегов проекта
                    </div>
                    <div className={styles.tagsProject}>
                        <Spin spinning={projectTagLoading}>
                            <ReloadList className="ident" handlerUpdateList={handlerUpdateProjectTag}/>
                            <SelectComponent
                                name={filingName('projectTags')}
                                placeholder={'Выберите теги проекта'}
                                options={options?.PROJECT_TAG}
                                multi={true}
                                disabled={form.projectTags.isDisabled}
                                disabledOptionsIds={projectOptionsDisabled}
                                maxTagCount={'responsive'}
                                dropdownRender={
                                    <Checkbox
                                        className={styles.checkboxInSelect}
                                        onChange={selectCheckboxProjectTag}
                                        checked={options?.PROJECT_TAG.length ? form.projectTags?.value?.length === projectFilteredOptions?.length : false}
                                        disabled={form.projectTags.isDisabled}
                                    >
                                        <span className={styles.checkboxText}>Выбрать все</span>
                                    </Checkbox>
                                }
                            />
                        </Spin>
                    </div>
                </>
            )}
            <div className={classNames(styles.tagsAssignmentCollection_label, 'label')}>
                Свод тегов поручений и подборок
            </div>

            <div className={styles.tagsAssignment1}>
                <Spin spinning={firstAssignmentLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateFirstAssignmentTag}/>
                    <SelectComponent
                        name={filingName('firstLevelAssignmentTags')}
                        placeholder={'Выберите теги поручений 1-уровня'}
                        options={options?.FIRST_LVL_ASSIGNMENT_TAG}
                        multi={true}
                        disabled={form.firstLevelAssignmentTags.isDisabled}
                        disabledOptionsIds={firstLevelAssignmentOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxFirstAssignmentTag}
                                checked={options?.FIRST_LVL_ASSIGNMENT_TAG.length ? form.firstLevelAssignmentTags?.value?.length === firstAssignmentFilteredOptions?.length : false}
                                disabled={form.firstLevelAssignmentTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tagsAssignment2}>
                <Spin spinning={secondAssignmentLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateSecondAssignmentTag}/>
                    <SelectComponent
                        name={filingName('secondLevelAssignmentTags')}
                        placeholder={'Выберите теги поручений 2-уровня'}
                        options={options?.SECOND_LVL_ASSIGNMENT_TAG}
                        multi={true}
                        disabled={form.secondLevelAssignmentTags.isDisabled}
                        disabledOptionsIds={secondLevelAssignmentOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxSecondAssignmentTag}
                                checked={options?.SECOND_LVL_ASSIGNMENT_TAG.length ? form.secondLevelAssignmentTags?.value?.length === secondAssignmentFilteredOptions?.length : false}
                                disabled={form.secondLevelAssignmentTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tagsCollection1}>
                <Spin spinning={firstCollectionLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateFirstCollectionTag}/>
                    <SelectComponent
                        name={filingName('firstLevelCollectionTags')}
                        placeholder={'Выберите теги подборок 1-уровня'}
                        options={options?.FIRST_LVL_COLLECTION_TAG}
                        multi={true}
                        disabled={form.firstLevelCollectionTags.isDisabled}
                        disabledOptionsIds={firstLevelCollectionOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxFirstCollectionTag}
                                checked={options?.FIRST_LVL_COLLECTION_TAG.length ? form.firstLevelCollectionTags?.value?.length === firstCollectionFilteredOptions?.length : false}
                                disabled={form.firstLevelCollectionTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={styles.tagsCollection2}>
                <Spin spinning={secondCollectionLoading}>
                    <ReloadList className="ident" handlerUpdateList={handlerUpdateSecondCollectionTag}/>
                    <SelectComponent
                        name={filingName('secondLevelCollectionTags')}
                        placeholder={'Выберите теги подборок 2-уровня'}
                        options={options?.SECOND_LVL_COLLECTION_TAG}
                        multi={true}
                        disabled={form.secondLevelCollectionTags.isDisabled}
                        disabledOptionsIds={secondLevelCollectionOptionsDisabled}
                        maxTagCount={'responsive'}
                        dropdownRender={
                            <Checkbox
                                className={styles.checkboxInSelect}
                                onChange={selectCheckboxSecondCollectionTag}
                                checked={options?.SECOND_LVL_COLLECTION_TAG.length ? form.secondLevelCollectionTags?.value?.length === secondCollectionFilteredOptions?.length : false}
                                disabled={form.secondLevelCollectionTags.isDisabled}
                            >
                                <span className={styles.checkboxText}>Выбрать все</span>
                            </Checkbox>
                        }
                    />
                </Spin>
            </div>
            <div className={classNames('label', styles.isContentTaggingRequired_label)}>Обязательность тегирования единиц контента</div>
            <div className={styles.isContentTaggingRequired}>
                <CheckboxComponent
                    disabled={form?.isContentTaggingRequired.isDisabled}
                    name={filingName('isContentTaggingRequired')}
                    placeholder={form?.isContentTaggingRequired.value ? 'Обязательность включена' : 'Обязательность выключена'}
                />
            </div>
        </>
    )
};
export default React.memo(TagsField);
