import React from 'react';
import { FileType } from 'shared/types/file';
import { DocumentViewer } from 'shared/ui/file-viewer';
import ImageComponent from 'shared/ui/image';
import PlayerComponent from 'shared/ui/player';
import { Empty } from 'antd';

type Props = {
	file: FileType
	//TODO У будущем удалить, нужно для страницы контента чтоб
	// заново запросить файлы когда идёт генерация превью для документов
	refetchFiles?: VoidFunction;
}
const ViewFile = ({file, refetchFiles}: Props) => {
	switch (file.type) {
		case 'PDF':
		case 'CSV':
		case 'DOC':
		case 'DOCX':
		case 'PPT':
		case 'PPTX':
		case 'TXT':
		case 'XLS':
		case 'XLSX':
			return (
				<DocumentViewer
					extension={'pdf'}
					fileId={file.id}
					fileType={file.type}
					previewFileFileId={file.previewFile?.id}
					name={file.previewFile?.name || file.name}
					invalidate={typeof refetchFiles === 'undefined'}
					closeModal={refetchFiles}
				/>
			)
		case 'IMAGE':
			return <ImageComponent imageId={file.id} compressedId={file.compressedId}/>
		case 'VIDEO':
		case 'AUDIO':
			return <PlayerComponent file={file}/>
		default:
			return <Empty description={`Неподдерживаемый формат: ${file.type}`}/>
	}
}

export default ViewFile;
