export const UsersActionTypes = {
	CLEAR_USERS_ERROR: '[USERS] CLEAR_USERS_ERROR',
	CLEAR_USERS_IS_SUCCESS: '[USERS] CLEAR_USERS_IS_SUCCESS',

	GET_USERS_LIST_START: '[USERS] GET_USERS_LIST_START',
	GET_USERS_LIST_SUCCESS: '[USERS] GET_USERS_LIST_SUCCESS',
	GET_USERS_LIST_ERROR: '[USERS] GET_USERS_LIST_ERROR',

	GET_USER_START: '[USERS] GET_USER_START',
	GET_USER_SUCCESS: '[USERS] GET_USER_SUCCESS',
	GET_USER_ERROR: '[USERS] GET_USER_ERROR',

	SEND_USER_DETAILS_START: '[USERS] SEND_USER_DETAILS_START',
	SEND_USER_DETAILS_SUCCESS: '[USERS] SEND_USER_DETAILS_SUCCESS',
	SEND_USER_DETAILS_ERROR: '[USERS] SEND_USER_DETAILS_ERROR',

	SET_USERS_FILTER: '[USERS] SET_USERS_FILTER',

	CREATE_USER_START: '[USERS] CREATE_USER_START',
	CREATE_USER_SUCCESS: '[USERS] CREATE_USER_SUCCESS',
	CREATE_USER_ERROR: '[USERS] CREATE_USER_ERROR',

	UPDATE_USER_START: '[USERS] UPDATE_USER_START',
	UPDATE_USER_SUCCESS: '[USERS] UPDATE_USER_SUCCESS',
	UPDATE_USER_ERROR: '[USERS] UPDATE_USER_ERROR',

	UPDATE_PERSONAL_DATA_START: '[USERS] UPDATE_PERSONAL_DATA_START',
	UPDATE_PERSONAL_DATA_SUCCESS: '[USERS] UPDATE_PERSONAL_DATA_SUCCESS',
	UPDATE_PERSONAL_DATA_ERROR: '[USERS] UPDATE_PERSONAL_DATA_ERROR',

	DELETE_USER_START: '[USERS] DELETE_USER_START',
	DELETE_USER_SUCCESS: '[USERS] DELETE_USER_SUCCESS',
	DELETE_USER_ERROR: '[USERS] DELETE_USER_ERROR',

	DELETE_USERS_START: '[USERS] DELETE_USERS_START',
	DELETE_USERS_SUCCESS: '[USERS] DELETE_USERS_SUCCESS',
	DELETE_USERS_ERROR: '[USERS] DELETE_USERS_ERROR',

	CHANGE_USER_STATUS_START: '[USERS] CHANGE_USER_STATUS_START',
	CHANGE_USER_STATUS_SUCCESS: '[USERS] CHANGE_USER_STATUS_SUCCESS',
	CHANGE_USER_STATUS_ERROR: '[USERS] CHANGE_USER_STATUS_ERROR',

	CHANGE_PASSWORD_START: '[USERS] CHANGE_PASSWORD_START',
	CHANGE_PASSWORD_SUCCESS: '[USERS] CHANGE_PASSWORD_SUCCESS',
	CHANGE_PASSWORD_ERROR: '[USERS] CHANGE_PASSWORD_ERROR',

	GET_RELATED_USERS_START: '[USERS] GET_RELATED_USERS_START',
	GET_RELATED_USERS_SUCCESS: '[USERS] GET_RELATED_USERS_SUCCESS',
	GET_RELATED_USERS_ERROR: '[USERS] GET_RELATED_USERS_ERROR',

	UNLOADING_USERS_START: '[USERS] UNLOADING_USERS_START',
	UNLOADING_USERS_SUCCESS: '[USERS] UNLOADING_USERS_SUCCESS',
	UNLOADING_USERS_ERROR: '[USERS] UNLOADING_USERS_ERROR',

	IMPORT_TEMPLATE_USERS_START: '[USERS] IMPORT_TEMPLATE_USERS_START',
	IMPORT_TEMPLATE_USERS_SUCCESS: '[USERS] IMPORT_TEMPLATE_USERS_SUCCESS',
	IMPORT_TEMPLATE_USERS_ERROR: '[USERS] IMPORT_TEMPLATE_USERS_ERROR',

	IMPORT_USERS_START: '[USERS] IMPORT_USERS_START',
	IMPORT_USERS_SUCCESS: '[USERS] IMPORT_USERS_SUCCESS',
	IMPORT_USERS_ERROR: '[USERS] IMPORT_USERS_ERROR',

	GET_ORGANIZATIONS_USERS_START: '[USERS] GET_ORGANIZATIONS_USERS_START',
	GET_ORGANIZATIONS_USERS_SUCCESS: '[USERS] GET_ORGANIZATIONS_USERS_SUCCESS',
	GET_ORGANIZATIONS_USERS_ERROR: '[USERS] GET_ORGANIZATIONS_USERS_ERROR',

	RECOVER_BULK_START: '[USERS] RECOVER_BULK_START',
	RECOVER_BULK_SUCCESS: '[USERS] RECOVER_BULK_SUCCESS',
	RECOVER_BULK_ERROR: '[USERS] RECOVER_BULK_ERROR',

	GET_INSPECTORS_START: '[USERS] GET_INSPECTORS_START',
	GET_INSPECTORS_SUCCESS: '[USERS] GET_INSPECTORS_SUCCESS',
	GET_INSPECTORS_ERROR: '[USERS] GET_INSPECTORS_ERROR',

	GET_LIST_ACTIVE_SYNCED_USERS_START: '[USERS] GET_LIST_ACTIVE_SYNCED_USERS_START',
	GET_LIST_ACTIVE_SYNCED_USERS_SUCCESS: '[USERS] GET_LIST_ACTIVE_SYNCED_USERS_SUCCESS',
	GET_LIST_ACTIVE_SYNCED_USERS_ERROR: '[USERS] GET_LIST_ACTIVE_SYNCED_USERS_ERROR',

	CLEAR_BULK_ACTIONS: '[USERS] CLEAR_BULK_ACTIONS',
} as const

export const UsersDoingTypes = {
	GET_ACTIONS_USERS_START: '[USERS] GET_ACTIONS_USERS_START',
	GET_ACTIONS_USERS_SUCCESS: '[USERS] GET_ACTIONS_USERS_SUCCESS',
	GET_ACTIONS_USERS_ERROR: '[USERS] GET_ACTIONS_USERS_ERROR',

	SET_ACTIONS_USERS_FILTER: '[USERS] SET_ACTIONS_USERS_FILTER',

} as const

export const OptionsNotificationsActionsTypes = {
	GET_NOTIFICATIONS_START: '[NOTIFICATIONS] GET_NOTIFICATIONS_START',
	GET_NOTIFICATIONS_SUCCESS: '[NOTIFICATIONS] GET_NOTIFICATIONS_SUCCESS',
	GET_NOTIFICATIONS_ERROR: '[NOTIFICATIONS] GET_NOTIFICATIONS_ERROR',

	GET_ACTIVE_NOTIFICATIONS_CHANNELS_START: '[NOTIFICATIONS] GET_ACTIVE_NOTIFICATIONS_CHANNELS_START',
	GET_ACTIVE_NOTIFICATIONS__CHANNELS_SUCCESS: '[NOTIFICATIONS] GET_ACTIVE_NOTIFICATIONS__CHANNELS_SUCCESS',
	GET_ACTIVE_NOTIFICATIONS__CHANNELS_ERROR: '[NOTIFICATIONS] GET_ACTIVE_NOTIFICATIONS__CHANNELS_ERROR',

	CLEAR_BULK_ACTIONS: '[NOTIFICATIONS] CLEAR_BULK_ACTIONS',

	SET_NOTIFICATION_FILTER: '[NOTIFICATIONS] SET_NOTIFICATION_FILTER',
} as const

export const PersonalSettingTypes = {
	GET_PERSONAL_SETTING_START: '[PERSONAL SETTING] GET_PERSONAL_SETTING_START',
	GET_PERSONAL_SETTING_SUCCESS: '[PERSONAL SETTING] GET_PERSONAL_SETTING_SUCCESS',
	GET_PERSONAL_SETTING_ERROR: '[PERSONAL SETTING] GET_PERSONAL_SETTING_ERROR',

	UPDATE_PASSWORD_LENGTH_START: '[PERSONAL SETTING] UPDATE_PASSWORD_LENGTH_START',
	UPDATE_CONTAIN_SYMBOLS_START: '[PERSONAL SETTING] UPDATE_CONTAIN_SYMBOLS_START',
	UPDATE_LETTERS_NUMBERS_START: '[PERSONAL SETTING] UPDATE_LETTERS_NUMBERS_START',

	UPDATE_PERSONAL_SETTING_START: '[PERSONAL SETTING] UPDATE_PERSONAL_SETTING_START',
	UPDATE_PERSONAL_SETTING_SUCCESS: '[PERSONAL SETTING] UPDATE_PERSONAL_SETTING_SUCCESS',
	UPDATE_PERSONAL_SETTING_ERROR: '[PERSONAL SETTING] UPDATE_PERSONAL_SETTING_ERROR',

	CONFIRM_EMAIL_START: '[PERSONAL SETTING] CONFIRM_EMAIL_START',
	CONFIRM_EMAIL_SUCCESS: '[PERSONAL SETTING] CONFIRM_EMAIL_SUCCESS',
	CONFIRM_EMAIL_ERROR: '[PERSONAL SETTING] CONFIRM_EMAIL_ERROR',

	CONFIRM_TELEGRAM_START: '[PERSONAL SETTING] CONFIRM_TELEGRAM_START',
	CONFIRM_TELEGRAM_SUCCESS: '[PERSONAL SETTING] CONFIRM_TELEGRAM_SUCCESS',
	CONFIRM_TELEGRAM_ERROR: '[PERSONAL SETTING] CONFIRM_TELEGRAM_ERROR',

	SEND_CONFIRM_EMAIL_START: '[PERSONAL SETTING] SEND_CONFIRM_EMAIL_START',
	SEND_CONFIRM_EMAIL_SUCCESS: '[PERSONAL SETTING] SEND_CONFIRM_EMAIL_SUCCESS',
	SEND_CONFIRM_EMAIL_ERROR: '[PERSONAL SETTING] SEND_CONFIRM_EMAIL_ERROR',

	CHANGE_PASSWORD_PERSONAL_SETTING_START: '[PERSONAL SETTING] CHANGE_PASSWORD_PERSONAL_SETTING_START',
	CHANGE_PASSWORD_PERSONAL_SETTING_SUCCESS: '[PERSONAL SETTING] CHANGE_PASSWORD_PERSONAL_SETTING_SUCCESS',
	CHANGE_PASSWORD_PERSONAL_SETTING_ERROR: '[PERSONAL SETTING] CHANGE_PASSWORD_PERSONAL_SETTING_ERROR',

	CLEAR_INPUT: '[PERSONAL SETTING] CLEAR_INPUT',
} as const



