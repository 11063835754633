import { FileType } from 'shared/types/file';
import { ModalEvent } from 'shared/ui/modal';
import ViewFile from '../ui/view-file';
import React from 'react';

type Props = { file: FileType, type?: string, refetchFiles?: VoidFunction }
export function OpenPreviewFile({file, type, refetchFiles}: Props) {
	ModalEvent.open({
		width: 900,
		title: file.name,
		component: (
			<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
				<ViewFile file={file} refetchFiles={refetchFiles}/>
			</div>
		),
		destroyOnClose: true,
		closePrevious: false,
		key: `PreviewFile-id:(${file.id})-${type || 'other'}`,
	});
}
