import React from 'react';
import {Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import AppService from "services/appService";
import {transformArrayIdNameForValue} from "utils/transformObjectFeels";
import {ExtendedSearchModel, ExtendedSearchTypes} from 'features/extended-search/index';
import {IOptions} from "models/feels";
import {useQuery} from "react-query";
import {ProjectApi, ProjectLib} from 'entities/project';
import {SprintApi} from 'entities/sprints';

const { Option} = Select;

type TKey = keyof Omit<
    ExtendedSearchTypes.Stores.Status, 'isToggled'>

export const StatusItem = () => {
    const statuses = useQuery({
        queryKey: ['projects/statuses'],
        queryFn: async () => {
            const {data} = await AppService.getStatuses()
            return transformArrayIdNameForValue(data)
        },
    })
    const sprintStatuses = useQuery({
        queryKey: [SprintApi.SprintService.getAllKey],
        queryFn: async () => {
            const {data} = await SprintApi.SprintService.getAll()
            return transformArrayIdNameForValue(data)
        },
    })
    const contentDirection = ProjectLib.useGetProjectContentDirectionOptions()

    const contentEditorLabel = useQuery({
        queryKey: [ProjectApi.ProjectLabelController.get.key, 'FOR_EDITOR'],
        queryFn: async () => {
            const data = await ProjectApi.ProjectLabelController.get.fetch({labelTypeCode: 'FOR_EDITOR'})
            return data.map((el) => ({value: el.id, label: el.name} as IOptions))
        },
    })
    const moderatorLabel = useQuery({
        queryKey: [ProjectApi.ProjectLabelController.get.key, 'FOR_MODERATOR'],
        queryFn: async () => {
            const data = await ProjectApi.ProjectLabelController.get.fetch({labelTypeCode: 'FOR_MODERATOR'})
            return data.map((el) => ({value: el.id, label: el.name} as IOptions))
        },
    })

    const isLoading = [
        statuses.isLoading,
        contentDirection.isLoading,
        contentEditorLabel.isLoading,
        moderatorLabel.isLoading,
        sprintStatuses.isLoading,
    ].includes(true);

    return (
        <Spin spinning={isLoading}>
            <div className="item-wrapper">
                <Item options={statuses.data || []} name={'statusIds'} label={'Статус проекта'}/>
                <Item options={sprintStatuses.data || []} name={'sprintStatusIds'} label={'Статус этапа'}/>
                <Item options={contentDirection.data || []} name={'contentDirectionIds'} label={'Направление'}/>
                <Item options={contentEditorLabel.data || []} name={'contentEditorLabelIds'} label={'Метка редактора контента'}/>
                <Item options={moderatorLabel.data || []} name={'moderatorLabelIds'} label={'Метка редактора каталога'}/>
            </div>
        </Spin>
    )
}
type ItemProps = {
    options: IOptions[],
    name: TKey,
    label: string
}
function Item({options, name, label}: ItemProps) {
    const put = useDispatch();
    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const status = useSelector(ExtendedSearchModel.selectors.status)

    function onSelectChange(value: string[]) {
        put(ExtendedSearchModel.actions.setStatus(typeContent, {[name]: value}))
    }

    return (
        <div className="item">
            <div className="label">{label}</div>
            <div className="form-group">
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    allowClear={true}
                    onChange={onSelectChange}
                    maxTagCount={"responsive"}
                    mode={"multiple"}
                    value={status[typeContent][name]}
                    placeholder={'Искать среди всех'}
                    filterOption={(input, option) => {
                        if (option === undefined || option === null) return false;
                        return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                >
                    {options.map((el) => (
                        <Option
                            key={el.value}
                            value={el.value}
                            data-name={el.label}
                        >
                            {el.label}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}
