import { z } from 'zod';
import { FilterRequest, Id_Name_Code_Hidden_Schema, SearchResponse } from 'shared/types/common';
import {Nullable} from "models/common";

export const TagSchema = Id_Name_Code_Hidden_Schema.extend({
    level: z.number(),
    isForNationalProject: z.boolean(),
    isAssignment: z.boolean(),
    isTheme: z.boolean(),
    isCollection: z.boolean(),
    levelVisibility: z.boolean(),
    description: z.string().nullable().optional()
});

export type Tag = z.infer<typeof TagSchema>;

export type ProjectTagRequest = {
    code: string | null,
    name: string,
    level: Nullable<number>,
    isForNationalProject: boolean,
    isAssignment: boolean,
    isTheme: boolean,
    isCollection: boolean,
    description?: Nullable<string>,
    hidden: boolean
}

export type FilterData = {
    searchString?: string;
    hidden?: boolean;
    level?: number,
    isForNationalProject?: boolean,
    isAssignment?: boolean,
    isTheme?: boolean,
    isCollection?: boolean,
};

export type ProjectTags = SearchResponse<Tag>;
export type Filter = FilterRequest<FilterData>
