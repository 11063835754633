import {TasksContentActionTypes} from "../action-types/tasks-content";

import {InferValueTypes} from "models/common";
import * as actions from '../actions/content'
import {TTaskRecordFilter} from "models/task/task-content";
import {ErrorActionState, StartActionState, SuccessActionState} from "utils/reducerHalper";
import {AuthActionTypes} from "../../auth/action-types";
import {Logout} from "../../auth/reducer";
import {TaskTypes} from "entities/task";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;
type TResetStatusRecords = {[key: string]: {isLoading: boolean, ok?: boolean, error?: any}}
export const initialFilter =  {
    page: 1,
    pageSize: 50,
    filterData: {searchString: ''}
}

export type TTasksContentState = {
    tasks: TaskTypes.ContentRecords[] | null
    filter: TTaskRecordFilter
    votes: Record<string, TaskTypes.NewVote>
    recordId: string | null
    resetStatusRecords: TResetStatusRecords
    bulkAction: string | null
    rowsIds: string[]
    isLoading: boolean
    error: any
}

export const initialState: TTasksContentState = {
    tasks: null,
    filter: initialFilter,
    votes: {},
    recordId: null,
    resetStatusRecords: {},
    bulkAction: null,
    rowsIds: [],
    isLoading: false,
    error: null
}

export default function tasksReducer(state: TTasksContentState = initialState, action: ActionTypes | Logout): TTasksContentState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case TasksContentActionTypes.CLEAR_FILTER:
            return {...state, filter: initialFilter}
        case TasksContentActionTypes.SET_TASK_FILTER:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter
                }
            }
        case TasksContentActionTypes.SET_BULK_ACTION:
            return {
                ...state,
                bulkAction: action.bulkAction
            }
        case TasksContentActionTypes.SET_ROWS_IDS:
            return {
                ...state,
                rowsIds: action.rows
            }
        case TasksContentActionTypes.VOTE_TASK_STATUS:
            return {
                ...state,
                votes: {
                    ...state.votes,
                    [action.data.vote.recordId]: action.data
                }
            }
        case TasksContentActionTypes.VOTE_TASK_MULTIPLE_STATUSES:
            return {
                ...state,
                votes: {
                    ...state.votes,
                     ...action.data
                }
            }
        case TasksContentActionTypes.VOTE_TASK_COMMENT:
            return {
                ...state,
                votes: {
                    ...state.votes,
                    [action.data.vote.recordId]: action.data
                }
            }
        case TasksContentActionTypes.VOTE_TASK_CHANGE:
            const newObj = Object.assign({}, state.votes);
            delete newObj[action.id];
            return {
                ...state,
                votes: {...newObj}
            }

        case TasksContentActionTypes.CREATE_FILE_ARCHIVE_START:
            return state
        case TasksContentActionTypes.CREATE_FILE_ARCHIVE_SUCCESS:
            return state
        case TasksContentActionTypes.CREATE_FILE_ARCHIVE_ERROR:
            return ErrorActionState(state, action);

        case TasksContentActionTypes.ADD_TASK_STAT:
            return StartActionState(state);
        case TasksContentActionTypes.ADD_TASK_SUCCESS:
            return {...state, recordId: action.recordId};
        case TasksContentActionTypes.ADD_TASK_ERROR:
            return ErrorActionState(state, action);

        case TasksContentActionTypes.DELETE_TASK_STAT:
            return StartActionState(state);
        case TasksContentActionTypes.DELETE_TASK_SUCCESS:
            return {...state};
        case TasksContentActionTypes.DELETE_TASK_ERROR:
            return ErrorActionState(state, action);

      //TO_EXAMINATION_TASK
        case TasksContentActionTypes.TO_EXAMINATION_TASK_START:
            return StartActionState(state);
        case TasksContentActionTypes.TO_EXAMINATION_TASK_SUCCESS:
            return SuccessActionState(state)
        case TasksContentActionTypes.TO_EXAMINATION_TASK_ERROR:
            return ErrorActionState(state, action)

        case TasksContentActionTypes.UPDATE_TASK_STAT:
            return StartActionState(state);
        case TasksContentActionTypes.UPDATE_TASK_SUCCESS:
            return {...state, recordId: null};
        case TasksContentActionTypes.UPDATE_TASK_ERROR:
            return ErrorActionState(state, action);

        case TasksContentActionTypes.VOTE_TASK_START:
            return StartActionState(state);
        case TasksContentActionTypes.VOTE_TASK_SUCCESS:
            return {...state};
        case TasksContentActionTypes.VOTE_TASK_ERROR:
            return ErrorActionState(state, action);

        case TasksContentActionTypes.INTERIM_VOTE_TASK_START:
            return StartActionState(state);
        case TasksContentActionTypes.INTERIM_VOTE_TASK_SUCCESS:
            return SuccessActionState(state)
        case TasksContentActionTypes.INTERIM_VOTE_TASK_ERROR:
            return ErrorActionState(state, action);

        case TasksContentActionTypes.GET_TASKS_STAT:
            return {
                ...state,
                error: null,
                isLoading: state.isLoading ? state.isLoading : true
            }

        case TasksContentActionTypes.GET_TASKS_SUCCESS:
            const {content, page, ...filters} = action.tasks;

            return {
                ...SuccessActionState(state),
                tasks: content,
                filter: {
                    ...state.filter,
                    page: page + 1,
                    ...filters,
                },
                resetStatusRecords: initialState.resetStatusRecords
            };
        case TasksContentActionTypes.GET_TASKS_ERROR:
            return ErrorActionState(state, action);
        //GET_RECORD_ID
        case TasksContentActionTypes.GET_RECORD_START:
            return {...StartActionState(state)}
        case TasksContentActionTypes.GET_RECORD_SUCCESS:
            return {
                ...SuccessActionState(state),
                tasks: action.data,
                resetStatusRecords: initialState.resetStatusRecords
            }
        case TasksContentActionTypes.GET_RECORD_ERROR:
            return ErrorActionState(state, action)

        //RESET STATUS
        case TasksContentActionTypes.RESET_STATUS_TASK_START:
            return {
                ...state,
                resetStatusRecords: {
                    ...state.resetStatusRecords,
                    [action.recordId]: {isLoading: true}
                }
            }
        case TasksContentActionTypes.RESET_STATUS_TASK_SUCCESS:
            return {
                ...SuccessActionState(state),
                resetStatusRecords: {
                    ...state.resetStatusRecords,
                    [action.recordId]: {ok: true, isLoading: false}
                }
            }
        case TasksContentActionTypes.RESET_STATUS_TASK_ERROR:
            return {
                ...ErrorActionState(state, action),
                resetStatusRecords: {
                    ...state.resetStatusRecords,
                    [action.recordId]: {ok: false, error: action.error, isLoading: false}
                }
            }
        //Bulk Actions Votes
        case TasksContentActionTypes.SEND_BULK_VOTES_START:
            return StartActionState(state)
        case TasksContentActionTypes.SEND_BULK_VOTES_SUCCESS:
            return {...SuccessActionState(state), votes: {}}
        case TasksContentActionTypes.SEND_BULK_VOTES_ERROR:
            return ErrorActionState(state, action)

        //Bulk Actions Review
        case TasksContentActionTypes.SEND_BULK_REVIEW_START:
            return StartActionState(state)
        case TasksContentActionTypes.SEND_BULK_REVIEW_SUCCESS:
            return SuccessActionState(state)
        case TasksContentActionTypes.SEND_BULK_REVIEW_ERROR:
            return ErrorActionState(state, action)
        default:
            return state
    }
}
