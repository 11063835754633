import React, { useState } from 'react';
import { FileType } from 'shared/types/file';
import AudioPlayer from './audio';
import { Select } from 'antd';
import VimePlayer from './vime';
import styles from './styles.module.less';
import VideoReact from './video-react';
import env from 'shared/config/env';
import VideoDecoder from './video-decoder';
type Props = {
	file: FileType;
};
const path = `${env.API_ENDPOINT}/files/`;
type Players = 'vime' | 'video-react' | 'video' | 'VideoDecoder';
const variants = [
	{value: 'vime', label: 'Vime'},
	{value: 'video-react', label: 'VideoReact'},
	{value: 'video', label: 'video'},
	{value: 'VideoDecoder', label: 'VideoDecoder'},
]
const PlayerComponent = ({file}: Props) => {
	const [player, setPlayer] = useState<Players>('vime');

	if (file.type === 'AUDIO') return <AudioPlayer file={file}/>
	return (
		<div className={styles.container}>
			<div className={styles.chosen}>
				<span>Сменить плеер</span>
				<Select
					className={styles.select}
					onChange={(value: Players) => setPlayer(value)}
					value={player}
					options={variants}
					placeholder={'Выберите плеер'}
				/>
			</div>
			<div>
				{player === 'vime' && <VimePlayer file={file}/>}
				{player === 'video-react' && <VideoReact file={file}/>}
				{player === 'video' && (
					<video width="540" height="310" controls crossOrigin="use-credentials" preload="metadata">
						<source src={`${path}${file.id}`} type="video/mp4" />
					</video>
				)}
				{player === 'VideoDecoder' && <VideoDecoder file={file}/>}
			</div>
		</div>
	)
};

export default PlayerComponent;