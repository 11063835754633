import {useEffect, useState} from "react";
import {IOptions} from "models/feels";
import {useDispatch} from "react-redux";
import {GetFeelsSuccessAction} from "store/app/actions";
import {ProjectApi} from "entities/project";

export function useGetStatusAndProjects() {
    const put = useDispatch()
    const [statuses, setStatuses] = useState<IOptions[]>([]);
    const [loadingStatuses, setLoadingStatuses] = useState(false);

    async function getStatuses() {
        try {
            setLoadingStatuses(true);
            const {data} = await ProjectApi.ProjectStatusesController.getStatuses()
            const options: IOptions[] = data.map((el) => ({value: el.id, label: el.name, code: el.code}))
            setStatuses(options)
            put(GetFeelsSuccessAction({ statuses: data }))
        } catch (e) {
            setStatuses([]);
        } finally {
            setLoadingStatuses(false);
        }
    }

    useEffect(() => {
        if (!statuses.length) getStatuses()
    }, []);//eslint-disable-line


    return {statuses, loadingStatuses}
}
