import React, { useEffect, useState } from 'react'
import './index.less'
import ComeBackBtn from 'processes/breadcrumbs/ui/come-back-btn'
import { useDispatch, useSelector } from 'react-redux'
import { CONTENT } from 'routing/names'
import { TypeContent } from 'models/content'
import InnerHTMLFunction from 'utils/innerHTMLFunction'
import { Collapse, Empty, Pagination, Spin } from 'antd'
import ErrorBoundary from 'antd/es/alert/ErrorBoundary'
import { selectContentProject, selectFilter } from 'store/content/content/selectors'
import ViewContent from '../components/view-content'
import ProjectInformation from './projectInformation'
import Tags from './tags'
import TabItemElement from '../components/tab-item/tab-item'
import Sorting from '../components/actions'
import { SetSortingFilter } from 'store/content/content/actions'

const { Panel } = Collapse

const ContentItemPage = () => {
	const put = useDispatch()
	const filter = useSelector(selectFilter)
	const [activeContent, setActiveContent] = useState<TypeContent | null>(null)
	const { project, content, contentTotalCount, isLoading } = useSelector(selectContentProject)

	useEffect(() => {
		if (content?.length) setActiveContent(content[0])
	}, [content])

	const handlerItem = (content: TypeContent) => () => {
		if (content) {
			setActiveContent(content)
		}
	}

	const onChangePage = (page: number, pageSize?: number) => {
		put(SetSortingFilter({ ...filter, page: page, pageSize: pageSize ? pageSize : 10 }))
	}

	const isEmpty = !project || !content
	return (
		<Spin spinning={isLoading}>
			<div className="ContentItemPage">
				<ErrorBoundary>
					<ComeBackBtn breadcrumbs={[{ link: CONTENT.CONTENT, name: 'Список контента' }]} />
					{isEmpty && <Empty />}
					{!isEmpty && (
						<>
							<div className="ContentItemPage__header">
								<div className="ContentItemPage__header-top">
									<div className="title">Проект “{project.name}”</div>
									<div className="status">
										Категория проекта: {project.type.name} • Статус проекта:{' '}
										<span className={project.status.code}>{project.status.name}</span>
									</div>
								</div>
								<div className="ContentItemPage__header-bottom">
									<span>{project.contractorOrganization.name}</span>
									<span> • {project.contentDirection?.name}</span>
									<span> • {project.session.year}</span>
								</div>
							</div>
							<div className="ContentItemPage__content">
								<div className="ContentItemPage__content-player">
									<Spin spinning={isLoading}>
										<ViewContent
											key={activeContent?.id}
											files={activeContent?.files}
										/>
									</Spin>
								</div>
								<div className="ContentItemPage__content-description">
									<ProjectInformation activeContent={activeContent} />
									{!!activeContent?.internetResources.length && (
										<div className="item">
											<span className="title">Размещение контента</span>
											<ul className={'item-ul'}>
												{activeContent?.internetResources.map((el, index) => (
													<li className={'item-li'} key={index + el.internetResource.id}>
														{el.internetResource.name} <strong>{el.actualKpi}</strong> <small>просмотров</small>
													</li>
												))}
											</ul>
										</div>
									)}

									<Tags content={activeContent} project={project} />

									<div className="item">
										<Collapse defaultActiveKey={['1', '2']} ghost={true}>
											<Panel header="Описание проекта" key="1">
												<div
													id="quill-container"
													dangerouslySetInnerHTML={InnerHTMLFunction(project.description as string)}
												/>
											</Panel>
											<Panel header={activeContent?.name} key="2">
												<div
													id="quill-container"
													dangerouslySetInnerHTML={InnerHTMLFunction(activeContent?.description as string)}
												/>
											</Panel>
										</Collapse>
									</div>
								</div>
							</div>
							<div className="ContentItemPage__footer">
								<Sorting />
								{content?.length ? (
									<div className="ContentItemPage__footer__cards">
										{content.map((el, index) => (
											<TabItemElement
												active={activeContent?.id === el.id}
												key={el.name + el.status.id + index}
												el={el}
												handler={handlerItem(el)}
												image={project.image}
											/>
										))}
									</div>
								) : (
									<div className="ContentItemPage__footer__cards ContentItemPage__footer__empty">
										<Empty />
									</div>
								)}
								{content?.length ? (
									<div className="ContentItemPage__footer__pagination">
										<Pagination
											pageSize={filter.pageSize}
											current={filter.page}
											total={contentTotalCount}
											onChange={onChangePage}
										/>
									</div>
								) : null}
							</div>
						</>
					)}
				</ErrorBoundary>
			</div>
		</Spin>
	)
}
export default ContentItemPage
