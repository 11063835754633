import {AxiosResponse} from 'axios'
import $api from '../http'
import {
    T_ID_NAME,
    T_ID_NAME_CODE,
    T_ID_NAME_CODE_HIDDEN,
    TFormatTypes,
    TSubmissionForms,
    TThematic
} from 'models/common'

/**
 * @category Service
 * @subcategory All
 * @class AppService
 */
export default class AppService {
    /**
     * @static method getStatuses()
     * @description Получение всех статусов проектов, доступных для текущей роли
     */
    static async getStatuses(): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get('/projects/statuses')
    }
    /**
     * @static method getRequestNumbers()
     * @description Получение номеров заявок проектов
     */
    static requestNumbersKey = '/projects/request-numbers'
    static async getRequestNumbers(): Promise<AxiosResponse<string[]>> {
        return $api.get('/projects/request-numbers')
    }
    /**
     * @static method getСontractNumbers()
     * @description Получение номеров договоров проектов
     */
    static contractNumbersKey = '/projects/contract-numbers'
    static async getContractNumbers(): Promise<AxiosResponse<string[]>> {
        return $api.get('/projects/contract-numbers')
    }
    /**
     * @static method getCodes()
     * @description Получение кодов проектов
     */
    static codesKey = '/projects/codes'
    static async getCodes(): Promise<AxiosResponse<string[]>> {
        return $api.get('/projects/codes')
    }
    /**
     * @static method getIgks()
     * @description Получение ИГК проектов
     */
    static igksKey = '/projects/igks'
    static async getIgks(): Promise<AxiosResponse<string[]>> {
        return $api.get('/projects/igks')
    }
    /**
     * @static method getFormatTypes()
     * @description Получение всех типов форматов контента проектов
     */
    //todo создал новую версию в entities project
    static formatTypesKey = '/projects/format_types';
    static async getFormatTypes(): Promise<AxiosResponse<TFormatTypes[]>> {
        return $api.get('/projects/format_types')
    }
    //todo создал новую версию в entities project
    static submissionFormsKey = '/projects/submission_forms'
    static async getSubmissionForms(): Promise<AxiosResponse<TSubmissionForms[]>> {
        return $api.get('/projects/submission_forms')
    }
    /**
     * @static method getThematics()
     * @description Получение всех типов форматов контента проектов
     */
    static thematicsKey = '/projects/thematics'
    static async getThematics(): Promise<AxiosResponse<TThematic[]>> {
        return $api.get('/projects/thematics')
    }
    /**
     * @static method getFormatSubmissionById(projectId: string)
     * @description Получение всех форм сдачи контента проектов по проекту project-content-submission-form-controller
     * @param {string} projectId - id проекта
     * @returns {Promise<AxiosResponse<T_ID_NAME[]>>}
     */
    static async getFormatSubmissionById(projectId: string): Promise<AxiosResponse<T_ID_NAME[]>> {
        return $api.get<T_ID_NAME[]>(`/projects/${projectId}/submission_forms`)
    }
    /**
     * @static method getFormatTypesById(projectId: string)
     * @description Получение всех типов контента проектов по проекту
     * @param {string} projectId - id проекта
     * @returns {Promise<AxiosResponse<T_ID_NAME_CODE[]>>}
     */
    static async getFormatTypesById(projectId: string): Promise<AxiosResponse<T_ID_NAME_CODE[]>> {
        return $api.get<T_ID_NAME_CODE[]>(`/projects/${projectId}/format_types`)
    }
    /**
     * @static method getKpiTypes()
     * @description Получение списка меры измерения КПЭ
     */
    static async getKpiTypes(): Promise<AxiosResponse<T_ID_NAME[]>> {
        return $api.get<T_ID_NAME[]>('/kpi-types')
    }
    /**
     * @static method getReportType()
     * @description Получение всех типов отчётов estimate-type-controller
     */
    static reportsTypesKey = '/reports/types'
    static async getReportType(): Promise<AxiosResponse<T_ID_NAME[]>> {
        return $api.get<T_ID_NAME[]>('/reports/types')
    }
	/**
     * @static method getRoles()
     * @description Получение списка ролей
     * @returns {T_ID_NAME[]}
	 */
	static async getRoles(): Promise<AxiosResponse<T_ID_NAME[]>> {
        return $api.get<T_ID_NAME[]>('/roles')
    }

    /**
     * Получение всех званий
     */
    static getRanks(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/rank')
    }
    /**
     * Получение всех regions
     */
    static regionsKey = '/region/types'
    static getRegions(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/region/types')
    }
    /**
     * Получение всех тегох категорий проектов
     */
    static categoryProjectKey = '/category-types';
    static async getCategoryProject(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/category-types')
    }

    /**
     * Получение всех жанров категорий проектов
     */
    static genreProjectKey = '/genre-types'
    static async getGenreProject(): Promise<AxiosResponse<T_ID_NAME_CODE_HIDDEN[]>> {
        return $api.get('/genre-types')
    }
}
