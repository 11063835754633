import { Modal } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { ModalType, OpenModalTypeParams } from './events';
import styles from './styles.module.less';
import { useMap, useSet } from 'shared/lib/hooks';
import { eventBus } from 'shared/services';

const ModalComponent = () => {
	const modalKeys = useSet<string>([]);

	const [modal, modalActions] = useMap<string, ModalType>();
	const activeKey = Array.from(modalKeys).at(-1);


	const handleModal = ({ key = new Date().toISOString(), closePrevious = true, ...rest }: OpenModalTypeParams) => {
		if (closePrevious) {
			modalKeys.clear();
			modalActions.reset();
		}
		modalKeys.add(key);
		modalActions.set(key, rest);
	};

	const handleClose = (key?: string) => {
		requestAnimationFrame(() => {
			//Отслеживаем какое модальное окно было закрыто
			eventBus.broadcast('Modal-after-close', activeKey)
		})
		if (key) {
			modalKeys.delete(key);
			modalActions.remove(key);
		}
		if (activeKey) {
			modalKeys.delete(activeKey);
			modalActions.remove(activeKey);
		}
	};

	const closeAll = useCallback(() => {
		modalKeys.clear();
		modalActions.reset();
	}, [modalActions, modalKeys]);

	useEffect(() => {
		const unsubscribe = eventBus.subscribe('ModalClose-All', closeAll);
		return () => unsubscribe();
	}, []);//eslint-disable-line

	useEffect(() => {
		const unsubscribe = eventBus.subscribe('ModalOpen', handleModal);
		return () => unsubscribe();
	}, []);//eslint-disable-line

	useEffect(() => {
		if (!activeKey) eventBus.broadcast('ModalClose-All');
		const unsubscribe = eventBus.subscribe('ModalClose', handleClose);
		return () => unsubscribe();
	}, [activeKey]);//eslint-disable-line

	return (
		<Modal
			className={styles.modal}
			centered
			visible={!!activeKey}
			onCancel={() => eventBus.broadcast('ModalClose', activeKey)}
			footer={null}
			{...(activeKey && modal.get(activeKey))}
		>
			{activeKey && modal.get(activeKey)?.component}
		</Modal>
	)
};
export default ModalComponent;
