export type TFilterData = {
    searchString:  string; //Строка поиска (наименование проекта, номер договора, номер заявки)
    isApprovedMasterContentExist: boolean // Признак наличия в проекте данных контента (апрувнутый, мастер-файл)
    excludeWithoutContent: boolean // Признак исключения проектов без контента

    contestId: string; //ID конкурса
    year: number; //Список годов
    sessionId: string; //Список ID сессий

    statusIds: string[]; //Список ID статусов
    contentDirectionIds: string[]; //Список ID направлений контента

    isProjectPosted: boolean; //Признак размещения проекта
    isFavoriteProject: boolean; //Признак избранного проекта

    projectTagIds: string[] //Теги проекта
    contentTagIds: string[] //Теги единиц контента проекта
    regionAboutIds: string[] //О каком регионе проект
    regionForIds: string[] //Для какого региона проект
    contentFilterRegionAbout: string[] //О каком регионе ед.контента
    contentFilterRegionFor: string[] //Для какого региона ед.контента

    genreIds: string[] //Жанры
    categoryIds: string[] //Категории

    contentFormatTypeIds: string[] //Формат единиц контента в проекте
    contentFilterContentSubmissionFormIds: string[] //Форма сдачи единиц контента согласно записям задач (файл)
    contentSubmissionFormIds: string[] //Форма сдачи единиц контента в проекте
    contentFilterContentFormatTypeIds: string[] //Формат единиц контента согласно записям задач (файл)
    contentStatusCodes: string[]; //Список статусов

    fieldFilters: FieldFilter[] //Список фильтров по выпадающим спискам
    bulkAction: BulkAction[]
}

export type FieldFilter = {
    field: FieldsEnums; //Код поля, по которому производится фильтрация
    values: string[]; //Список значений для фильтрации
    minValue?: number; //Минимальное значение для фильтрации
    maxValue?: number; //Максимальное значение для фильтрации
    startDate?: string | Date; //Начальная дата для фильтрации по датам
    endDate?: string | Date; //Конечная дата для фильтрации по датам
    isAndOrBesides?: boolean; //Признак и/или (для списка значений), а также кроме/между (для минимального и максимального значений): true - и/кроме, false - или/между
}
export enum FieldsEnums {
    REGION_ABOUT = 'REGION_ABOUT',
    REGION_FOR = 'REGION_FOR',

    CONTENT_FORMAT_TYPE = 'CONTENT_FORMAT_TYPE',
    CONTENT_SUBMISSION_FORM = 'CONTENT_SUBMISSION_FORM',

    CONTRACT_DATE = 'CONTRACT_DATE',
    COMPLETION_DATE = 'COMPLETION_DATE',
    AGREEMENT_DATE = 'AGREEMENT_DATE',
    GENRE = 'GENRE',
    CATEGORY = 'CATEGORY',

    SPRINT_UNTIL_END_DAYS = 'SPRINT_UNTIL_END_DAYS',
    TASK_UNTIL_END_DAYS = 'TASK_UNTIL_END_DAYS',
}
export type ContentFieldFilters = {
    field: FieldsContent //Код поля, по которому производится фильтрация
    values: string[] //Список значений для фильтрации
    isAndOrBesides?: boolean; //Признак и/или (для списка значений), а также кроме/между (для минимального и максимального значений): true - и/кроме, false - или/между
}
export enum FieldsContent {
    CONTENT_FILTER_FIRST_LVL_TAG = 'CONTENT_FILTER_FIRST_LVL_TAG',
    CONTENT_FILTER_SECOND_LVL_TAG = 'CONTENT_FILTER_SECOND_LVL_TAG',
    CONTENT_FILTER_REGION_ABOUT = 'CONTENT_FILTER_REGION_ABOUT',
    CONTENT_FILTER_REGION_FOR = 'CONTENT_FILTER_REGION_FOR',
    CONTENT_FILTER_CONTENT_SUBMISSION_FORM  = 'CONTENT_FILTER_CONTENT_SUBMISSION_FORM',
}
export enum BulkAction  {
    ASSIGN_DRAFT_STATUS = 'ASSIGN_DRAFT_STATUS',
    ASSIGN_ACTUAL_STATUS = 'ASSIGN_ACTUAL_STATUS',
    ASSIGN_SUSPENDED_STATUS = 'ASSIGN_SUSPENDED_STATUS',
    ASSIGN_COMPLETED_STATUS = 'ASSIGN_COMPLETED_STATUS',
    DELETE = 'DELETE',
    SHOW_FOR_AGENT = 'SHOW_FOR_AGENT',
    HIDE_FOR_AGENT = 'HIDE_FOR_AGENT',
}
