import {axiosRequest} from 'shared/config/axios';
import {
	ContentDirection,
	ContentDirectionFilter,
	ContentDirectionRequest,
	ContentDirections,
	ContentDirectionSchema
} from '../types';
import {downloadXLXS} from "utils/downloadXLXS";

/**
 * @description Получение всех направлений контента проектов
 */
export const getContentDirections = {
	key: '/projects/directions',
	fetch: async () => {
		const {data} = await axiosRequest<ContentDirection[]>({
			url: '/projects/directions',
			method: 'GET',
			showError: true,
			parser: ContentDirectionSchema.array()
		})
		return data
	},
}

/**
 * Получение списка направления контента с пагинацией, сортировкой и поиском
 */
export const getWithFilter = {
	key: '/projects/directions/search',
	fetch: async (filter: ContentDirectionFilter): Promise<ContentDirections> => {
		const newFilter = { ...filter, page: filter.page - 1 }
		const { data } = await axiosRequest<ContentDirections>({
			method: 'POST',
			url: '/projects/directions/search',
			data: newFilter,
			parser: { list: true, schema: ContentDirectionSchema.array() },
		})
		return data
	},
}
/**
 * Создание нового направления контента
 */
export const create = async (data: ContentDirectionRequest) => {
	await axiosRequest({
		method: 'POST',
		url: '/projects/directions',
		data,
	})
}

/**
 * Обновление направления контента
 */
export const update = async ({ id, data }: { id: string; data: ContentDirectionRequest }) => {
	await axiosRequest({
		method: 'PUT',
		url: `/projects/directions/${id}`,
		data,
	})
}

/**
 * Удаление направления контента
 */
export const remove = async (id: string) => {
	await axiosRequest({
		method: 'DELETE',
		url: `/projects/directions/${id}`,
	})
}

/**
 * Массовые действия
 */
export const bulkActions = async ({ rowIds, bulkAction }: { rowIds: string[]; bulkAction: string }) => {
	await axiosRequest({
		method: 'POST',
		url: `/projects/directions/bulk-actions/${bulkAction}`,
		data: rowIds,
	})
	return bulkAction
}

/**
 * Выполнение выгрузки списка в формате Excel
 */
export const download = async (value: string[]) => {
	const { data }: any = await axiosRequest({
		method: 'POST',
		url: `/projects/directions/export`,
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
		data: value,
	})
	downloadXLXS(data, 'список_направления_контента')
}
/**
 * Получение файла шаблона для выполнения импорта данных направления контента
 */
export const downloadTemplate = async () => {
	const { data }: any = await axiosRequest({
		method: 'GET',
		url: '/projects/directions/import/template',
		responseType: 'arraybuffer',
		headers: {
			'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		},
	})
	downloadXLXS(data, 'шаблон_формы_сдачи_контента')
}
/**
 * Выполнение импорта данных направления контента из заполненного шаблона в формате Excel
 */
export const upload = async (data: FormData) => {
	await axiosRequest({
		method: 'POST',
		url: '/projects/directions/import',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data,
	})
}
