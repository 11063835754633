import React, {FC, useEffect, useMemo} from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectRole} from "store/auth/selectors";
import {selectProjectsStatus} from "store/projects/selectors";
import usePath from "hooks/usePath";
import {PROJECTS} from "routing/names";
import useHandlerStatus from "hooks/handlStatus";
import {ProjectTypes} from "entities/project";
import {TRole} from "models/user";
import { ProjectFormDto } from '../lib';
import {Col, Row, Spin} from "antd";
import StatusProjectForm from "components/status-element/status-project-form";
import styles from './form-container.module.less'
import ErrorComponent from 'components/error-boundary';
import FormUi from "./form";
import { useGetReturnLink } from 'shared/lib/hooks/get-return-link';

type Props = {
    id?: string
    handler?: Function
}

const FormContainer: FC<Props> = ({id, handler}) => {
    const typeForm = id ? 'edit' : 'crete';
    const history = useHistory();
    const ReturnLink = useGetReturnLink();
    const {page, typePage2} = usePath();

    const role = useSelector(selectRole);
    const {isLoaded, isLoading, project} = useSelector(selectProjectsStatus)

    const returnFunction = () => {
        if (ReturnLink) {
            history.push(ReturnLink)
        } else if (handler) {
            handler()
        } else {
            history.push(PROJECTS.PROJECTS);
        }
    }
    useEffect(() => {
        if (isLoaded) {
            returnFunction()
        }
        // eslint-disable-next-line
    }, [isLoaded, history, handler, page, typePage2]);


    const {statusCount, handlerStatus} = useHandlerStatus({init: 1, limit: 5})

    const info: TInfoProject = useMemo(() => {
        if (!!project) {
            return {
                status: project.status.code,
                role: role as TRole,
                tasksExist: project?.tasksExist
            }
        }
        else return null;
    }, [project, role]);

    const initialValue: ProjectFormDto = useMemo(() => {
        const data = typeForm === 'edit' ? new ProjectFormDto(project) : new ProjectFormDto()
        if (typeForm === 'edit' && role) {
            data.stepFirst
                .validate(info)
                .validateStatus(info)
            data.stepSecond
                .validate(info)
                .validateProjectType(data.stepFirst.projectType.value?.code)
                .validateLevelTags(data.stepFirst.status.value?.code)
                .validateContentTaggingRequired(role)
            data.stepThird
                .validate(info)
                .validateProjectType(data.stepFirst.projectType.value?.code)
            data.stepFourth
                .validate(info)
            data.stepFifth
                .validate(info)
                .validateTargetAudienceAgeTo()
                .validateNationalTags()
        } else {
            data.stepFirst.validateStatus(null)
            data.stepSecond.validateContentTaggingRequired(role)
            data.stepFifth.validateTargetAudienceAgeTo()
                .validateNationalTags()
        }
        return data
    }, [typeForm, info, project, role]);

    return (
        <Spin spinning={isLoading}>
            <Row>
                <Col xl={2} xs={24} sm={4} md={3} className='projectCreate__status'>
                    <StatusProjectForm status={statusCount} handler={handlerStatus}/>
                </Col>
                <Col xl={22} xs={24} sm={20} md={21} className={styles.projectForm}>
                    <ErrorComponent>
                        <FormUi
                            isEdit={typeForm === 'edit'}
                            id={id}
                            statusCount={statusCount}
                            data={initialValue}
                            handler={handlerStatus}
                            handlerClose={returnFunction}
                            status={id && project ? project.status.code : undefined}
                        />
                    </ErrorComponent>
                </Col>
            </Row>
        </Spin>
    )
};
export type TInfoProject = {
    status: ProjectTypes.ProjectStatus
    role: TRole
    tasksExist?: boolean
} | null
export default FormContainer;
