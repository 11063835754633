import React, { FC, useState } from 'react'
import { Select, Spin, Tooltip } from 'antd'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { IOptions } from 'models/feels'
import { ProjectLib } from 'entities/project'
import { ReportTypes } from 'entities/reports'
import styles from './styles.module.less'

const { Option } = Select

type TProjectSelect = {
	setState: (val: string[]) => void
	state: string[]
	sessionIds: string[]
	disabled: boolean
	type: ReportTypes.ReportType
}

const ProjectSelect: FC<TProjectSelect> = ({ setState, state, sessionIds, disabled, type }) => {
	const [projects, setProjects] = useState<IOptions[]>()
	const { isLoading } = ProjectLib.useGetProjectAll(
		{
			statusCodes: ['ACTUAL', 'ARCHIVED', 'COMPLETED'],
			sessionIds: sessionIds || [],
			projectDirectionLinkedWithReportCode: type,
			enabled: !!sessionIds && !!type,
		},
		(data) => {
			const options = data.map((el) =>
					({
						value: el.id,
						label: el.name,
						code: `${el.name} (${el.contractorOrganization?.name})`,
					} as IOptions)
			)
			setProjects(options)
		}
	)
	const arr = projects?.map((it) => it.value) || []

	const handlerSelect = (values: string[]) => {
		setState(values)
	}

	const onClear = () => {
		setState([])
	}

	const handlerCheckbox = (e: CheckboxChangeEvent) => {
		const checked: boolean = e.target.checked
		if (checked) setState(arr)
		else setState([])
	}

	return (
		<Spin spinning={isLoading}>
			<div className={styles.wrapper}>
				<p className={styles.text}>Проекты</p>
				<Select
					disabled={disabled}
					allowClear
					getPopupContainer={(trigger) => trigger.parentNode}
					mode={'multiple'}
					value={state}
					onClear={onClear}
					onChange={handlerSelect}
					placeholder={'Выберите доступные для данного отчета проекты'}
					maxTagCount={'responsive'}
					optionFilterProp="children"
					filterOption={(input, option) => {
						if (option === undefined || null) return false

						const optionText = option?.['data-code']
						return optionText?.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}}
					dropdownRender={(menu) => (
						<>
							{menu}
							<div className={styles.select_checkbox}>
								<Checkbox checked={arr?.length === state?.length} onChange={handlerCheckbox}>
									Выбрать все
								</Checkbox>
							</div>
						</>
					)}
				>
					{projects && projects.map((option) => renderOptions(option))}
				</Select>
			</div>
		</Spin>
	)
}

export default ProjectSelect

function renderOptions(option: IOptions) {
	return (
		<Option key={option.value} value={option.value} data-code={option.code}>
			<Tooltip title={option.code} placement="right">
				{option.label}
			</Tooltip>
		</Option>
	)
}
