const actionsUsers =  [
	{
		id: '72',
		selector: '.tour-bulk-actions',
		content: 'Для выполнения массового действия выберите тип массового действия, которое хотите совершить. Далее выберите записи таблицы и нажмите “Применить”',
		role: [],
	},
	{
		id: '76',
		selector: '.ant-table',
		content:
			'Таблица справочной информации отражает текущие данные, которые используются в проектах и задачах системы, позволяет отсортировать данные, сделать их видимыми и невидимыми для пользователей системы, а также удалить и отредактировать данные',
		role: [],
	},
]

export default actionsUsers
