import { notification } from 'antd';
import React, { useEffect } from 'react';
import { Notification as NotificationEvent, NotificationModel } from '..';
import InnerHTMLFunction from 'utils/innerHTMLFunction';
import { useSet } from 'shared/lib/hooks/use-set';



const Notification = () => {
	const keys = useSet<string>();

	const openNotification = ({ type, info }: NotificationModel.NotificationType) => {
		const key = `${info.status}-${type}-${info.message}`;
		if (keys.has(key)) return;

		const message = typeof info.message === 'string' ? info.message : 'Неизвестная ошибка';

		notification[type]({
			message,
			description: info?.description && (
				<div dangerouslySetInnerHTML={InnerHTMLFunction(info.description)} />
			),
			duration: 2,
			onClose: () => {
				keys.delete(key);
			}
		});
		keys.add(key);
	};

	useEffect(() => {
		const unsubscribe = NotificationEvent.subscribe(openNotification);
		return () => unsubscribe();
	}, []); //eslint-disable-line

	return null;
};
export default Notification;
