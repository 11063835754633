import { z } from 'zod'
import { FilterRequest, Id_Name_Code_Schema, SearchResponse } from 'shared/types/common'
import { Nullable } from 'models/common'

export const InternetResourceShortSchema = z.object({
	id: z.string(),
	name: z.string(),
	domainLink: z.string(),
	hidden: z.boolean(),
})
export const InternetResourceSchema = z.object({
	id: z.string(),
	name: z.string(),
	domainLink: z.string(),
	hidden: z.boolean(),
	isLocal: z.boolean(),
	type: Id_Name_Code_Schema.nullable(),
	image: z.string().optional(),
})

export type InternetResourceShort = z.infer<typeof InternetResourceShortSchema>
export type InternetResource = z.infer<typeof InternetResourceSchema>

export type InternetResourceRequest = {
	domainLink: string
	name: string
	hidden: boolean
	image: Nullable<string>
	isLocal: boolean
	typeId: string
}

export type FilterData = {
	searchString?: string
	hidden?: boolean
	isLocal?: boolean
	typeIds?: string[]
}

export type Filter = FilterRequest<FilterData>
export type InternetResources = SearchResponse<InternetResource>
