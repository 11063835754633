import { combineReducers } from 'redux'

import usersReducer from './users-reducer'
import optionsNotificationsReducer from "./notifications-reducer";
import personalSettingReducer from "./personal-setting-reducer";

const reducer = combineReducers({
	users: usersReducer,
	notifications: optionsNotificationsReducer,
	setting: personalSettingReducer,
})

export type TOptionsState = ReturnType<typeof reducer>

export default reducer
