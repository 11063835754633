import {AxiosResponse} from 'axios'
import $api from '../http'
import {TLogsAction} from "../models/logs";

/**
 * @category Service
 * @class UsersActionsService
 */
export default class UsersActionsService {
	/**
	 * Логирование действий пользователя
	 */
	static async sendActions(data: TLogsAction[]): Promise<AxiosResponse<any>> {
		return $api.post<any>('/users/actions', data)
	}
}
