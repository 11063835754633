import { z } from 'zod'

const fieldProjectObjectLinksSchema = z.object({
	addIds: z.array(z.string()).optional(),
	deleteIds: z.array(z.string()).optional(),
})
export type fieldProjectObjectLinks = z.infer<typeof fieldProjectObjectLinksSchema>
export const ProjectObjectLinksSchema = z.object({
	projectIds: z.array(z.string()),
	contentThematics: fieldProjectObjectLinksSchema.optional(),
	tags: fieldProjectObjectLinksSchema.optional(),
	genres: fieldProjectObjectLinksSchema.optional(),
	categories: fieldProjectObjectLinksSchema.optional(),
	regionsAbout: fieldProjectObjectLinksSchema.optional(),
	contentLocations: fieldProjectObjectLinksSchema.optional(),
	awards: fieldProjectObjectLinksSchema.optional(),
	projectModeratorLabels: fieldProjectObjectLinksSchema.optional(),
	projectContentEditorLabels: fieldProjectObjectLinksSchema.optional(),
	filmClusters: fieldProjectObjectLinksSchema.optional(),
})
export type ProjectObjectLinks = z.infer<typeof ProjectObjectLinksSchema>
