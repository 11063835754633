import {z} from 'zod'
import {FilterRequest, SearchResponse} from 'shared/types/common'

export const LegalFormSchema = z.object({
    id: z.string(),
    code: z.string(),
    shortName: z.string(),
    fullName: z.string(),
    hidden: z.boolean(),
})

export type LegalForm = z.infer<typeof LegalFormSchema>

export type LegalFormRequest = {
    code: string | null,
    shortName: string,
    fullName: string,
    hidden: boolean,
}

export type FilterData = {
    searchString?: string
    hidden?: boolean
}

export type Search = SearchResponse<LegalForm>
export type Filter = FilterRequest<FilterData>
