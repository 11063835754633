import { FileType } from 'shared/types/file';
import env from 'shared/config/env';
import { Player } from 'video-react';

type Props = { 
	file: FileType;
};
const path = `${env.API_ENDPOINT}/files/`;
const VideoReact = ({ file }: Props) => {
	return (
		<Player crossOrigin={'use-credentials'} preload="metadata">
			<source src={`${path}${file.id}`}  />
		</Player>
	)
};

export default VideoReact;