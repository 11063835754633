import {TContentsForm, TContentsResponse, TTaskForm, TTaskResponse} from "models/task";
import {OptionsTasksDto, TDefault} from "./task-form.dto";
import {TInfoFormTask} from "../../task-form";
import taskValidateRules from "utils/validation/task-validate-rules";
import { transformMaterialType} from "../../utils/utils";
import moment from "moment";
import {PROJECT_CREATE_DATE} from "constants/variables";

export class StepSecondDto  {
    description: TDefault<string>
    contents: TDefault<TContentsForm[] | null>
    reachRecordsScreenshotRequired: TDefault<boolean>
    reachRecordsCounterScreenshotRequired: TDefault<boolean>
    postingRecordsScreenshotRequired: TDefault<string>
    contentRecordDescriptionContractorVisible: TDefault<boolean>
    contentTagsContractorVisible: TDefault<boolean>
    regionTagsContractorVisible: TDefault<boolean>
    contentTimeCodeRequired: TDefault<boolean>
    reachRecordsReactionAmountRequired: TDefault<boolean>
    materialTypeContent: TDefault<string>
    constructor(task?:TTaskResponse | null) {
        this.description = task && task.description  ? {value: task.description} : {value: ''}
        this.contents = task && task.contents.length
            ? {value:  task.contents.map((el) => new ContentsTasksDto(el))}
            : {value: null}

        this.reachRecordsScreenshotRequired = task && task.reachRecordsScreenshotRequired ? {value: task.reachRecordsScreenshotRequired} : {value: false}
        this.reachRecordsCounterScreenshotRequired = task && task.reachRecordsCounterScreenshotRequired ? {value: task.reachRecordsCounterScreenshotRequired} : {value: false}
        this.postingRecordsScreenshotRequired = task && task.postingRecordsScreenshotRequired ? {value: 'true'} : {value: 'false'}
        this.contentRecordDescriptionContractorVisible = task && task.contentRecordDescriptionContractorVisible ? {value: task.contentRecordDescriptionContractorVisible} : {value: false}
        this.reachRecordsReactionAmountRequired = task && task.reachRecordsReactionAmountRequired ? {value: task.reachRecordsReactionAmountRequired} : {value: false}
        this.regionTagsContractorVisible = task && task.regionTagsContractorVisible !== null ? {value: task.regionTagsContractorVisible} : {value: false}
        this.contentTimeCodeRequired = task && task.contentTimeCodeRequired !== null ? {value: task.contentTimeCodeRequired} : {value: false}
        this.materialTypeContent = task ? {value: transformMaterialType(task.defaultIsContent, task.defaultIsAccountedContent)} : {value: 'accountedContent'}

        if (task?.project.projectCreateDate && moment(task?.project.projectCreateDate).isAfter(moment(PROJECT_CREATE_DATE))) { 	// Определяем, новый ли проект, если новый contentTagsContractorVisible будет скрыто и определяем по ключу проекта-isContentTaggingRequired
            this.contentTagsContractorVisible = task ? {value: task.project.isContentTaggingRequired} : {value: false}
        } else {
            this.contentTagsContractorVisible = task && task.contentTagsContractorVisible !== null ? {value: task.contentTagsContractorVisible} : {value: false}
        }

    }
    validate(info: TInfoFormTask) {
        for (const fieldName in this) {
            // @ts-ignore
            this[fieldName].isDisabled = validate(fieldName, info)
        }
        return this;
    }
    validateTypeProject(type?: string) {
        if (type === 'REPORT') {
            this.contents = {value: null}
            this.contentRecordDescriptionContractorVisible = {value: false}
            this.contentTagsContractorVisible = {value: false}
            this.regionTagsContractorVisible = {value: false}
            this.contentTimeCodeRequired = {value: false}
        } else {
            this.reachRecordsScreenshotRequired = {value: false}
            this.reachRecordsCounterScreenshotRequired = {value: false}
            this.postingRecordsScreenshotRequired = {value: 'false'}
            this.reachRecordsReactionAmountRequired = {value: false}
        }
        return this;
    }
    validateAutofillParameters() {
        this.materialTypeContent.isDisabled = true
        return this
    }
}

class ContentsTasksDto implements TContentsForm {
    contentCount;
    id;
    contentFormatType;
    isAccountedContent;
    isContent;
    constructor(model: TContentsResponse) {
        this.contentCount = model.contentCount
        this.id = model.id
        this.contentFormatType = new OptionsTasksDto(model.contentFormatType)
        this.isAccountedContent = model.isAccountedContent
        this.isContent = model.isContent
    }
}


function validate(fieldName: keyof TTaskForm, info: TInfoFormTask): boolean | undefined {
    if (!info) return undefined
    const { role, projectStatus, status } = info
    if (projectStatus === 'ACTUAL' || projectStatus === 'ARCHIVED') {

        //todo нужно убрать эти функции и расписать сдесь для каждого поля в этом DTO, и валидацию выше перенести ниже
        if (role === 'ROLE_ADMIN') return taskValidateRules.roleAdmin(status, fieldName)
        if (role === 'ROLE_ADMIN_IS') return taskValidateRules.roleAdminIs(status, fieldName)
        else if (role === 'ROLE_COORDINATOR') return taskValidateRules.roleCoordinator(status, fieldName)
        else if (role === 'ROLE_CONTRACTOR') return taskValidateRules.roleContractor(status, fieldName)
        else if (role === 'ROLE_PRODUCER') return taskValidateRules.roleProducer(status, fieldName)
        else if (role === 'ROLE_ANALYST') return taskValidateRules.roleAnalyst(status, fieldName)
        else if (role === 'ROLE_FINANCIER') return taskValidateRules.roleFinancier(status, fieldName)
        else if (role === 'ROLE_PROJECT_MANAGER') return taskValidateRules.roleProjectManager(status, fieldName)
        else if (role === 'ROLE_CONTENT_EDITOR') return taskValidateRules.roleContentEditor(status, fieldName)

    } else return true
}
