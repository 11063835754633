import {z} from "zod";
import {RoleSchema} from "./user";
import {FilterRequest, Id_Name_Code_Schema, SearchResponse} from 'shared/types/common'

export const UserActionSchema = z.object({
    user: z.object({
        id: z.string(),
        lastName: z.string(),
        firstName: z.string(),
        middleName: z.string()
    }),
    role: RoleSchema,
    ipAddress: z.string(),
    dateTime: z.string(),
    actionName: z.string(),
    routingPageAddress: z.string(),
    requestUrl: z.string(),
    requestMethod: z.string(),
    methodStatusCode: z.string(),
    actionInformation: z.string(),
    actionParameters: z.object({
        additionalProp1: z.string(),
        additionalProp2: z.string(),
        additionalProp3: z.string()
    })
})

export type UserAction = z.infer<typeof UserActionSchema>
export type UserActionsResponse = SearchResponse<UserAction>

export type UserActionsFilterData = {
    searchString?: string
    beginDate?: string | null,
    endDate?: string | null,
    actionTypeIds?: string[],
    roleIds?: string[],
    userIds?: string[]
}

export type UserActionsFilter = FilterRequest<UserActionsFilterData>

export const UserActionsSearchSchema = UserActionSchema.extend({
    id: z.string(),
})

export type UserActionsSearch = SearchResponse<z.infer<typeof UserActionsSearchSchema>>

export const UserActionTypesSchema = Id_Name_Code_Schema.extend({
    enabled: z.boolean(),
    template: z.string()
})

export type UserActionTypes = z.infer<typeof UserActionTypesSchema>

export type UserActionTypesRequest = {
    code: string | null
    name: string
    enabled: boolean
    template: string,
}

export type UserActionsTypesFilterData = {
    searchString?: string
    hidden?: boolean
}

export type UserActionsTypes = SearchResponse<UserActionTypes>
export type UserActionsTypesFilter = FilterRequest<UserActionsTypesFilterData>

export const UserActionsStatisticSchema = z.object({
    actionsNumber: z.number(),
    ipAddress: z.string(),
    lastLoginDateTime: z.string(),
    roleName: z.string(),
    userFullName: z.string(),
    userId: z.string(),
    userSessionId: z.number(),
    username: z.string(),
    websiteSpentTime: z.string(),
});

export type UserActionsStatisticFilterData = {
    searchString?: string
    roleNames?: string[]
    beginDate?: string | null
    endDate?: string | null
}
export type UserActionsStatisticFilter = FilterRequest<UserActionsStatisticFilterData>

export type UserActionsStatistic = z.infer<typeof UserActionsStatisticSchema>;
export type UserActionsStatisticSearch = SearchResponse<UserActionsStatistic>

export const UserActionDetailsSchema = z.object({
    id: z.string(),
    requestMethod: z.string().nullable(),
    requestPayload: z.string().nullable(),
    requestResponse: z.string().nullable(),
    requestUrl: z.string()
});


export type UserActionDetails = z.infer<typeof UserActionDetailsSchema>;




