import React, { FC, useCallback } from 'react';
import { Empty } from 'antd'
import { FilesSlider } from 'features/files-uploader';
import { FileType } from 'shared/types/file'
import { useDispatch } from 'react-redux';
import { GetContentStartAction } from 'store/content/content/actions';

type Props = {
	files?: FileType[] | null
}

const ViewContent: FC<Props> = ({ files }) => {
	const put = useDispatch();
	const refetchFiles = useCallback(() => {
		put(GetContentStartAction())
	}, [put]);
	if (!files?.length) return <Empty />

	return <FilesSlider files={files || []} refetchFiles={refetchFiles}/>
}
export default ViewContent
