import React, { FC } from 'react'
import { useQuery } from 'react-query'
import './modal-content-format.less'
import { Modal, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { selectParams } from 'store/selectors'
import NameCenterCell from 'components/table-cells/name-center-cell'
import { useSelector } from 'react-redux'
import useHandlerModal from 'hooks/handlModal'
import { ProjectApi, ProjectTypes } from 'entities/project'
import { useErrorNotification } from 'shared/lib/error-notification'
import NameCellWithCount from "../../table-cells/name-cell-with-count";

type ModalContentFormatProps = {
	status: boolean
	projectId?: string
}

const ModalContentFormat: FC<ModalContentFormatProps> = ({ status, projectId }) => {
	const errorNotification = useErrorNotification()
	const { isVisible, closeModal } = useHandlerModal(status)
	const { id } = useSelector(selectParams)

	const ProjectId = projectId ? projectId : id

	const { data: contentFormats = [], isLoading: contentFormatsLoading } = useQuery({
		queryKey: [ProjectApi.ProjectViewDetailsController.contentFormats.key(ProjectId)],
		queryFn: () => ProjectApi.ProjectViewDetailsController.contentFormats.fetch(ProjectId),
		onError: errorNotification,
	})
	const { data: approvedContentFormats = [], isLoading: approvedContentFormatsLoading } = useQuery({
		queryKey: [ProjectApi.ProjectViewDetailsController.approvedContentFormats.key(ProjectId)],
		queryFn: () => ProjectApi.ProjectViewDetailsController.approvedContentFormats.fetch(ProjectId),
		onError: errorNotification,
	})

	const ContentFormatsColumns: ColumnsType<ProjectTypes.ContentFormats> = [
		{
			title: 'Тип учета',
			dataIndex: 'isAccountedContent',
			key: 'isAccountedContent',
			render: (_, record) => NameCenterCell(record.isAccountedContent ? 'Учитывается' : 'Не учитывается'),
			width: 100,
			sorter: false,
		},
		{
			title: 'Тип материала',
			dataIndex: 'isContent',
			key: 'isContent',
			render: (_, record) => NameCenterCell(record.isContent ? 'Контент' : 'Рабочий материал'),
			width: 100,
			sorter: false,
		},
		{
			title: 'Единица контента',
			dataIndex: ['type', 'name'],
			key: 'name',
			render: NameCenterCell,
			width: 100,
			sorter: false,
		},
		{
			title: 'Количество контента',
			dataIndex: 'contentCount',
			key: 'contentCount',
			render: (count: string) => NameCenterCell(count + ' шт'),
			width: 150,
			sorter: false,
		},
		{
			title: 'Формат сдачи контента',
			dataIndex: 'submissionForms',
			key: 'submissionForms',
			render: (_, { submissionForms }) => {
				return NameCenterCell(submissionForms.map((el) => el.name).join(', '))
			},
			width: 100,
			sorter: false,
		},
		{
			title: 'Требования к файлу',
			dataIndex: 'submissionForms',
			key: 'requirements',
			render: (_, { submissionForms }) => {
				return NameCenterCell(
					submissionForms
						.filter((el) => el.requirements !== null)
						.map((el) => el.requirements)
						.join(' / ')
				)
			},
			width: 250,
			sorter: false,
		},
	]

	const ApprovedContentSubmissionColumns: ColumnsType<ProjectTypes.ApprovedContentFormats> = [
		{
			title: 'Тип учета',
			dataIndex: 'isAccountedContent',
			key: 'isAccountedContent',
			render: (_, record) =>
				NameCenterCell(
					record.isAccountedContent === null
						? 'Не определено'
						: record.isAccountedContent
						? 'Учитывается'
						: 'Не учитывается'
				),
			width: 100,
			sorter: false,
		},
		{
			title: 'Тип материала',
			dataIndex: 'isContent',
			key: 'isContent',
			render: (_, record) => NameCenterCell(record.isContent ? 'Контент' : 'Рабочий материал'),
			width: 100,
			sorter: false,
		},
		{
			title: 'Единица контента',
			dataIndex: 'contentFormatType',
			key: 'contentFormatType',
			render: (_, record) => NameCenterCell(record.contentFormatType.name),
			width: 100,
			sorter: false,
		},
		{
			title: 'Формат сдачи контента',
			dataIndex: 'contentSubmissionForms',
			key: 'contentSubmissionForms',
			render: NameCellWithCount,
			width: 100,
			sorter: false,
		},
		{
			title: 'Количество файлов согласованного контента',
			dataIndex: 'filesCount',
			key: 'filesCount',
			render: (_, record) => {
				const name = record?.fileCount ? `${record?.fileCount} шт` : '__'
				return NameCenterCell(name)
			},
			width: 150,
			sorter: false,
		},
	]

	return (
		<Modal
			className="modal-content-format"
			title="Единицы контента проекта"
			visible={isVisible}
			footer={null}
			maskClosable={true}
			onCancel={closeModal}
		>
			<div>
				<h1>Требования к формату контента</h1>
				<Table
					loading={contentFormatsLoading}
					className="table"
					sticky
					scroll={{ x: 510 }}
					columns={ContentFormatsColumns}
					dataSource={contentFormats}
					rowKey="id"
					pagination={false}
				/>
			</div>
			<div>
				<h1>Данные о количестве реализованных единиц контента проекта</h1>
				<Table
					loading={approvedContentFormatsLoading}
					className="table"
					sticky
					scroll={{ x: 510 }}
					columns={ApprovedContentSubmissionColumns}
					dataSource={approvedContentFormats}
					rowKey="id"
					pagination={false}
				/>
			</div>
		</Modal>
	)
}

export default ModalContentFormat
