import { useReducer, useRef } from 'react';

export function useSet<T>(values?: T[]) {
	const setRef = useRef<Set<T>>(new Set(values));
	const [, reRender] = useReducer((x) => x + 1, 0);

	setRef.current.add = (...args) => {
		const res = Set.prototype.add.apply(setRef.current, args);
		reRender();
		return res;
	};

	setRef.current.clear = (...args) => {
		Set.prototype.clear.apply(setRef.current, args);
		reRender();
	};

	setRef.current.delete = (...args) => {
		const res = Set.prototype.delete.apply(setRef.current, args);
		reRender();

		return res;
	};

	return setRef.current;
}