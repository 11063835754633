import {put} from "redux-saga/effects";
import {AxiosResponse} from "axios";
import {GetProjectErrorAction, GetProjectStartAction, GetProjectSuccessAction,} from "store/projects/actions";

import {GetFileStartAction} from "store/files/actions";
import {NotificationOpen} from "store/notification/actions";
import { ProjectTypes } from "entities/project";
import Api from "entities/project/api/project";
import CreateErrorMessage from "utils/validation/createErrorMessage";
import ZodParser from "shared/services/zod-parser";


export function* getProject({projectId}: ReturnType<typeof GetProjectStartAction>) {
    try {
        const { data: project }: AxiosResponse<ProjectTypes.Project> = yield Api.getProject(projectId);
        ZodParser.parse(ProjectTypes.ProjectSchema, project, `GetProjectStartAction /projects/${projectId}`)

        yield put(GetProjectSuccessAction(project));

        if (project.files && project.files.length > 0) {
            for (const file of project.files) {
                yield put(GetFileStartAction(file.id, file.type))
            }
        }
        if (project.image) {
            yield put(GetFileStartAction(project.image.id, project.image.type))
        }

    } catch (error: any) {
        yield put(GetProjectErrorAction(error))
        const message = CreateErrorMessage.response(error)
        yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось получить проект'))
    }
}
