import { z } from "zod";
import { VoteSchema } from '../vote';
import { TasksRecordStatusEnum } from '../record-status';
import { FileSchema } from '../../../../shared/types/file';

export const PostingRecordsSchema = z.object({
	id: z.string(),
	planPublicationDate: z.string().nullable(),
	actualPublicationDate: z.string().nullable(),
	publishedName: z.string().nullable(),
	contentTaskRecord: z.object({ id: z.string(), name: z.string() }).nullable(),
	link: z.string().nullable(),
	resource: z.object({
		id: z.string(),
		name: z.string(),
		domainLink: z.string(),
		isLocal: z.boolean(),
		type: z.object({ id: z.string(), code: z.string(), name: z.string() }).nullable(),
		hidden: z.boolean(),
		image: z.string()
	}).nullable(),
	contentUnitDimension: z.string().nullable(),
	linkedFilesCount: z.number(),
	screenshots: z.array(FileSchema).optional(),
	screenshotDate: z.string().nullable(),
	status: TasksRecordStatusEnum,
	votes: z.array(VoteSchema).nullable(),
	lastTaskRecordWithVotesResponse: z.object({
		id: z.string(),
		votes: z.array(VoteSchema).nullable(),
	}).nullable(),
	ageCategory: z.string().nullable(),
	isOriginal: z.boolean(),
	isReplaced: z.boolean(),
	task: z.object({
		deadline: z.string(),
		number: z.number(),
		id: z.string(),
		name: z.string(),
		code: z.string(),
	}).optional()
});
export type PostingRecords = z.infer<typeof PostingRecordsSchema>
