import {useQuery, UseQueryResult} from 'react-query'
import {transformArrayIdNameForValue} from 'utils/transformObjectFeels'
import {useMemo} from 'react'
import {getContentModuleSection} from "../../api";
import {Id_Name_Code} from "../../types/common";

type onSuccessType = (data: Id_Name_Code[]) => void
export function useGetContentModuleSection(onSuccess?: onSuccessType): UseQueryResult<Id_Name_Code[]> {
    return useQuery({
        queryKey: [getContentModuleSection.key],
        queryFn: () => getContentModuleSection.fetch(),
        onSuccess,
    })
}
export function useGetContentModuleSectionOptions(onSuccess?: onSuccessType) {
    const { data = [], ...rest } = useGetContentModuleSection(onSuccess)
    const options = useMemo(() => transformArrayIdNameForValue(data), [data])
    return { data: options, ...rest }
}
