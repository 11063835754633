import {FileType, Nullable, T_ID_NAME, TEmail, TPaginationDefault, TPhone} from "../common";
import {TOrganizations} from "../users-list/user-form";
import {UserTypes} from 'entities/user'
import {OrganizationLegalFormTypes} from 'entities/organization'

export type TPersonalDataOrganizations = {
    id: string
    name: string
    isContentCustomer: true,
    region: T_ID_NAME,
    legalForm: {
        id: string,
        shortName: string
        code: string
        fullName: string
        hidden: boolean
    },
    emails: TEmail[],
    phones: TPhone[]
}

type TPersonalData = {
    leader?: boolean
    contactPhone: string;
    contactPhoneCount: number;
    email: string;
    emailCount: number;
    firstName: string;
    image?: FileType | null;
    lastName: string;
    middleName: string | null;
    position: string;
    organizationINN: string
    organizationId: string
    organizationShortName: string
    organizationCount: number
    isReceivingNotificationsConfirmed: boolean
    isUserAgreementConfirmed: boolean
    emailConfirmed: boolean,
    userPhoneConfirmed: boolean
    telegramConfirmed: boolean
    organizations: TPersonalDataOrganizations[]
    contentDownloadAccess: boolean
    contentStorageLinkAccess: boolean
    contentViewAccess: boolean
    deletedDatetime: Nullable<string>
    privateProjectViewAccess: boolean
}

export type TPerson = {
    id: string
    roleName: UserTypes.RoleName
    role?: UserTypes.Role
    isTutor: boolean
    tutorVisible: boolean
    status?: string
    isMain?: boolean | null
} & Omit<TPersonalData, 'position'> & TKeycloakUserData & TUSerAction

export type TKeycloakUserData = {
    keycloakId: Nullable<string>;
    keycloakPhone: Nullable<string>;
    keycloakEmail: Nullable<string>;
    keycloakLastSyncDatetime: Nullable<string>;
}
export type TUSerAction = {
    lastActionDateTime?: string
}
export type TStatus = 'ACTIVE' | 'ARCHIVE' | 'BLOCKED' | boolean;
//todo remove role type
export type TRole = 'ROLE_ADMIN' | 'ROLE_COORDINATOR' |
    'ROLE_PRODUCER' |
    'ROLE_CONTRACTOR' |
    'ROLE_ANALYST' |
    'ROLE_FINANCIER'|
    'ROLE_LAWYER' |
    'ROLE_CLIENT' |
    'ROLE_MODERATOR'|
    'ROLE_VIEWER' |
    'ROLE_AGENT' |
    'ROLE_CONTENT_EDITOR' |
    'ROLE_PROJECT_MANAGER' |
    'ROLE_ADMIN_IS' |
    'ROLE_INTEGRATION_USER'

export type TUserCredentials = {
    username: string;
    password: string;
}

//Todo изменить на TCurrentUser
export type TUser = {
    id: string;
    emailConfirmed: boolean
    allMainEmailsConfirmed: boolean
    allMainPhonesConfirmed: boolean
    telegramConfirmed: boolean
    isUserAgreementConfirmed: boolean,
    isReceivingNotificationsConfirmed: boolean,
    isDownloadRightsConfirmed: boolean,
    userPhoneConfirmed:boolean,
    personalData?: TPersonalData;
    role: UserTypes.RoleName;
    username: string;
    contentDownloadAccess: boolean
    contentStorageLinkAccess: boolean
    contentViewAccess: boolean
} & TKeycloakUserData

export type TUsersResponse = {
    content: TPerson[] | null;
} & TPaginationDefault

export type TUsersAction = {
    actionsNumber: 0,
    ipAddress: string,
    lastLoginDateTime: string,
    roleName: string,
    userFullName: string,
    userId: string,
    userSessionId: number,
    username: string,
    websiteSpentTime: {
        nano: number,
        negative: boolean,
        seconds: number,
        units: [
            {
                dateBased: boolean,
                durationEstimated: boolean,
                timeBased: boolean
            }
        ],
        zero: boolean
    }
}
export type TUserAction = {
    actionName: string,
    actionInformation: string,
    dateTime: string,
    ipAddress: string,
    authKeyUserFirstName?: string,
    authKeyUserLastName?: string,
    authKeyUserId?: string,
    authKeyUser?: {
        id: string
        lastName: string
        firstName: string
        middleName: string
    }
    actionParameters?: TUserTUserActionParametersParameters
}


export type TUserTUserActionParametersParameters = {
    project_id?: string
    project_code?: string
    user_email?: string
    user_name?: string
    task_id?: string
    task_number?: string
    record_id?: string
    link?: string
    Guide_name?: string
    page_link?: string
    type?: string
    notification_name?: string
    task_status?: string
    user_id?: string
    action_name?: string
    file_id?: string
}

export enum TSearchStringType {
    CS = 'cs',
    EQ = 'eq'
}
type TFieldFilters = {
    field?: 'DELETED_DATETIME'| 'LAST_ACTION_DATETIME' | 'KEYCLOAK_LAST_SYNC_DATETIME'
    startDate?: string
    endDate?: string
}

//Todo перенести в entities
export type TFilterData = {
    roleNames?: string[] | any;
    roleIds?: string[] | any;
    userIds?: string[] | any;
    searchString?: string;
    searchStringType?: TSearchStringType.CS | TSearchStringType.EQ;
    status?: 'ACTIVE' | 'BLOCKED' | 'ARCHIVE'
    beginDate?: string | null,
    endDate?: string | null,
    bulkAction?: string | null
    hidden?: boolean | null
    legalFormId?: string | null
    regionId?: string | null
    contentViewAccess?: boolean
    contentDownloadAccess?: boolean
    contentStorageLinkAccess?: boolean
    isDeleted?: boolean
    isKeycloakSynced?: boolean
    fieldFilters?: TFieldFilters[]
    isLocal?: boolean,
    typeId?: boolean,
    thematicIds?: string[]
    firstLvlTagIds?: string[]
    firstLevelTagIds?: string[]
    secondLvlTagIds?: string[]
    startDate?: string | null,
    level?: number,
    isForNationalProject?: boolean
}

export type TUserOptionsFilter = {
    filterData?: TFilterData
} & TPaginationDefault

export type TUserProfileForm = {
    firstName: string,
    lastName: string,
    role: any,
    middleName: string,
    login:string,
    image: any,
    imageId: string | null,
    organizations: TOrganizations[],
}

export type TOrganizationsUser = {
    emails: TEmail[]
    id: string
    inn: string
    isContentCustomer: boolean
    legalForm: OrganizationLegalFormTypes.LegalForm
    name: string
    phones: TPhone[]
    region: T_ID_NAME
}
