import { ModalProps } from 'antd';
import React from 'react';
import {eventBus} from "shared/services";

export type ModalType = {
	component: React.ReactNode
} & ModalProps;
export type OpenModalTypeParams = {
	key?: string
	closePrevious?: boolean
} & ModalType
const eventName = 'ModalOpen';
export const subscribeOpen = (callback: Function) => {
	return eventBus.subscribe(eventName, callback);
};

export const open = (data: OpenModalTypeParams) => {
	eventBus.broadcast(eventName, data);
};
