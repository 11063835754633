import React, {MouseEvent, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Radio, RadioChangeEvent, Select, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import Api from "entities/project/api/project";
import {ExtendedSearchModel} from 'features/extended-search';
import {Fields, Stores} from 'features/extended-search/types';
import {transformArrayForValue} from "utils/transformObjectFeels";
import {useQuery} from "react-query";
import {useGetInternetResourceTypeListOptions} from "shared/lib/hooks/use-get-internet-resource-list";
import {InternetResourceLib} from 'entities/internet-resource'

const {Option} = Select;
const optionsFields: Array<{label: string, value: Stores.ChannelsKeys | 'Параметр неважен'}> = [
    {label: 'Параметр неважен', value: 'Параметр неважен'},
    {label: 'Интернет-ресурс', value: Fields.FieldsEnums.INTERNET_RESOURCE},
    {label: 'Канал проекта', value: Fields.FieldsEnums.CHANNEL},
    {label: 'Вид площадок размещения проекта', value: Fields.FieldsEnums.PROJECT_INTERNET_RESOURCE_TYPE},
    {label: 'Вид площадок размещения контента проектов', value: Fields.FieldsEnums.POSTING_RECORD_INTERNET_RESOURCE_TYPE},
];
function useGetsData() {

    const internetResources = InternetResourceLib.useGetInternetResourceOptions()

    const channels = useQuery({
        queryKey: [Api.projectsChannelsKey],
        queryFn: async () => {
            const {data} = await Api.getProjectsChannels()
            return transformArrayForValue(data)
        },
        refetchOnWindowFocus: false
    })
    const internetResourceTypeList = useGetInternetResourceTypeListOptions()

    return {
        loading: internetResources.isLoading || channels.isLoading || internetResourceTypeList.isLoading,
        options: {
            INTERNET_RESOURCE: internetResources.data || [],
            CHANNEL: channels.data || [],
            PROJECT_INTERNET_RESOURCE_TYPE: internetResourceTypeList.data || [],
            POSTING_RECORD_INTERNET_RESOURCE_TYPE: internetResourceTypeList.data || []
        }}
}

export const ChannelsItem = () => {
    const put = useDispatch();
    const {loading, options} = useGetsData();

    const {typeContent} = useSelector(ExtendedSearchModel.selectors.main)
    const channels = useSelector(ExtendedSearchModel.selectors.channels)

    const disabledOptions = useMemo(() => {
        return channels[typeContent].fields.map((el) => el.field)
    }, [channels, typeContent]);

    const onGroupChange = (index: number) => (e: RadioChangeEvent) => {
        const arr = [...channels[typeContent].fields];
        arr[index].isAndOrBesides = e.target.value;
        put(ExtendedSearchModel.actions.setChannels(typeContent, arr))
    }

    const onSelectChangeType = (index: number) => (value: Stores.ChannelsKeys | 'Параметр неважен') => {
        const newArray = [...channels[typeContent].fields];
        newArray[index]["field"] = value;
        newArray[index]["values"] = [];
        put(ExtendedSearchModel.actions.setChannels(
            typeContent,
            newArray
        ))
    }

    const onSelectChangeValue = (index: number) => (value: Fields.FieldFilter["values"]) => {
        const newArray = [...channels[typeContent].fields];
        newArray[index]["values"] = value;
        put(ExtendedSearchModel.actions.setChannels(
            typeContent,
            newArray
        ))
    }

    const addGroup = () => {
        put(ExtendedSearchModel.actions.setChannels(typeContent, [
            ...channels[typeContent].fields,
            {field: 'Параметр неважен', isAndOrBesides: false, values: []}
        ]))
    }

    const onRemoveHandler = (index: number) => (_event: MouseEvent<HTMLDivElement>) => {
        const newArray = [...channels[typeContent].fields];
        newArray.splice(index, 1)
        put(ExtendedSearchModel.actions.setChannels(
            typeContent,
            newArray
        ))
    }

    return (
        <Spin spinning={loading}>
            <div className="item-wrapper" key={typeContent}>
                {channels[typeContent].fields.map((item, index) => (
                    <div className="item-wrapper__group" key={item.field + index}>
                        <div className="item-wrapper__items">
                            <div className="item">
                                <div className="form-group">
                                    <Select
                                        value={item.field}
                                        onChange={onSelectChangeType(index)}
                                        placeholder={'Параметр неважен'}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {optionsFields.map((el) => (
                                            <Option
                                                disabled={disabledOptions.includes(el.value)}
                                                value={el.value}
                                                key={el.value}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="or">
                                <Radio.Group value={item.isAndOrBesides} onChange={onGroupChange(index)}>
                                    <Radio value={true}>И</Radio>
                                    <Radio value={false}>Или</Radio>
                                </Radio.Group>
                            </div>
                            <div className="item item-second">
                                <div className="form-group">
                                    <Select
                                        allowClear={true}
                                        mode={"multiple"}
                                        disabled={item.field === 'Параметр неважен'}
                                        maxTagCount={"responsive"}
                                        value={item.values}
                                        onChange={onSelectChangeValue(index)}
                                        placeholder={'Параметр неважен'}
                                        filterOption={(input, option) => {
                                            if (option === undefined || option === null) return false;
                                            return option['data-name'].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {item.field !== 'Параметр неважен' && options[item.field].map((el) => (
                                            <Option
                                                value={el.value}
                                                key={el.value}
                                                data-name={el.label}
                                            >
                                                {el.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {channels[typeContent].fields.length > 1 && (
                            <div className="item__remove" onClick={onRemoveHandler(index)}>
                                <DeleteOutlined />
                            </div>
                        )}
                    </div>
                ))}
                {channels[typeContent].fields.length < optionsFields.length - 1 && (
                    <div className="btn">
                        <Button type={'link'} onClick={addGroup}>+ Добавить условие</Button>
                    </div>
                )}
            </div>
        </Spin>
    )
}
