import {InferValueTypes} from "../../models/common";
import * as actions from './actions';
import {ModalsActionTypes} from "./action-types";
import {AuthActionTypes} from "../auth/action-types";
import {Logout} from "../auth/reducer";

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export type TModalTypes = 'team' |
    'delete-contest' |
    'delete-session' |
    'delete-organization' |
    'delete-organizations'|
    'delete-project' |
    'delete-user' |
    'delete-users' |
    'vote' |
    'vote-user' |
    'logout' |
    'duplicate' |
    'deadlines' |
    'reporting' |
    'comment' |
    'content-task' |
    'content-format' |
    'modal-error' |
    'reset-status-task-error' |
    'posting-content' |
    'form-no-save' |
    'reach-statistic' |
    'famousPeople' |
    'projectInfo' |
    'channels' |
    'projectUsers' |
    'archive' |
    'SHD' |
    'modal-user' |
    'modal-user-profile' |
    'user-contact-details' |
    'confirm-delete' |
    'task' |
    'support' |
    string

export type TModalState = {
    isOpen: boolean,
    type: TModalTypes | null,
    data: any
}

export const initialState: TModalState = {
    isOpen: false,
    type: null,
    data: null
}

export default function reducer(state: TModalState = initialState, action: ActionTypes | Logout): TModalState {
    switch (action.type) {
        case AuthActionTypes.LOGOUT:
            return {...initialState}
        case ModalsActionTypes.MODAL_OPEN:
            return {...state, type: action.typeModal, isOpen: true, data: action.data ? action.data : null}
        case ModalsActionTypes.MODAL_CLOSE:
            return {...state, type: null, isOpen: false, data: null}
        default:
            return state
    }
}
