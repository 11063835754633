import { put } from 'redux-saga/effects'
import { NotificationOpen } from '../../store/notification/actions'
import CreateErrorMessage from '../../utils/validation/createErrorMessage'
import {
	ClearEditingKey,
	GetProjectDocumentsWithFilterStartAction,
	UpdateProjectDocumentsErrorAction,
	UpdateProjectDocumentsStartAction,
	UpdateProjectDocumentsSuccessAction,
} from '../../store/project-documents/actions'
import ProjectDocumentsService from '../../services/projectDocument'

export function* updateDocuments({ documentId, data }: ReturnType<typeof UpdateProjectDocumentsStartAction>) {
	try {
		yield ProjectDocumentsService.updateDocumentId(documentId, data)
		yield put(UpdateProjectDocumentsSuccessAction())
		yield put(GetProjectDocumentsWithFilterStartAction())
		yield put(ClearEditingKey())
		yield put(NotificationOpen('success', 'Документ обновлён'))
	} catch (error: any) {
		yield put(UpdateProjectDocumentsErrorAction(error))
		const message = CreateErrorMessage.response(error)
		yield put(NotificationOpen('error', 'Ошибка', message ? message : 'Не удалось обновить документы'))
	}
}
