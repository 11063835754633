export const ProjectsActionTypes = {
    GET_PROJECTS_START: '[PROJECTS] GET_PROJECTS_START',
    GET_PROJECTS_SUCCESS: '[PROJECTS] GET_PROJECTS_SUCCESS',
    GET_PROJECTS_ERROR: '[PROJECTS] GET_PROJECTS_ERROR',

    GET_PROJECT_START: '[PROJECTS] GET_PROJECT_START',
    GET_PROJECT_SUCCESS: '[PROJECTS] GET_PROJECT_SUCCESS',
    GET_PROJECT_ERROR: '[PROJECTS] GET_PROJECT_ERROR',

    UPDATE_PROJECT_START: '[PROJECTS] UPDATE_PROJECT_START',
    UPDATE_PROJECT_SUCCESS: '[PROJECTS] UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_ERROR: '[PROJECTS] UPDATE_PROJECT_ERROR',

    DELETE_PROJECT_START: '[PROJECTS] DELETE_PROJECT_START',
    DELETE_PROJECT_SUCCESS: '[PROJECTS] DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_ERROR: '[PROJECTS] DELETE_PROJECT_ERROR',

    SET_PROJECTS_FILTER: '[PROJECTS] SET_PROJECTS_FILTER',
    SET_PROJECTS_INFO: '[PROJECTS] SET_PROJECTS_INFO',
    SET_PROJECTS_FILTER_MODAL: '[PROJECTS] SET_PROJECTS_FILTER_MODAL',

    CREATE_PROJECTS_START: '[PROJECTS] CREATE_PROJECTS_START',
    CREATE_PROJECTS_SUCCESS: '[PROJECTS] CREATE_PROJECTS_SUCCESS',
    CREATE_PROJECTS_ERROR: '[PROJECTS] CREATE_PROJECTS_ERROR',

    GET_PROJECTS_REACH_DATA_START: '[PROJECTS] GET_PROJECTS_REACH_DATA_START',
    GET_PROJECTS_REACH_DATA_SUCCESS: '[PROJECTS] GET_PROJECTS_REACH_DATA_SUCCESS',
    GET_PROJECTS_REACH_DATA_ERROR: '[PROJECTS] GET_PROJECTS_REACH_DATA_ERROR',

    GET_PROJECTS_REPORTING_START: '[PROJECTS] GET_PROJECTS_REPORTING_START',
    GET_PROJECTS_REPORTING_SUCCESS: '[PROJECTS] GET_PROJECTS_REPORTING_SUCCESS',
    GET_PROJECTS_REPORTING_ERROR: '[PROJECTS] GET_PROJECTS_REPORTING_ERROR',

    UPDATE_PROJECT_STATUS_START: '[PROJECTS] UPDATE_PROJECT_STATUS_START',
    UPDATE_PROJECT_STATUS_SUCCESS: '[PROJECTS] UPDATE_PROJECT_STATUS_SUCCESS',
    UPDATE_PROJECT_STATUS_ERROR: '[PROJECTS] UPDATE_PROJECT_STATUS_ERROR',

    BULK_PROJECT_START: '[PROJECTS] BULK_PROJECT_START',
    BULK_PROJECT_SUCCESS: '[PROJECTS] BULK_PROJECT_SUCCESS',
    BULK_PROJECT_ERROR: '[PROJECTS] BULK_PROJECT_ERROR',

    GET_PROJECTS_TYPES_START: '[PROJECTS] GET_PROJECTS_TYPES_START',
    GET_PROJECTS_TYPES_SUCCESS: '[PROJECTS] GET_PROJECTS_TYPES_SUCCESS',
    GET_PROJECTS_TYPES_ERROR: '[PROJECTS] GET_PROJECTS_TYPES_ERROR',
    GET_PROJECTS_TYPES_CLEAR: '[PROJECTS] GET_PROJECTS_TYPES_ERROR',

    DOWNLOAD_TEMPLATE_START: '[PROJECTS] DOWNLOAD_TEMPLATE_START',
    DOWNLOAD_TEMPLATE_SUCCESS: '[PROJECTS] DOWNLOAD_TEMPLATE_SUCCESS',
    DOWNLOAD_TEMPLATE_ERROR: '[PROJECTS] UNLOADING_TEMPLATE_ERROR',

    DOWNLOAD_PROJECT_LIST_START: '[PROJECTS] DOWNLOAD_PROJECT_LIST_START',
    DOWNLOAD_PROJECT_LIST_SUCCESS: '[PROJECTS] DOWNLOAD_PROJECT_LIST_SUCCESS',
    DOWNLOAD_PROJECT_LIST_ERROR: '[PROJECTS] DOWNLOAD_PROJECT_LIST_ERROR',

    UPLOAD_START: '[PROJECTS] UPLOAD_START',
    UPLOAD_SUCCESS: '[PROJECTS] UPLOAD_SUCCESS',
    UPLOAD_ERROR: '[PROJECTS] UPLOAD_ERROR',

    CLEAR_BULK_ACTIONS: '[PROJECTS] CLEAR_BULK_ACTIONS',
    CLEAR_ERROR: '[PROJECTS] CLEAR_ERROR',
} as const;
