import * as Yup from 'yup'
function ValidateObj(val: any) {
	return !!(val && val.label && val.value)
}
function ValidateArr(val: any) {
	return !!val?.length
}
export const validation = Yup.object({
	date: Yup.object({
		value: Yup.mixed().test(
			'Дата и время создания единиц контента',
			'Поле «Дата и время создания единиц контента» обязательно для заполнения',
			function (value: string | Date) {
				return !!value
			}
		),
	}).nullable(),
	name: Yup.mixed().test(
		'Название единицы контента',
		'Поле «Название единицы контента» обязательно для заполнения',
		function (value) {
			return !!value
		}
	),
	contentFormatType: Yup.mixed()
		.test('Единица контента', 'Поле «Единица контента» обязательно для заполнения', ValidateObj)
		.required('Выберите единицу контента'),
	submissionForms: Yup.mixed()
		.test('Формат сдачи контента', 'Поле «Формат сдачи контента» обязательно для заполнения', ValidateArr)
		.required('Выберите формат сдачи контента'),
})
