import React, {FC} from 'react'
import classNames from 'classnames'
import styles from './styles.module.less'
import {FieldArray, useFormikContext} from 'formik'
import {Button, Col, DatePicker, Row, Spin, Tooltip} from 'antd'
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons'
import {ProjectFormDto} from '../../../lib'
import {SelectComponent} from 'components/formic-control'
import {TaskApi} from 'entities/task'
import moment from "moment/moment";
import {Moment} from "moment";
import {TagLib} from "entities/tags";

type Props = {
	name: string
	disabled?: boolean
}
const ContentTimeLines: FC<Props> = ({ name, disabled }) => {
	const { errors } = useFormikContext<ProjectFormDto>()
	const isError = errors.stepFifth?.contentTimelines

	return (
		<>
			<div className={classNames('label', styles.timesLines_label)}>Тайм-коды контента национального проекта</div>
			<div className={styles.timesLines}>
				<FieldArray
					name={name}
					render={({ form, remove, push }) => {
						const idx = form.values.stepFifth.contentTimelines?.value.length
						const disableBtn = isError && idx !== 0
						return (
							<>
								{form.values.stepFifth.contentTimelines?.value.map((_item: any, index: number) => (
									<ContentTimeLinesItem key={index} name={name} index={index} remove={remove} disabled={disabled} />
								))}
								{disableBtn ? (
									<div className="invalid-feel">{errors.stepFifth?.contentTimelines as string}</div>
								) : (
									<Button
										disabled={disabled}
										type="link"
										onClick={() =>
											push({
												contentRecord: null,
												timelines: [],
											})
										}
									>
										<PlusCircleOutlined /> Добавить единицу контента
									</Button>
								)}
							</>
						)
					}}
				/>
			</div>
		</>
	)
}

export default ContentTimeLines

type SuffixComponentProps = {
	disabled?: boolean
	pushTimeline: Function
}
const SuffixComponent: FC<SuffixComponentProps> = ({ pushTimeline, disabled }) => {
	return (
		<Tooltip
			title={disabled ? 'Выберите согласованную единицу контента, чтобы "Добавить тайм-код"' : 'Добавить тайм-код'}
		>
			<PlusCircleOutlined
				className={disabled ? styles.disabled : styles.active}
				onClick={() => {
					!disabled && pushTimeline({ timeline: '', timelineLabel: '' })
				}}
			/>
		</Tooltip>
	)
}

type ItemProps = {
	name: string
	index: number
	remove: (index: number) => void
	disabled?: boolean
}
function ContentTimeLinesItem({ name, index, remove, disabled }: ItemProps) {
	const { values, setFieldValue } = useFormikContext<ProjectFormDto>()
	const { data: options, isLoading } = TaskApi.ContentTaskRecordController.useGetRecordsApprovedOptions({
		projectId: values?.projectId,
	})
	const {data: nationalTagOptions, isLoading: loadingNationalTag} = TagLib.useGetProjectTagsOptions({isForNationalProject: true})

	const contentRecordName = `${name}[${index}].contentRecord`
    const timelineName = `${name}[${index}].timelines`

    const handlerTime = (name: string) => (_value: Moment | null, time: string) => {
        if (time) setFieldValue(name,time)
    }
	return (
		<FieldArray
			name={`${name}[${index}].timelines`}
			render={({ remove: removeTimeline, push: pushTimeline }) => (
				<>
					<Row gutter={[5, 5]}>
						<Col lg={22} span={22}>
							<Spin spinning={isLoading}>
								<SelectComponent
									options={options}
									name={contentRecordName}
									placeholder="Выберите согласованную единицу контента"
									disabled={disabled}
									suffixIcon={
										<SuffixComponent
											disabled={!values.stepFifth.contentTimelines.value[index].contentRecord?.value || disabled}
											pushTimeline={pushTimeline}
										/>
									}
								/>
							</Spin>
						</Col>

						<Col span={2}>
							{!disabled && (
								<Tooltip title="Удалить?">
									<Button className={styles.delete} type="link" onClick={() => remove(index)} disabled={values.stepFifth.contentTimelines.isDisabled}>
										<DeleteOutlined />
									</Button>
								</Tooltip>
							)}
						</Col>
					</Row>
					{values.stepFifth.contentTimelines.value[index]?.timelines?.map((el: any, timeIndex: number) => {
						const arr = el.timeline.split(':')
						const date = new Date();
						if (el.timeline) {
							date.setHours(+arr[0])
							date.setMinutes(+arr[1])
							date.setSeconds(+arr[2])
						}
						return (
							<Row key={timeIndex} gutter={[5, 5]} align="middle" className={styles.timeline_wrapper}>
								<Col span={1}>{timeIndex + 1}.</Col>
								<Col span={10}>
									<div className="form-group">
										<DatePicker
											picker={'time'}
											onChange={handlerTime(`${timelineName}[${timeIndex}].timeline`)}
											value={el.timeline ? moment(date) : undefined}
											showNow={false}
											placeholder={'Введите тайм-код'}
										/>
									</div>
								</Col>
								<Col span={11} >
									<SelectComponent
										name={`${timelineName}[${timeIndex}].nationalTags`}
										placeholder="Выберите национальный тег"
										options={nationalTagOptions}
										multi={true}
										showError={false}
										maxTagCount={'responsive'}
										loading={loadingNationalTag}
									/>
								</Col>
								<Col span={2}>
									<Tooltip title="Удалить тайм-код">
										<Button className={styles.delete} type="link"
												onClick={() => removeTimeline(timeIndex)}>
											<DeleteOutlined/>
										</Button>
									</Tooltip>
								</Col>
							</Row>
						)
					})}
				</>
			)}
		/>
	)
}
